import {
  FETCH_SETTINGS,
  UPDATE_SETTINGS,
  RETAIN_SETTINGS_STATE,
  SET_SHIPPING_RULE,
  SET_STOCK_RULE,
  SET_ORDER_RULE,
  SET_PRICING_RULE_LIST,
  CLEAR_PRICING_RULE_LIST,
  UPDATE_PRICING_RULES,
  SET_FREE_SHIPPING_TAG,
  SET_AUTO_FULLFILL_ORDER,
  SET_IS_BRAND_USER,
  SET_AUTO_UPDATE_PRICE
} from './settingsConstants';

const initialState = {
  pricingRules: [],
  shippingRule: null,
  stockRule: null,
  orderRule: null,
  freeShippingTag: null,
  autoFullfillOrder: null,
  retainSettingState: false,
  updateSettingResponse: null,
  updateSettingError: null,
  updatePricingRuleSettingResponse: null,
  updatePricingRuleSettingError: null,
  isBrandUser: false,
  autoUpdatePrice: null
};

export default function settingsReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_AUTO_UPDATE_PRICE:
      return {
        ...state,
        retainSettingState: true,
        autoUpdatePrice: payload
      };
    case SET_IS_BRAND_USER:
      return {
        ...state,
        isBrandUser: payload === true
      };

    case FETCH_SETTINGS:
      return {
        ...state,
        pricingRules: [...state.pricingRules, ...payload.priceRules],
        shippingRule: payload.shippingRule,
        stockRule: payload.stockRule,
        orderRule: payload.orderRule,
        freeShippingTag: payload.freeShippingTag,
        autoFullfillOrder: payload.autoFullfillOrder,
        autoUpdatePrice: payload.autoUpdatePrice
        //   updatePricingRuleSettingResponse: null
      };
    case UPDATE_SETTINGS:
      return {
        ...state,
        retainSettingState: true,
        updateSettingResponse: payload.updateSettingResponse,
        updateSettingError: payload.updateSettingError
      };
    case SET_PRICING_RULE_LIST:
      return {
        ...state,
        pricingRules: [...state.pricingRules, ...payload],
        retainSettingState: true
      };
    case UPDATE_PRICING_RULES:
      return {
        ...state,
        retainSettingState: true,
        updatePricingRuleSettingResponse: payload.updateSettingResponse,
        updatePricingRuleSettingError: payload.updateSettingError
      };
    case SET_SHIPPING_RULE:
      return {
        ...state,
        retainSettingState: true,
        shippingRule: payload
      };
    case SET_STOCK_RULE:
      return {
        ...state,
        retainSettingState: true,
        stockRule: payload
      };
    case SET_ORDER_RULE:
      return {
        ...state,
        retainSettingState: true,
        orderRule: payload
      };
    case SET_FREE_SHIPPING_TAG:
      return {
        ...state,
        retainSettingState: true,
        freeShippingTag: payload
      };
      case SET_AUTO_FULLFILL_ORDER:
      return {
        ...state,
        retainSettingState: true,
        autoFullfillOrder: payload
      };
    case RETAIN_SETTINGS_STATE:
      return {
        ...state,
        retainSettingState: true
      };
    case CLEAR_PRICING_RULE_LIST:
      return {
        ...state,
        pricingRules: [],
        retainSettingState: true
        //updatePricingRuleSettingResponse: null
      };
    default:
      return state;
  }
}

//Enable button
/* const isProductImport = (productList) => {
  let index = productList.findIndex((p) => p.isSelected === true);

  if (index === -1) return true;
  else return false;
}; */

//Set main product list
/* const setProductImport = (
  productList,
  productID,
  isSelectAllMode,
  isSelectAll
) => {
  const newList = [];

  if (isSelectAllMode) {
    productList.forEach((product, index) => {
      product.isSelected = isSelectAll;
      newList.push(product);
    });
  } else {
    productList.forEach((product, index) => {
      if (product.product_id === productID) {
        product.isSelected = !product.isSelected;
      }
      newList.push(product);
    });
  }
  return newList;
}; */

//Set product import list
/* const setProductImportList = (
  productList,
  productToImportList,
  productID,
  isSelectAllMode,
  isSelectAll
) => {
  const newList = [];
  //add existing value
  productToImportList.forEach((product, index) => {
    newList.push(product);
  });
  //mode:add/remove all
  if (isSelectAllMode) {
    productList.forEach((product, index) => {
      var itemIndex = productToImportList.findIndex(
        (p) => p.product_id === product.product_id
      );
      if (itemIndex === -1 && isSelectAll) {
        newList.push(product);
      } else if (itemIndex > -1 && !isSelectAll) {
        // console.log('splice', itemIndex);
        productToImportList.splice(itemIndex, 1);
        var itemIndexTemp = newList.findIndex(
          (p) => p.product_id === product.product_id
        );
        if (itemIndex > -1 && !isSelectAll) {
          newList.splice(itemIndexTemp, 1);
        }
      }
    });
  } else {
    //Single product update
    productList.forEach((product, index) => {
      if (product.product_id === productID) {
        //console.log(product.product_id, productID)
        var itemIndex = productToImportList.findIndex(
          (p) => p.product_id === product.product_id
        );
        //console.log("itemIndex 1",itemIndex)
        if (itemIndex === -1 && product.isSelected) {
          //console.log('itemIndex 2', itemIndex);
          newList.push(product);
        } else if (itemIndex > -1 && !product.isSelected) {
          // console.log('itemIndex 3', itemIndex);
          newList.splice(itemIndex, 1);
        }
      }
    });
  }

  console.log('productToImportList', newList);
  saveToLocalStorage(newList);
  return newList;
}; */

/* function saveToLocalStorage(productList) {
  try {
    console.log('saveToLocalStorage', productList);
    const serializedState = JSON.stringify(productList);
    localStorage.setItem('selectedProduct', serializedState);
  } catch (e) {
    console.log(e);
  }
} */
/* const productFilterByExtraShipping = (
    productList,
    isExtraShipping,
    productsTemp,
    isFreeShipping
  ) => {
    if (isFreeShipping && isExtraShipping) return productsTemp;
    else if (isExtraShipping)
      return productList.filter((p) => p.freeshipping === false);
    else return productsTemp;
  };
  
  const productFilterByFreeShipping = (
    productList,
    isFreeShipping,
    productsTemp,
    isExtraShipping
  ) => {
    if (isFreeShipping && isExtraShipping) return productsTemp;
    else if (isFreeShipping) return productList.filter((p) => p.freeshipping === true);
    else return productsTemp;
  }; */

const sortProductByKey = (list, key, direction) => {
  if (direction === 'asc') return list.slice().sort(sortByKey(key));
  else if (direction === 'desc') return list.slice().sort(sortDescByKey(key));
  else return list;
};

const sortByKey = (key) => {
  return (a, b) => (a[key] > b[key] ? 1 : -1);
};
const sortDescByKey = (key) => {
  return (a, b) => (a[key] > b[key] ? -1 : 1);
};
