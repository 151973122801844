import {
  FETCH_PRICE_LOG_UPDATES,
  PRICE_LOG_SET_PAGE_SIZE,
  PRICE_LOG_SET_PAGE_NO,
  CLEAR_PRICE_LOG
} from './productPriceUpdateLogConstants';

const initialState = {
  priceUpdateLog: [],
  totalCount: 0,
  currentPage: 0,
  pageSize: 10
};

export default function productPriceUpdateLogReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FETCH_PRICE_LOG_UPDATES:
      return {
        ...state,
        priceUpdateLog: payload.priceUpdateLog,
        totalCount: payload.totalCount,
        currentPage: payload.currentPage
      };
    case CLEAR_PRICE_LOG:
      return {
        ...state,
        priceUpdateLog: [],
        currentPage: 1
      };
    case PRICE_LOG_SET_PAGE_SIZE:
      return {
        ...state,
        retainState: false,
        pageSize: payload
      };

    case PRICE_LOG_SET_PAGE_NO:
      return {
        ...state,
        retainState: false,
        currentPage: payload
      };

    default:
      return state;
  }
}
