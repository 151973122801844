import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import { connect } from 'react-redux';

import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import { useSsoAuth } from '../../hooks/useSsoAuth';
import InformationSvg from '../../assets/images/icon/information-circle.svg';
import x from '../../assets/images/icon/x.svg';

import {
  SidebarHeader,
  SidebarMenu,
  SidebarFooter
} from '../../layout-components';
import { NavLink } from 'react-router-dom';
import { signOutUser } from '../../components/auth/authActions';

const Sidebar = (props) => {
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  const {
    sidebarStyle,
    sidebarShadow,
    sidebarFooter,
    sidebarToggleMobile,
    setSidebarToggleMobile,
    showFeatureItems5
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoding] = useState(false)
  const [isDisplayNote, setIsDisplayNote] = useState(true)
  const { dszEmail, token, } = useSelector((state) => state.auth);
  const { ssoEnabled, ssoLogout } = useSsoAuth(dszEmail, token)
  const DSZ_URL = process.env.REACT_APP_DSZ_WEBSITE_URL
  const LEGACY_DSZ_URL = process.env.REACT_APP_DSZ_LEGACY_WEBSITE_URL || process.env.REACT_APP_DSZ_WEBSITE_URL;
  async function handleSignOut(e) {
    e.preventDefault();
    console.log('start handleSignOut')
    try {
      setLoding(true);
      if (ssoEnabled) {
        await ssoLogout();
      }
      await dispatch(signOutUser());
      setLoding(false);
      history.push('/login');
    } catch (error) {
      toast.error(error.message);
    }
  }
  const clearNotification = () => {
    setIsDisplayNote(false)
  }
  return (
    <>
      <div
        className={clsx('app-sidebar', sidebarStyle, {
          'app-sidebar--shadow': sidebarShadow
        })}>
        <SidebarHeader />
        <div className="app-sidebar--content">
          <SidebarMenu />
        </div>
        {showFeatureItems5 && (<div className="sidebar-navigation" style={{ marginBottom: '10px' }}>
          <ul>
            <li className='line-nav-bar'>
              <span style={{ width: '270px', height: '0.7px', backgroundColor: '#E6E6EE' }}></span>
            </li>
            <li className='active-li'>
              <a href='https://web.dropshipzone.com.au/learn/user-guides' onClick={toggleSidebarMobile}>
                <span className='sidebar-icon2'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M7.06596 7.66667C7.49305 6.76041 8.64544 6.11111 10 6.11111C11.7183 6.11111 13.1112 7.15578 13.1112 8.44444C13.1112 9.5329 12.1174 10.4473 10.7733 10.7051C10.3515 10.7861 10 11.126 10 11.5556M10 13.8889H10.0078M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="#E6E6EE" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                </svg></span>
                <span className="sidebar-item-label2">
                  Help & Support
                </span>
              </a>
            </li>
            <li className='active-li'>
              <a href={LEGACY_DSZ_URL + '/customer/account/'} onClick={toggleSidebarMobile}>
                <span className='sidebar-icon2'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M13.1111 6.11111C13.1111 7.82933 11.7182 9.22222 9.99999 9.22222C8.28177 9.22222 6.88888 7.82933 6.88888 6.11111C6.88888 4.39289 8.28177 3 9.99999 3C11.7182 3 13.1111 4.39289 13.1111 6.11111Z" stroke="#E6E6EE" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M9.99999 11.5556C6.9931 11.5556 4.55554 13.9931 4.55554 17H15.4444C15.4444 13.9931 13.0069 11.5556 9.99999 11.5556Z" stroke="#E6E6EE" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                </svg></span>
                <span className="sidebar-item-label2">
                  Dropshipzone Account
                </span>
              </a>
            </li>

            <li className='active-li'>
              <a href='javascript:;' onClick={(e) => handleSignOut(e)}>
                <span className='sidebar-icon2'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                    <path d="M9.22222 13.7386L6.11111 10.6275M6.11111 10.6275L9.22222 7.51638M6.11111 10.6275L17 10.6275M13.1111 13.7386V14.5164C13.1111 15.805 12.0664 16.8497 10.7778 16.8497H5.33333C4.04467 16.8497 3 15.805 3 14.5164V6.73861C3 5.44994 4.04467 4.40527 5.33333 4.40527H10.7778C12.0664 4.40527 13.1111 5.44994 13.1111 6.73861V7.51638" stroke="#E6E6EE" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
                <span className="sidebar-item-label2">
                  Logout
                </span>
              </a>
            </li>
          </ul>
        </div>)}
        {(!showFeatureItems5 && isDisplayNote==true) && (
          <div className='notification'>
            <div className='notification-box'>
              <div><img src={InformationSvg} /></div>
              <div className='notification-content'>On May 20 the way you place orders is changing. <br></br>
              <a className='notification-link' href={DSZ_URL + '/new-retailer-portal'}>Click here to find out more.</a></div>
              <div onClick={clearNotification}><img src={x}></img></div>
            </div>
          </div>
        )}

        {sidebarFooter && <SidebarFooter />}
      </div>
      <div
        onClick={toggleSidebarMobile}
        className={clsx('app-sidebar-overlay', {
          'is-active': sidebarToggleMobile
        })}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarShadow: state.ThemeOptions.sidebarShadow,
  sidebarFooter: state.ThemeOptions.sidebarFooter,
  sidebarStyle: state.ThemeOptions.sidebarStyle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  showFeatureItems5: state.ThemeOptions.showFeatureItems5,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
