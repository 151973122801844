import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Badge,
  ListGroup,
  ListGroupItem,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  UncontrolledPopover
} from 'reactstrap';
import { NavLink as NavLinkStrap } from 'reactstrap';
import { User, Bell } from 'react-feather';
import { toast } from 'react-toastify';
import { useHistory, NavLink } from 'react-router-dom';
import { signOutUser } from '../../components/auth/authActions';
import { clearDSZPriceNotification } from '../../components/myAccount/profileActions.js';
import clickFrenzyLogo from '../../assets/images/Sofortig_Notification_ClickFrenzy_380x80.png';
import { useSsoAuth } from '../../hooks/useSsoAuth';
import usersvg from '../../assets/images/icon/user_purple.svg'

export function HeaderUserbox() {
  const dispatch = useDispatch();
  const { showFeatureItems5 } = useSelector((state) => state.ThemeOptions);
  const { shopname, email, dszEmail, token } = useSelector((state) => state.auth);
  const { DszNotificationFlag } = useSelector((state) => state.profile);
  const history = useHistory();
  const isClickFrenzy = process.env.REACT_APP_IS_CLICK_FRENZY;
  const [enableDropDownOpen, setEnableDropDownOpen] = useState(false);
  const enableButtonToggle = () =>
    setEnableDropDownOpen((prevState) => !prevState);

  const { ssoEnabled, ssoLogout } = useSsoAuth(dszEmail, token)

  async function handleSignOut(e) {
    e.preventDefault();
    console.log('start handleSignOut')
    try {
      if (ssoEnabled) {
        const ssoLogoutRes = await ssoLogout();
        if (ssoLogoutRes) {
          const signout = await dispatch(signOutUser());
          if (signout) {
            history.push('/login');
          }
        } else {
          toast.error('sso logout failed, please retry later')
        }
      } else {
        const signout = await dispatch(signOutUser());
        if (signout) {
          localStorage.setItem('forceRefresh', 'true');
          history.push('/login');
        }
      }

    } catch (error) {
      toast.error(error.message);
    }
  }

  const clearDszNotification = () => {
    dispatch(clearDSZPriceNotification());
  };

  return (
    <>
      {!showFeatureItems5&&
      (<><div className="d-flex align-items-center popover-header-wrapper">
        {!isClickFrenzy && (
          <span className="d-inline-block pr-2">
            <Button
              id="alertsPopover"
              color="neutral-success"
              className="bg-neutral-success text-success font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded position-relative">
              <span>
                <Badge color="success" className="badge-circle">
                  Click Frenzy Mayhem Notification
                </Badge>
                <Bell />
              </span>
            </Button>
            <UncontrolledPopover
              isOpen={enableDropDownOpen}
              toggle={enableButtonToggle}
              target="alertsPopover"
              trigger="legacy"
              className="popover-custom-wrapper popover-custom-md"
              placement="auto">
              <div className="mx-3 mt-3 border-0 text-center rounded-sm">
                <img
                  alt="Click Frenzy"
                  src={clickFrenzyLogo}
                  className="rounded-sm"
                />
              </div>
              <div className="mx-3 mt-3">
                Click Frenzy Mayhem is coming! We're offering an <strong>exclusive discount</strong> to Dropshipzone retailers.
              </div>

              <div className="text-center py-3">
                <NavLinkStrap href="https://web.dropshipzone.com.au/click-frenzy-mayhem">
                  <Button
                    style={{ backgroundColor: '#942FFB', borderColor: '#942FFB' }}
                    color="#942FFB"
                    className="hover-scale-sm font-weight-bold btn-pill px-4 btn btn-primary">
                    <span className="btn-wrapper--label">Learn More</span>
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </span>
                  </Button>
                </NavLinkStrap>
              </div>
            </UncontrolledPopover>
          </span>
        )}
        {isClickFrenzy && (
          <span className="d-inline-block pr-2">
            <Button
              id="alertsPopover"
              color="neutral-info"
              className="bg-neutral-info text-info font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded position-relative">
              <span>
                {DszNotificationFlag === 1 && (
                  <span className="">
                    <Badge
                      color="danger"
                      className="badge position-absolute top-0 start-100 translate-right ml-3 badge rounded-pill bg-danger">
                      1
                    </Badge>
                  </span>
                )}
                <FontAwesomeIcon icon={['far', 'bell']} />
              </span>
            </Button>
            {DszNotificationFlag === 1 && (
              <UncontrolledPopover
                target="alertsPopover"
                trigger="legacy"
                className="popover-custom-wrapper popover-custom-md"
                placement="auto">
                <div className="bg-composed-wrapper bg-danger mx-3 mt-3 border-0 text-center rounded-sm">
                  <div className="bg-composed-img-3 bg-composed-wrapper--image" />
                  <div className="bg-composed-wrapper--content text-white px-2 py-4">
                    <h4 className="font-size-xl font-weight-bold mb-2">
                      Price Alert Notification
                    </h4>
                  </div>
                </div>
                <div className="mx-3 mt-3">
                  You have some products that are selling below DSZ Price. By
                  clicking this button, you acknowledge you have been notified.
                </div>

                <div className="text-center py-3">
                  <Button
                    tag="a"
                    href="/MyProducts?DSZ-Below-Price"
                    onClick={clearDszNotification}
                    color="primary"
                    className="hover-scale-sm font-weight-bold btn-pill px-4 btn btn-primary">
                    <span className="btn-wrapper--label">
                      Take me to My Products
                    </span>
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </span>
                  </Button>
                </div>
              </UncontrolledPopover>
            )}

            {DszNotificationFlag !== 1 && (
              <UncontrolledPopover
                target="alertsPopover"
                trigger="legacy"
                className="popover-custom-wrapper popover-custom-md"
                placement="auto">
                <div className="bg-composed-wrapper mx-3 mt-3 border-0 text-center rounded-sm">
                  <div className="bg-composed-wrapper--content text-info px-0 py-2">
                    <h4 className="font-size-xl font-weight-bold mb-2">
                      No Notifications
                    </h4>
                  </div>
                </div>
              </UncontrolledPopover>
            )}
          </span>
        )}
      </div>

      <UncontrolledDropdown className="position-relative ml-2">
        <DropdownToggle
          color="link"
          className="p-0 text-left d-flex btn-transition-none align-items-center">
          <div
            color="neutral-success"
            className="bg-neutral-success text-success font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded position-relative">
            <span>
              <Badge color="success" className="badge-circle">
                Online
              </Badge>
              <User />
            </span>
          </div>

          <div className="d-none d-xl-block pl-2">
            {shopname && (
              <>
                <div className="font-weight-bold">{shopname}</div>
                <span className="text-black-50">{email}</span>
              </>
            )}
          </div>
          <span className="pl-1 pl-xl-3">
            <FontAwesomeIcon
              icon={['fas', 'angle-down']}
              className="opacity-5"
            />
          </span>
        </DropdownToggle>
        <DropdownMenu right className="dropdown-menu-lg overflow-hidden p-0">
          <ListGroup flush className="text-left bg-transparent">
            <ListGroupItem className="rounded-top">
              <Nav pills className="nav-neutral-primary flex-column">
                <NavItem className="nav-header d-flex text-primary pt-1 pb-2 font-weight-bold align-items-center">
                  <div>Profile options</div>
                  {/* <div className="ml-auto font-size-xs">
                    <a
                      href="#/"
                      onClick={(e) => e.preventDefault()}
                      id="ChangeSettingsTooltip">
                      <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                    </a>
                    <UncontrolledTooltip
                      target="ChangeSettingsTooltip"
                      container="body">
                      Change settings
                    </UncontrolledTooltip>
                  </div> */}
                </NavItem>
                <NavItem>
                  <NavLinkStrap href="/MyAccount">My Account</NavLinkStrap>
                </NavItem>
                <NavItem>
                  <NavLinkStrap href="#/" onClick={(e) => handleSignOut(e)}>
                    Sign out
                  </NavLinkStrap>
                </NavItem>
              </Nav>
            </ListGroupItem>
          </ListGroup>
        </DropdownMenu>
      </UncontrolledDropdown>
      </>)}

      {showFeatureItems5 && (<div style={{paddingRight:'32px'}}>
        <img src={usersvg}></img>
        <span style={{
          color: '#942FFB',
          fontFamily: "Caslon Ionic",
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '400',
          marginLeft: '10px',
        }}>{dszEmail}</span>
      </div>)}
    </>
  );
}

export default HeaderUserbox;
