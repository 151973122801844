import {
  FETCH_MY_ORDERS,
  CLEAR_MY_ORDERS,
  SET_MY_ORDERS_PAGE_SIZE,
  SET_MY_ORDERS_PAGE_NO,
  SET_MY_ORDERS_SEARCH_KEYWORDS,
  SET_SELECT_MY_ORDERS,
  SET_SEARCH_BINDED_ORDERS,
  SET_SEARCH_UNBINDED_ORDERS,
  SET_BINDED_ORDERS,
  SET_UNBINDED_ORDERS,
  GET_MY_ORDER_DETAIL,
  SET_MY_SELECTED_PRODUCT_ID,
  CLEAR_MY_SELECTED_PRODUCT,
  CLEAR_MY_SELECTED_UNPAIDORDERS,
  SET_UPDATE_PRODUCT_RESPONSE_VIEW,
  RETAIN_MY_ORDERS_STATE,
  UPDATE_ORDERS_LIST,
  SET_FREE_SHIPPING_MY_PRODUCT,
  SET_EXTRA_SHIPPING_MY_PRODUCT,
  SET_GONE_FROM_SUPPLIER,
  SET_BELOW_DSZ_PRICE,
  REMOVE_ORDERS_LIST,
  CLEAR_REMOVE_ORDERS_RESPONSE,
  RETAIN_MY_ORDERS_DETAILS_STATE,
  CLEAR_MY_ORDER_DETAILS,
  RETAIN_ORDERS_BUNDLE_STATE,
  GET_ORDERS_BUNDLE_DETAILS,
  CLEAR_ORDERS_BUNDLE_DETAILS,
  SET_BUNDLE_PAYMENT_TYPE,
  SET_BUNDLE_PAYMENT_ID,
  SET_ORDER_STATUS,
  GET_CREDIT_POINTS,
  CLEAR_MY_ORDER_DETAILS_STATE,
  SET_PAY_POPUP,
  GET_CLIENT_TOKEN,
  SET_CLIENT_TOKEN,
  CLEAR_CLIENT_TOKEN
} from './unpaidOrdersConstants';

const initialState = {
  myOrdersList: [],
  selectedProducts: loadFromLocalStorage(),
  isProductToUpdateResponse: false,
  productToUpdateResponse: [],
  productToRemoveResponse: '',
  retainState: false,
  totalCount: 0,
  totalPages: 0,
  pageSize: 30,
  currentPage: 1,
  searchKeywords: '',
  bindedProducts: false,
  unbindedProducts: false,
  freeShipping: false,
  extraShipping: false,
  goneFormSupplier: false,
  belowDszPrice: false,
  sortParams: [],
  selectedProductID: null,
  selectedProduct: null,
  isProductSelectAll: false,
  btnAll: false,
  btnShowProductImportList: true,
  btnProceedProductImportList: true,
  isSelected: true,
  bindingMode: '',
  selectedExportProduct: [],
  myOrderDetails: null,
  retainOrderDetailsState: false,
  retainOrderBundleState: false,
  orderBundleDetails: null,
  paymentType: 'paypal',
  bundlePaymentID: 0,
  searchOrderStatus: 'unpaid',
  activeTabVal: '1',
  creditPoints: 0,
  creditPointsEnable: false,
  isPayClickPopup: true,
  clientToken: '',
  isClientToken: false,
  tokenExpireIn: ''
};

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('myselunp');
    //console.log("serializedState",serializedState.length)
    if (serializedState === null || serializedState.length === 0) return [];
    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
    return [];
  }
}

export default function unpaidOrderReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FETCH_MY_ORDERS:
      return {
        ...state,
        myOrdersList: [...state.myOrdersList, ...payload.myOrdersList],
        totalCount: payload.totalCount,
        totalPages: payload.totalPages,
        currentPage: payload.currentPage,
        pageSize: payload.pageSize,
        isSelected: payload.showPayButton,
        retainOrderDetailsState: false,
        retainOrderBundleState: false,
        myOrderDetails: null,
        retainState: true
      };
    case GET_MY_ORDER_DETAIL:
      return {
        ...state,
        myOrderDetails: payload.myOrderDetails,
        retainState: false,
        retainOrderDetailsState: true
      };
    case CLEAR_MY_ORDER_DETAILS:
      return {
        ...state,
        retainOrderDetailsState: false,
        retainOrderBundleState: false,
        myOrderDetails: null
      };
    case CLEAR_MY_ORDER_DETAILS_STATE:
      return {
        ...state,
        retainOrderDetailsState: false,
        myOrderDetails: null
      };
    case GET_ORDERS_BUNDLE_DETAILS:
      return {
        ...state,
        orderBundleDetails: payload.orderBundleDetails,
        retainState: false,
        retainOrderDetailsState: false
      };
    case CLEAR_ORDERS_BUNDLE_DETAILS:
      return {
        ...state,
        orderBundleDetails: null,
        //selectedProducts: [],
        bundlePaymentID: 0,
        paymentType: 'paypal',
        clientToken: '',
        isClientToken: false
        // retainState: false
      };
    case SET_SELECT_MY_ORDERS:
      return {
        ...state,

        myOrdersList: setProductSelection(
          state.myOrdersList,
          payload.id,
          payload.isSelectAllMode,
          payload.isSelectAll
        ),
        selectedProducts: setProductSelectionList(
          state.myOrdersList,
          state.selectedProducts,
          payload.id,
          payload.isSelectAllMode,
          payload.isSelectAll
        ),
        isProductSelectAll: payload.isSelectAll,
        selectedExportProduct: selectedProducts(state.myOrdersList),
        isSelected: isProductImport(state.myOrdersList)
      };
    case UPDATE_ORDERS_LIST:
      return {
        ...state,
        productToUpdateResponse: [
          ...state.productToUpdateResponse,
          ...payload.productToUpdateResponse
        ],
        retainState: false,
        isProductToUpdateResponse: true,
        bindingMode: payload.bindingMode
      };
    case REMOVE_ORDERS_LIST:
      return {
        ...state,
        productToRemoveResponse: payload.productToUpdateResponse,
        retainState: false,
        selectedProducts: []
      };
    case CLEAR_REMOVE_ORDERS_RESPONSE:
      return {
        ...state,
        productToRemoveResponse: '',
        retainState: true
      };
    case SET_UPDATE_PRODUCT_RESPONSE_VIEW:
      return {
        ...state,
        retainState: true,
        isProductToUpdateResponse: payload,
        productToUpdateResponse: [],
        bindingMode: ''
      };
    case SET_FREE_SHIPPING_MY_PRODUCT:
      return {
        ...state,
        retainState: false,
        freeShipping: payload,
        isProductSelectAll: false,
        currentPage: 1
      };
    case SET_EXTRA_SHIPPING_MY_PRODUCT:
      return {
        ...state,
        retainState: false,
        extraShipping: payload,
        isProductSelectAll: false,
        currentPage: 1
      };
    case SET_GONE_FROM_SUPPLIER:
      return {
        ...state,
        retainState: false,
        goneFormSupplier: payload,
        isProductSelectAll: false,
        currentPage: 1
      };
    case SET_BELOW_DSZ_PRICE:
      return {
        ...state,
        retainState: false,
        belowDszPrice: payload,
        isProductSelectAll: false,
        currentPage: 1
      };
    case CLEAR_MY_ORDERS:
      return {
        ...state,
        myOrdersList: []
        // currentPage: 1
      };
    case CLEAR_MY_SELECTED_PRODUCT:
      return {
        ...state,
        retainState: false,
        selectedProductID: null,
        selectedProduct: null
      };
    case CLEAR_MY_SELECTED_UNPAIDORDERS:
      return {
        ...state,
        selectedProducts: loadFromLocalStorage(),
        retainState: false
      };

    case SET_MY_SELECTED_PRODUCT_ID:
      return {
        ...state,
        retainState: false,
        selectedProductID: payload
      };
    case SET_MY_ORDERS_PAGE_SIZE:
      return {
        ...state,
        retainState: false,
        pageSize: payload
      };
    case GET_CLIENT_TOKEN:
      return {
        ...state,
        clientToken: payload.clientToken,
        tokenExpireIn: payload.tokenExpireIn,
        isClientToken: true
      };
    case CLEAR_CLIENT_TOKEN:
      return {
        ...state,
        clientToken: '',
        isClientToken: false
      };
    case SET_MY_ORDERS_PAGE_NO:
      return {
        ...state,
        retainState: false,
        currentPage: payload,
        isProductSelectAll: false
      };
    case SET_MY_ORDERS_SEARCH_KEYWORDS:
      return {
        ...state,
        retainState: false,
        searchKeywords: payload,
        currentPage: 1
      };
    case SET_SEARCH_BINDED_ORDERS:
      return {
        ...state,
        retainState: false,
        bindedProducts: payload,
        isProductSelectAll: false,
        currentPage: 1
      };
    case SET_SEARCH_UNBINDED_ORDERS:
      return {
        ...state,
        retainState: false,
        unbindedProducts: payload,
        isProductSelectAll: false,
        currentPage: 1
      };
    case SET_BUNDLE_PAYMENT_TYPE:
      return {
        ...state,
        paymentType: payload
      };
    case SET_BUNDLE_PAYMENT_ID:
      return {
        ...state,
        bundlePaymentID: payload,
        retainState: false
      };
    case GET_CREDIT_POINTS:
      return {
        ...state,
        creditPoints: payload.creditPoints,
        creditPointsEnable: payload.creditPointsEnable
      };
    case SET_ORDER_STATUS:
      return {
        ...state,
        searchOrderStatus: payload.searchOrderStatus,
        retainState: false,
        currentPage: 1,
        activeTabVal: payload.activeTab
      };
    case RETAIN_MY_ORDERS_STATE:
      return {
        ...state,
        retainState: true
      };
    case SET_CLIENT_TOKEN:
      return {
        ...state,
        isClientToken: payload
      };
    case RETAIN_MY_ORDERS_DETAILS_STATE:
      return {
        ...state,
        retainOrderDetailsState: true
      };
    case RETAIN_ORDERS_BUNDLE_STATE:
      return {
        ...state,
        retainOrderBundleState: true
      };
    case SET_PAY_POPUP:
      return {
        ...state,
        isPayClickPopup: payload
      };
    default:
      return state;
  }
}

//Enable button
const isProductImport = (productList) => {
  //console.log("productList",productList)
  let index = productList.findIndex((p) => p.isSelected === true);
  //console.log("Selected count", index);
  if (index === -1) return true;
  else return false;
};

//Set main product list
const setProductSelection = (
  productList,
  productID,
  isSelectAllMode,
  isSelectAll
) => {
  const newList = [];

  if (isSelectAllMode) {
    productList.forEach((product, index) => {
      product.isSelected = isSelectAll;
      newList.push(product);
    });
  } else {
    productList.forEach((product, index) => {
      if (product.order_id === productID) {
        product.isSelected = !product.isSelected;
      }
      newList.push(product);
    });
  }
  return newList;
};

//Set product import list
const setProductSelectionList = (
  productList,
  selectedProductsList,
  productID,
  isSelectAllMode,
  isSelectAll
) => {
  /*   console.log("isSelectAllMode",isSelectAllMode);
  console.log("isSelectAll",isSelectAll);
  console.log("productID",productID);
  console.log("productList",productList); */
  var newList = [];
  //add existing value
  if (selectedProductsList.length < 1) newList = [];

  selectedProductsList.forEach((product, index) => {
    //Double checking if product exist in main order list
    productList.forEach((orderItem, index) => {
      /*  var itemIndex = selectedProductsList.findIndex(
        (p) => p === orderItem.order_id
      ); */
      if (orderItem.order_id === product && orderItem.isSelected) {
        newList.push(product);
      }
      /* else {
         newList.splice(itemIndex, 1);
      } */
    });
  });
  /*    console.log('newList before', newList);
  console.log('productList', productList);
  console.log('productID', productID);
  console.log('isSelectAllMode', isSelectAllMode); */

  //mode:add/remove all
  if (isSelectAllMode) {
    productList.forEach((product, index) => {
      var itemIndex = selectedProductsList.findIndex(
        (p) => p === product.order_id
      );
      if (itemIndex === -1 && isSelectAll) {
        newList.push(product.order_id);
      } else if (itemIndex > -1 && !isSelectAll) {
        // console.log('splice', itemIndex);
        selectedProductsList.splice(itemIndex, 1);
        var itemIndexTemp = newList.findIndex((p) => p === product.order_id);
        if (itemIndex > -1 && !isSelectAll) {
          newList.splice(itemIndexTemp, 1);
        }
      }
    });
  } else {
    //Single product update
    productList.forEach((product, index) => {
      if (product.order_id === productID) {
        //console.log(product.order_id, productID)
        var itemIndex = selectedProductsList.findIndex(
          (p) => p === product.order_id
        );
        //console.log("itemIndex 1",itemIndex)
        if (itemIndex === -1 && product.isSelected) {
          // console.log('itemIndex 2', itemIndex);
          newList.push(product.order_id);
        } else if (itemIndex > -1 && !product.isSelected) {
          // console.log('itemIndex 3', itemIndex);
          newList.splice(itemIndex, 1);
        }
      }
    });
  }

  //console.log('selectedProductsList', newList);
  saveToLocalStorage(newList);
  return newList;
};

function saveToLocalStorage(productList) {
  try {
    //console.log('saveToLocalStorage', productList);
    const serializedState = JSON.stringify(productList);
    localStorage.setItem('myselunp', serializedState);
  } catch (e) {
    console.log(e);
  }
}
const sortProductByKey = (list, key, direction) => {
  if (direction === 'asc') return list.slice().sort(sortByKey(key));
  else if (direction === 'desc') return list.slice().sort(sortDescByKey(key));
  else return list;
};

const sortByKey = (key) => {
  return (a, b) => (a[key] > b[key] ? 1 : -1);
};
const sortDescByKey = (key) => {
  return (a, b) => (a[key] > b[key] ? -1 : 1);
};

const selectedProducts = (selectProductList) => {
  const selectedList = selectProductList.filter((order) => order.isSelected);

  const mArray = [];
  if (selectedList && selectedList.length !== 0)
    selectedList.forEach((obj) => {
      let container = {};

      container.sku = obj.sku;
      container.title = obj.title;
      container.bind = obj.bind ? 'Bound' : 'Unbound';
      container.cost = obj.cost;
      container.rrp = obj.rrp;
      container.freeshipping = obj.freeshipping ? 'Y' : 'N';
      container.discontinued = obj.discontinued;

      mArray.push(container);
    });

  // console.log("Array", mArray);
  return mArray;
};
