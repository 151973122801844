import moment from 'moment';

import {
  AUTO_SYNC_RESPONSE_STATUS,
  CLEAR_PRICE_SYNC_LIST,
  FETCH_SELECTED_SYNC_ITEM,
  FETCH_PRICE_SYNC_LIST,
  SET_AUTO_SYNC_PAGE_NO,
  SET_AUTO_SYNC_STATE_INTIAL
} from './autoSyncConstants';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionStartSecondary,
  asyncActionFinishSecondary,
  asyncActionStartExportALL,
  asyncActionFinishExportALL,
  asyncActionStartExportPartial,
  asyncActionFinishExportPartial,
  asyncActionError
} from '../../../reducers/asyncReducer';

import service from '../../../services/request';
import serviceToken from '../../../services/requestToken';

import { toast } from 'react-toastify';

import { getUserProfile } from '../../myAccount/profileActions';

export const setPriceAutoSync = (isAutoSync, email, website) => {
  // console.log("isBinding:",isBinding);
  let url = 'api/users/auto_sync';

  return async function (dispatch) {
    dispatch(asyncActionStart());
    service
      .post(url, {
        email: email,
        website: website,
        price_auto_sync: isAutoSync
      })
      .then(function (response) {
        //console.log('setPriceAutoSync response', response);
        let autoSyncStatus = '';
        let autoSyncResponseData = '';
        if (response.data.status) {
          autoSyncStatus = 'success';
          autoSyncResponseData = response.data.data;

          dispatch(getUserProfile());

          toast.success('Auto-Sync has been enabled successfully', {
            toastId: 'autosyncseccess',
            autoClose: 8000
          });
        } else {
          autoSyncStatus = '';
          autoSyncResponseData = '';
          toast.error('There is an error while enable Auto-Sync.', {
            toastId: 'autosyncerror',
            autoClose: 8000
          });
        }
        dispatch({
          type: AUTO_SYNC_RESPONSE_STATUS,
          payload: { isAutoSync, autoSyncStatus, autoSyncResponseData }
        });
        dispatch(asyncActionFinish());
      })
      .catch(function (error) {
        console.error(error.data);
        dispatch(asyncActionError(error));
      });
  };
};

const API_GATEWAY_URL = process.env.REACT_APP_API_GATEWAY_URL;

export function fetchPriceSyncList(retailerID, pageNo, pageSize) {
  const url =
    API_GATEWAY_URL +
    `shopifysyncservice/v1/retailers/${retailerID}/price_sync?page_no=${pageNo}&limit=${pageSize}`;

  return async function (dispatch) {
    dispatch(asyncActionStart());
    dispatch(clearPriceSyncLists());
    serviceToken
      .get(url, {})
      .then(function (response) {
        // console.log('fetchPriceSyncList response', response);
        if (response.data.success) {
          const totalCount = response.data.data.total;
          const totalPages = response.data.data.total_pages;
          const currentPage = response.data.data.current_page;
          const pageSize = response.data.data.page_size;

          const priceSyncList = response.data.data.list;
          const priceSyncListProcessing = response.data.data.processing.list;
          const priceSyncListPending = response.data.data.pending.list;

          //  console.log("priceSyncList,priceSyncListProcessing, priceSyncListPending",priceSyncList,priceSyncListProcessing,priceSyncListPending);

          dispatch({
            type: FETCH_PRICE_SYNC_LIST,
            payload: {
              priceSyncList,
              priceSyncListPending,
              priceSyncListProcessing,
              totalCount,
              totalPages,
              currentPage,
              pageSize
            }
          });

          dispatch(asyncActionFinish());
        }
      })
      .catch(function (error) {
        console.log('error -> ', error);
        // dispatch(asyncActionError(error));
      });
  };
}

export function fetchPriceSyncListItems(
  retailerID,
  priceSyncID,
  statusText,
  pageSectionID
) {
  var url =
    API_GATEWAY_URL +
    `shopifysyncservice/v1/retailers/${retailerID}/price_sync/${priceSyncID}/items`;
  if (statusText.length > 0) url = url + `?status=${statusText}`;

  //console.log('fetchPriceSyncListItems url', url);

  return async function (dispatch) {
    if (pageSectionID === 1) dispatch(asyncActionStartSecondary());
    else if (pageSectionID === 2) dispatch(asyncActionStartExportALL());
    else if (pageSectionID === 3) dispatch(asyncActionStartExportPartial());

    serviceToken
      .get(url, {})
      .then(function (response) {
       // console.log('fetchPriceSyncListItems response', response.data.data);
        if (response.data.success) {
          
          if (pageSectionID === 1) dispatch(asyncActionFinishSecondary());
          else if (pageSectionID === 2) dispatch(asyncActionFinishExportALL());
          else if (pageSectionID === 3) dispatch(asyncActionFinishExportPartial());

          const itemList = response.data.data;

          const newList = [
            'SKU,Effective time,New cost, New price,New rrp, Old cost, Old price, Old rrp, Status,Update time,Description \n'
          ];

          itemList.forEach((item, index) => {
            // console.log('newList response', item.sku);
            const itemData = `"${item.sku}","${moment(item.effective_time).format('MM/DD/YYYY HH:mm A')}","${
              item.new_cost
            }","${item.new_price}","${item.new_rrp === undefined ? '' : item.new_rrp}","${item.old_cost}","${
              item.old_price
            }","${item.old_rrp ? item.old_rrp : ''}","${item.status ? item.status : ''}","${moment(
              item.update_time
            ).format('MM/DD/YYYY HH:mm A')}","${item.description ? item.description : '' }"\n`;

            newList.push(itemData);

            //            console.log('itemData response', itemData);
          });

          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob(newList));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'auto_sync_list.csv');
          // Append to html page
          document.body.appendChild(link);
          // Force download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        }
      })
      .catch(function (error) {
        console.log('error -> ', error);
        // dispatch(asyncActionError(error));
      });
  };
}

export function clearPriceSyncLists() {
  return {
    type: CLEAR_PRICE_SYNC_LIST
  };
}


export function setPriceSyncStateIntial() {
  return {
    type: SET_AUTO_SYNC_STATE_INTIAL
  };
}

export function setSelectedSyncItem(id) {
  return {
    type: FETCH_SELECTED_SYNC_ITEM,
    payload: id
  };
}

export function setCurrentPage(value) {
  return function (dispatch) {
    dispatch(clearPriceSyncLists());
    dispatch({ type: SET_AUTO_SYNC_PAGE_NO, payload: value });
  };
}
