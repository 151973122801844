import {
  FETCH_PRODUCT_PRICE_UPDATES,
  PRODUCT_PRICE_SET_SORT_PARAMS
} from './productPriceConstants';
import {
  PRODUCT_PRICE_RETAIN_STATE,
  PRODUCT_PRICE_SET_PAGE_NO,
  PRODUCT_PRICE_SET_PAGE_SIZE,
  SET_PRICE_UPDATE_LOADING_VIEW,
  SET_PRICE_UPDATE_PROGRESS_VIEW,
  FETCH_PRICE_UPDATE_PROGRESS,
  CLEAR_PRODUCT_PRICES
} from './productPriceConstants';
//from '../../productInfoUpdate/productInfoConstants';

const initialState = {
  productPriceUpdates: [],
  retainState: false,
  totalCount: 0,
  totalPages: 0,
  currentPage: 1,
  pageSize: 10,
  sortParams: [],
  priceUpdateProgress: {},
  isPriceUpdateRunning: false,
  isPriceUpdateProgressShow: false,
  isPriceUpdateLoadingShow: false
};

export default function productPriceReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_PRICE_UPDATE_LOADING_VIEW:
      return {
        ...state,
        isPriceUpdateLoadingShow: !!payload.value
      };
    case SET_PRICE_UPDATE_PROGRESS_VIEW:
      return {
        ...state,
        isPriceUpdateProgressShow: !!payload.value
      };
    case FETCH_PRICE_UPDATE_PROGRESS:
      return {
        ...state,
        isPriceUpdateRunning: payload.isPriceUpdateRunning || false,
        priceUpdateProgress: payload.priceUpdateProgress || {}
      };
    case FETCH_PRODUCT_PRICE_UPDATES:
      return {
        ...state,
        productPriceUpdates: payload.productsInfoUpdate,
        totalCount: payload.totalCount,
        totalPages: payload.totalPages,
        currentPage: payload.currentPage
      };
      case CLEAR_PRODUCT_PRICES:
        return {
          ...state,
          productPriceUpdates: [],
          currentPage: 1
        };
    case PRODUCT_PRICE_SET_PAGE_SIZE:
      return {
        ...state,
        retainState: false,
        pageSize: payload
      };

    case PRODUCT_PRICE_SET_PAGE_NO:
      return {
        ...state,
        retainState: false,
        currentPage: payload
      };

    case PRODUCT_PRICE_SET_SORT_PARAMS:
      return {
        ...state,
        sortParams: payload
      };

    case PRODUCT_PRICE_RETAIN_STATE:
      return {
        ...state,
        retainState: true
      };

    default:
      return state;
  }
}
