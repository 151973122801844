import React, { Fragment, useState } from 'react';

import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Row,
  Col,
  Badge,
  UncontrolledTooltip,
  Button,
  Alert,
  Card
} from 'reactstrap';

import { setHeaderDrawerToggle } from '../../../reducers/ThemeOptions';

import moment from 'moment';

import { fetchPriceSyncListItems } from './autoSyncActions';

export default function SyncDetailsSideBar({}) {
  const dispatch = useDispatch();
  const { loadingExportAll, loadingExportPartial } = useSelector(
    (state) => state.async
  );
  const { selectedSyncItem } = useSelector((state) => state.autoSync);
  const { headerDrawerToggle, showFeatureItems5 } = useSelector(
    (state) => state.ThemeOptions
  );
  const { currentUserProfile } = useSelector((state) => state.profile);

  const toggleSidebarMobile = () => {
    dispatch(setHeaderDrawerToggle(!headerDrawerToggle));
  };

  const priceSyncListItemsHandler = (syncID, statusVal, pageSectionID) => {
    const customerId = currentUserProfile.customer_id;

    dispatch(
      fetchPriceSyncListItems(customerId, syncID, statusVal, pageSectionID)
    ).then();
  };

  const getTypeText = (typeName) => {
    switch (typeName) {
      case 'initial_update':
        return 'Initial update';
      case 'markup_change':
        return 'Markup change';
      case 'price_update':
        return 'Price update';
      case 'price_review':
        return 'Price review';
      case 'promotion_start':
        return 'Promotion start';
      case 'promotion_end':
        return 'Promotion end';
      default:
        return '';
    }
  };

  const getStatusText = (
    sku_fail_count,
    sku_retry_count,
    sku_success_count,
    sku_total_count,
    statusText
  ) => {
    if (statusText === 'completed') {
      if (sku_fail_count > 0)
        return (
          <Badge className="m-1 text-danger" color="neutral-danger">
            <FontAwesomeIcon icon={['far', 'bell']} />
            {' ' + sku_fail_count} SKUs need action
          </Badge>
        );
      else if (sku_retry_count > 0)
        return (
          <Badge className="m-1 text-warning" color="neutral-warning">
            <FontAwesomeIcon icon={['far', 'bell']} /> Shopify to reattempt
          </Badge>
        );
      else if (sku_success_count > 0)
        return (
          <Badge className="m-1 text-success" color="neutral-success">
            <FontAwesomeIcon icon={['far', 'bell']} />
            {' ' + sku_success_count} SKUs updated
          </Badge>
        );
      else
        return (
          <Badge className="m-1 text-success" color="neutral-success">
            <FontAwesomeIcon icon={['far', 'bell']} />
            {' ' + sku_total_count} SKUs updated
          </Badge>
        );
    } else {
      return (
        <Badge className="m-1 text-info" color="neutral-info">
          <FontAwesomeIcon icon={['far', 'bell']} /> {' ' + sku_total_count}{' '}
          SKUs to be updated
        </Badge>
      );
    }
  };
  return (
    <Fragment>
      {/*  <div>
        <button
          onClick={toggleSidebarMobile}
          className={clsx('navbar-toggler hamburger hamburger--elastic', {
            'is-active': headerDrawerToggle
          })}>
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      </div>
 */}
      <div className="app-drawer-content">
        <Button
          onClick={toggleSidebarMobile}
          className="close-drawer-btn btn btn-sm"
          id="CloseDrawerTooltip">
          <div
            className={clsx('navbar-toggler hamburger hamburger--elastic', {
              'is-active': headerDrawerToggle
            })}>
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </div>
        </Button>
        <UncontrolledTooltip target="CloseDrawerTooltip" placement="left">
          Close
        </UncontrolledTooltip>
        <div className="vh-100 shadow-overflow">
          {selectedSyncItem && (
            <PerfectScrollbar>
              <div className="ml-3 p-4">
                <Row>
                  <Col md="6">
                    <div className="text-left">
                      <span className="text-black-50">
                        {moment(selectedSyncItem.completion_time).format(
                          'MM/DD/YYYY'
                        )}
                      </span>
                      <div className="font-weight-bold font-size-lg mb-0 text-black">
                        {getTypeText(selectedSyncItem.type)}
                      </div>
                    </div>
                  </Col>
                  <Col md="6">
                    {selectedSyncItem.status === 'completed' && (
                      <Button
                        disabled={loadingExportAll}
                        onClick={() =>
                          priceSyncListItemsHandler(selectedSyncItem.id, '', 2)
                        }
                        color="neutral-primary"
                        className="rounded-sm text-uppercase font-size-xs font-weight-bold mr-4 py-0 shadow-none hover-scale-sm w-auto d-40">
                        <span className="btn-wrapper--label">
                          {loadingExportAll && (
                            <span
                              className="btn-wrapper--icon spinner-border spinner-border-sm mb-1 mr-2"
                              role="status"
                              aria-hidden="true"></span>
                          )}{' '}
                          Export CSV
                        </span>
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={['far', 'keyboard']} />
                        </span>
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="divider" />
              <div className="px-2">
                <Row className="m-1">
                  <Col md="6" className="pb-2 text-left">
                    <span className="opacity-6 pb-2">Status</span>
                  </Col>
                  <Col md="6" className="pb-2 text-right">
                    <span className="font-weight-bold font-size-lg">
                      {selectedSyncItem.status.replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                    </span>
                  </Col>
                  <Col md="6" className="pb-2 text-left">
                    <span className="opacity-6 pb-2">Sync ID</span>
                  </Col>
                  <Col md="6" className="pb-2 text-right">
                    <div className="d-flex align-items-right justify-content-right">
                      <span>{selectedSyncItem.id}</span>
                    </div>
                  </Col>
                  <Col md="6" className="pb-2">
                    <span className="opacity-6 pb-2">Start time</span>
                  </Col>
                  <Col md="6" className="pb-2 text-right">
                    {moment(selectedSyncItem.start_time).format('HH:mm A')}
                  </Col>
                  {selectedSyncItem.status === 'completed' && (
                    <>
                      <Col md="6">
                        <span className="opacity-6 pb-2">Completion time</span>
                      </Col>
                      <Col md="6" className="pb-2 text-right">
                        {moment(selectedSyncItem.completion_time).format(
                          'HH:mm A'
                        )}
                      </Col>
                    </>
                  )}

                  <Col md="6">
                    <span className="opacity-6 pb-2">
                      Successful price syncs
                    </span>
                  </Col>
                  <Col md="6" className="pb-2 text-right">
                    {selectedSyncItem.sku_success_count + ' SKUs'}
                  </Col>
                  <Col md="6">
                    <span className="opacity-6 pb-2">
                      Unsuccessful price sync
                    </span>
                  </Col>
                  <Col md="6" className="pb-2 text-right">
                    {selectedSyncItem.sku_fail_count + ' SKUs'}
                  </Col>
                </Row>
              </div>
              <div className="divider" />
              <div className="px-2 pt-2">
                <Row className="m-1">
                  <Col md="12" className="pb-2 text-center">
                    <span className="font-weight-bold font-size-xl">
                      {getStatusText(
                        selectedSyncItem.sku_fail_count,
                        selectedSyncItem.sku_retry_count,
                        selectedSyncItem.sku_success_count,
                        selectedSyncItem.sku_total_count,
                        selectedSyncItem.status
                      )}
                    </span>
                  </Col>
                  {selectedSyncItem.sku_fail_count > 0 && (
                    <>
                      <Col md="12" className="pb-2 text-center">
                        <span>
                          Details of affected SKUs:{' '}
                          <Button
                            disabled={loadingExportPartial}
                            onClick={() =>
                              priceSyncListItemsHandler(
                                selectedSyncItem.id,
                                'fail',
                                3
                              )
                            }
                            color="link"
                            className="m-2 btn-link-primary">
                            <span className="btn-wrapper--label">
                              {loadingExportPartial && (
                                <span
                                  className="btn-wrapper--icon spinner-border spinner-border-sm mb-1 mr-2"
                                  role="status"
                                  aria-hidden="true"></span>
                              )}
                              Export CSV{' '}
                              <span className="btn-wrapper--icon">
                                <FontAwesomeIcon icon={['far', 'keyboard']} />
                              </span>
                            </span>
                          </Button>
                        </span>
                      </Col>
                      <Col md="12" className="pb-2 text-left">
                        <Alert className="m-1" color="info">
                          You may need to re-import these SKUs to your Shopify
                          store. Download the affected SKUs CSV to review error
                          details.
                        </Alert>
                      </Col>
                    </>
                  )}
                  {selectedSyncItem.sku_fail_count === 0 &&
                    selectedSyncItem.sku_retry_count > 0 && (
                      <Col md="12" className="pb-2 text-left">
                        {showFeatureItems5 && (
                          <Card className="card-box m-3">
                            <ul>
                              <li>
                                {' '}
                                <span>
                                  {selectedSyncItem.sku_retry_count + ' '}SKUs
                                  needing retry
                                </span>
                              </li>
                              <li>
                                A shopify error has occurred. No user action is
                                required. Please wait as Auto Sync reattempts
                                these SKUs
                              </li>
                            </ul>
                          </Card>
                        )}
                        {!showFeatureItems5 && (
                          <Alert className="" color="info">
                            <ul>
                              <li>
                                {' '}
                                <span>
                                  {selectedSyncItem.sku_retry_count + ' '}SKUs
                                  needing retry
                                </span>
                              </li>
                              <li>
                                A shopify error has occurred. No user action is
                                required. Please wait as Auto Sync reattempts
                                these SKUs
                              </li>
                            </ul>
                          </Alert>
                        )}
                      </Col>
                    )}
                </Row>
              </div>
            </PerfectScrollbar>
          )}
        </div>
      </div>

      <div
        onClick={toggleSidebarMobile}
        className={clsx('app-drawer-overlay', {
          'is-active': headerDrawerToggle
        })}
      />
    </Fragment>
  );
}
