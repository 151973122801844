import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { Collapse, Badge } from 'reactstrap';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';

import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import { setPriceSyncStateIntial } from '../../components/products/AutoSync/autoSyncActions';

import SidebarUserbox from '../SidebarUserbox';

import {
  Gift,
  Users,
  Grid,
  Columns,
  Award,
  ChevronRight,
  Settings,
  ShoppingBag,
  CheckSquare,
  Info,
  DollarSign,
  Database,
  BookOpen,
  Tag,
  Truck,
  FileText,
  PlusSquare,
  Crop,
  RefreshCw,
  Inbox
} from 'react-feather';

const SidebarMenu = (props) => {
  const {
    setSidebarToggleMobile,
    sidebarUserbox,
    showFeatureItems,
    showFeatureItems2,
    showFeatureItems3,
    showFeatureItems5,
    isAutoSync,
    setPriceSyncStateIntial
  } = props;

  const localStorageIsAutoSync = localStorage.getItem('isAutoSync');

  let DSZ_URL = process.env.REACT_APP_DSZ_WEBSITE_URL
  if (window.location.host.indexOf('test.com') >= 0) {
    DSZ_URL = 'http://dsz.test.com'
  }
  const LEGACY_DSZ_URL = process.env.REACT_APP_DSZ_LEGACY_WEBSITE_URL || process.env.REACT_APP_DSZ_WEBSITE_URL;

  //console.log("SidebarMenu",showFeatureItems);
  const toggleSidebarMobile = () => setSidebarToggleMobile(false);

  const toggleSidebarMobileAutoSync = () => {
    setSidebarToggleMobile(false);
    setPriceSyncStateIntial();
  };

  const [dropshipzoneOpen, setDropshipzoneOpen] = useState(true);
  const toggleDropshipzone = (event) => {
    setDropshipzoneOpen(!dropshipzoneOpen);
    event.preventDefault();
  };

  const [newslettersOpen, setNewslettersOpen] = useState(false);
  const toggleNewsletters = (event) => {
    setNewslettersOpen(!newslettersOpen);
    event.preventDefault();
  };

  const [toolsOpen, setToolsOpen] = useState(false);
  const toggleTools = (event) => {
    setToolsOpen(!toolsOpen);
    event.preventDefault();
  };

  const [priceUpdateOpen, setPriceUpdateOpen] = useState(false);
  const togglePriceUpdate = (event) => {
    setPriceUpdateOpen(!priceUpdateOpen);
    event.preventDefault();
  };

  //MyProducts
  const [myProductsOpen, setMyProductsOpen] = useState(true);
  const toggleMyProducts = (event) => {
    setMyProductsOpen(!myProductsOpen);
    event.preventDefault();
  };

  const [myStoreOpen, setMyStoreOpen] = useState(false);
  const toggleMyStore = (event) => {
    setMyStoreOpen(!myStoreOpen);
    event.preventDefault();
  };

  const debugAccounts = [
    'jenny_leon@163.com',
    'sofortig_dev@163.com',
    'it@newaim.com.au'
  ];
  const { email } = useSelector((state) => state.auth);
  const { isShopifyUser } = useSelector((state) => state.profile);
  const localStorageIsShopifyUser = localStorage.getItem('isShopifyUser');
  let showDebug =
    debugAccounts.includes(email) ||
    (email && email.endsWith('163.com')) ||
    (email && email.endsWith('newaim.com.au'));
  showDebug = true;

  return (
    <>
      <PerfectScrollbar options={{suppressScrollY: false}}>
        {sidebarUserbox && <SidebarUserbox />}
        {!showFeatureItems5 && (<div className="sidebar-navigation">
          <div className="sidebar-header">
            <span></span>
          </div>
          <ul>
            <li>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/MyAccount">
                <span className="sidebar-icon">
                  <Users />
                </span>
                My Account
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>
            </li>
            <li>
              <a
                href="#/"
                onClick={toggleDropshipzone}
                className={clsx({ active: dropshipzoneOpen })}>
                <span className="sidebar-icon">
                  <Gift />
                </span>
                <span className="sidebar-item-label">Dropshipzone</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRight />
                </span>
              </a>
              <Collapse isOpen={dropshipzoneOpen}>
                <ul style={{ paddingTop: '0.75rem', paddingBottom: '1rem' }}>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/ShopifyCatalogue">
                      <span className="sidebar-icon">
                        <Grid />
                      </span>
                      <span className="sidebar-item-label">
                        Product Catalogue
                      </span>
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Orders"
                      activeClassName="active"
                      className="nav-link-simple">
                      <span className="sidebar-icon">
                        <Inbox />
                      </span>
                      <span className="sidebar-item-label">
                        Orders
                      </span>
                    </NavLink>
                  </li> */}
                  {showFeatureItems2 && (
                    <>
                      <li>
                        <NavLink
                          onClick={toggleSidebarMobile}
                          to="/PlaceOrders"
                          activeClassName="active"
                          className="nav-link-simple">
                          <span className="sidebar-icon">
                            <PlusSquare />
                          </span>
                          <span className="sidebar-item-label">
                            Place Orders
                          </span>
                        </NavLink>
                      </li>
                    </>
                  )}
                  {showFeatureItems && (
                    <>
                      <li>
                        <NavLink
                          onClick={toggleSidebarMobile}
                          to="/UnpaidOrders"
                          activeClassName="active"
                          className="nav-link-simple">
                          <span className="sidebar-icon">
                            <Columns />
                          </span>
                          <span className="sidebar-item-label">
                            Unpaid Orders
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={toggleSidebarMobile}
                          to="/OrdersHistory"
                          activeClassName="active"
                          className="nav-link-simple">
                          <span className="sidebar-icon">
                            <Award />
                          </span>
                          <span className="sidebar-item-label">
                            Orders History
                          </span>
                        </NavLink>
                      </li>
                    </>
                  )}
                </ul>
              </Collapse>
            </li>
            <li>
              <a
                href="#/"
                onClick={toggleMyProducts}
                className={clsx({ active: myProductsOpen })}>
                <span className="sidebar-icon">
                  <ShoppingBag />
                </span>
                <span className="sidebar-item-label">
                  My Store
                  {/*  <Badge color="danger" className="rounded-circle mr-4">
                    7
                  </Badge> */}
                </span>

                <span className="sidebar-icon-indicator">
                  <ChevronRight />
                </span>
              </a>
              <Collapse isOpen={myProductsOpen}>
                <ul style={{ paddingTop: '0.75rem', paddingBottom: '1rem' }}>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/MyProducts">
                      <span className="sidebar-icon">
                        <CheckSquare />
                      </span>
                      <span className="sidebar-item-label">My Products</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/ProductInfoUpdate">
                      <span className="sidebar-icon">
                        <Info />
                      </span>
                      <span className="sidebar-item-label">
                        Product Info Update
                      </span>
                    </NavLink>
                  </li>
                  {localStorageIsAutoSync == 'false' && (
                    <>
                      <li>
                        <NavLink
                          onClick={toggleSidebarMobile}
                          to="/ProductPriceUpdate">
                          <span className="sidebar-icon">
                            <DollarSign />
                          </span>
                          <span className="sidebar-item-label">
                            Product Price Update
                          </span>
                        </NavLink>
                      </li>

                      {showDebug && (
                        <li>
                          <NavLink
                            onClick={toggleSidebarMobile}
                            to="/SpecialPriceUpdate">
                            <span className="sidebar-icon">
                              <Tag />
                            </span>
                            <span className="sidebar-item-label">
                              Special Price Update
                            </span>
                          </NavLink>
                        </li>
                      )}

                      <li>
                        <NavLink
                          onClick={toggleSidebarMobile}
                          to="/PriceUpdateHistory">
                          <span className="sidebar-icon">
                            <FileText />
                          </span>
                          <span className="sidebar-item-label">
                            Price Update History
                          </span>
                        </NavLink>
                      </li>
                    </>
                  )}
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/InventoryUpdateLog">
                      <span className="sidebar-icon">
                        <Database />
                      </span>
                      <span className="sidebar-item-label">
                        Inventory Update Log
                      </span>
                    </NavLink>
                  </li>
                  {localStorageIsAutoSync == 'false' && (
                    <>
                      <li>
                        <NavLink
                          onClick={toggleSidebarMobile}
                          to="/ProductPriceUpdateLog">
                          <span className="sidebar-icon">
                            <Database />
                          </span>
                          <span className="sidebar-item-label">
                            Product Price Update Log
                          </span>
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          onClick={toggleSidebarMobile}
                          to="/SpecialPriceUpdateLog">
                          <span className="sidebar-icon">
                            <Database />
                          </span>
                          <span className="sidebar-item-label">
                            Special Price Update Log
                          </span>
                        </NavLink>
                      </li>
                    </>
                  )}
                  {showFeatureItems3 && (
                    <>
                      {' '}
                      <li>
                        <NavLink
                          onClick={toggleSidebarMobileAutoSync}
                          to="/AutoSync">
                          <span className="sidebar-icon">
                            <RefreshCw />
                          </span>
                          <span className="sidebar-item-label">
                            Auto Price Sync
                          </span>
                          <Badge color="success" className="m-1">
                            NEW FEATURE
                          </Badge>
                        </NavLink>
                      </li>
                    </>
                  )}
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/ShippingCostInquiry">
                      <span className="sidebar-icon">
                        <Truck />
                      </span>
                      <span className="sidebar-item-label">
                        Shipping Cost Inquiry
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>

            <li>
              {/*  <a
                href="#/"
                onClick={toggleSettings}
                className={clsx({ active: settingsOpen })}>
                <span className="sidebar-icon">
                  <Settings />
                </span>
                <span className="sidebar-item-label">Settings</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRight />
                </span>
              </a>
              <Collapse isOpen={settingsOpen}> */}

              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/settings">
                <span className="sidebar-icon">
                  <Settings />
                </span>
                Settings
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/help">
                <span className="sidebar-icon">
                  <BookOpen />
                </span>
                Help
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>

              {/*   <li>
                    <NavLink onClick={toggleSidebarMobile} to="/ShippingRules">
                      <span className="sidebar-icon">
                        <Slack />
                      </span>
                      <span className="sidebar-item-label">Shipping Rules</span>
                    </NavLink>
                  </li> */}

              {/*  </Collapse> */}
            </li>
          </ul>
        </div>)}
        {showFeatureItems5 && (<div className="sidebar-navigation">
          {/* <div className="sidebar-header">
          </div> */}
          <div className='sidebar-menu'>
            <ul>
              <li className='title-box'>
                <span className="sidebar-title">DROPSHIPZONE</span>
              </li>
              {/* <li>
                <a>
                  <span className='sidebar-icon2'><img src={ShoppingCartSvg} /></span>
                  <span className="sidebar-item-label2">
                    Product Catalogue
                  </span>
                </a>
              </li> */}
              <li className='active-li'>
                <a href={DSZ_URL + '/categories/new-arrivals.html'}
                  onClick={toggleSidebarMobile}>
                  <span className='sidebar-icon2'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M3 3H4.55556L4.86667 4.55556M6.11111 10.7778H13.8889L17 4.55556H4.86667M6.11111 10.7778L4.86667 4.55556M6.11111 10.7778L4.32775 12.5611C3.83778 13.0511 4.1848 13.8889 4.87772 13.8889H13.8889M13.8889 13.8889C13.0298 13.8889 12.3333 14.5853 12.3333 15.4444C12.3333 16.3036 13.0298 17 13.8889 17C14.748 17 15.4444 16.3036 15.4444 15.4444C15.4444 14.5853 14.748 13.8889 13.8889 13.8889ZM7.66667 15.4444C7.66667 16.3036 6.97022 17 6.11111 17C5.252 17 4.55556 16.3036 4.55556 15.4444C4.55556 14.5853 5.252 13.8889 6.11111 13.8889C6.97022 13.8889 7.66667 14.5853 7.66667 15.4444Z" stroke="#E6E6EE" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                    </svg></span>
                  <span className="sidebar-item-label2">
                    Product Catalogue
                  </span>
                </a>
              </li>
              <li className='active-li'>
                <NavLink
                  onClick={toggleSidebarMobile}
                  to="/orders">
                  <span className='sidebar-icon2'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M17 10.875V4.75C17 3.7835 16.2165 3 15.25 3H4.75C3.7835 3 3 3.7835 3 4.75V10.875M17 10.875V15.25C17 16.2165 16.2165 17 15.25 17H4.75C3.7835 17 3 16.2165 3 15.25V10.875M17 10.875H14.7374C14.5054 10.875 14.2828 10.9672 14.1187 11.1313L12.0063 13.2437C11.8422 13.4078 11.6196 13.5 11.3876 13.5H8.61244C8.38037 13.5 8.15781 13.4078 7.99372 13.2437L5.88128 11.1313C5.71719 10.9672 5.49463 10.875 5.26256 10.875H3" stroke="#E6E6EE" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                  </svg></span>
                  <span className="sidebar-item-label2">
                    Orders
                  </span>
                </NavLink>
              </li>
              <li className='active-li'>
                <a href={LEGACY_DSZ_URL + '/rsds/credit/'}
                  onClick={toggleSidebarMobile}>
                  <span className='sidebar-icon2'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M13.8889 7.66665V6.1111C13.8889 5.25199 13.1924 4.55554 12.3333 4.55554H4.55556C3.69645 4.55554 3 5.25199 3 6.1111V10.7778C3 11.6369 3.69645 12.3333 4.55556 12.3333H6.11111M7.66667 15.4444H15.4444C16.3036 15.4444 17 14.748 17 13.8889V9.22221C17 8.3631 16.3036 7.66665 15.4444 7.66665H7.66667C6.80756 7.66665 6.11111 8.3631 6.11111 9.22221V13.8889C6.11111 14.748 6.80756 15.4444 7.66667 15.4444ZM13.1111 11.5555C13.1111 12.4147 12.4147 13.1111 11.5556 13.1111C10.6964 13.1111 10 12.4147 10 11.5555C10 10.6964 10.6964 9.99999 11.5556 9.99999C12.4147 9.99999 13.1111 10.6964 13.1111 11.5555Z" stroke="#FDFCFB" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                  </svg></span>
                  <span className="sidebar-item-label2">
                    Wallet
                  </span>
                </a>
              </li>
              <li>
                <a href='javascript:;'
                  onClick={toggleNewsletters}
                  className={clsx({ active: newslettersOpen })}>
                  <span className='sidebar-icon2'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M9.22222 5.2417V15.6313C9.22222 16.3872 8.60941 17 7.85347 17C7.27502 17 6.75902 16.6364 6.56446 16.0916L4.89503 11.3089M14.6667 10.7778C15.9553 10.7778 17 9.73311 17 8.44444C17 7.15578 15.9553 6.11111 14.6667 6.11111M4.89503 11.3089C3.78112 10.8354 3 9.73114 3 8.44444C3 6.72622 4.39289 5.33333 6.11111 5.33333H7.53607C10.7255 5.33333 13.4665 4.37348 14.6667 3L14.6667 13.8889C13.4665 12.5154 10.7255 11.5556 7.53607 11.5556L6.11109 11.5555C5.67958 11.5555 5.2686 11.4677 4.89503 11.3089Z" stroke="#E6E6EE" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                  </svg></span>
                  <span className="sidebar-item-label2">
                    Notifications
                  </span>
                  <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                    <ChevronRight />
                  </span>
                </a>
                <Collapse isOpen={newslettersOpen}>
                  <ul className='p-0'>
                    <li className='active-li p-0'>
                      <a href={LEGACY_DSZ_URL + '/notice/index/review/'} className='sidebar-noicon-item'
                        onClick={toggleSidebarMobile}>
                        <span className="sidebar-noicon-item-text">
                          Price Updates
                        </span>
                      </a>
                    </li>
                    <li className='active-li p-0'>
                      <a href={LEGACY_DSZ_URL + '/notice/index/promotion/'} className='sidebar-noicon-item'
                        onClick={toggleSidebarMobile}>
                        <span className="sidebar-noicon-item-text">
                          Promotion Updates
                        </span>
                      </a>
                    </li>
                    <li className='active-li p-0'>
                      <a href={LEGACY_DSZ_URL + '/notice/index/'} className='sidebar-noicon-item'
                        onClick={toggleSidebarMobile}>
                        <span className="sidebar-noicon-item-text">
                          Product Content Updates
                        </span>
                      </a>
                    </li>
                    <li className='active-li p-0'>
                      <a href={LEGACY_DSZ_URL + '/notice/index/site/'} className='sidebar-noicon-item'
                        onClick={toggleSidebarMobile}>
                        <span className="sidebar-noicon-item-text">
                          System Updates
                        </span>
                      </a>
                    </li>
                  </ul>
                </Collapse>
              </li>
              <li>
                <a href='javascript:;'
                  onClick={toggleTools}
                  className={clsx({ active: toolsOpen })}>
                  <span className='sidebar-icon2'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M3 13.5L3 14.375C3 15.8247 4.17525 17 5.625 17L14.375 17C15.8247 17 17 15.8247 17 14.375L17 13.5M13.5 10L10 13.5M10 13.5L6.5 10M10 13.5L10 3" stroke="#E6E6EE" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  </span>
                  <span className="sidebar-item-label2">
                    Downloads
                  </span>
                  <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                    <ChevronRight />
                  </span>
                </a>
                <Collapse isOpen={toolsOpen}>
                  <ul className='p-0'>
                    <li className='active-li p-0'>
                      <a href={LEGACY_DSZ_URL + '/rsds/download/'} className='sidebar-noicon-item'
                        onClick={toggleSidebarMobile}>
                        <span className="sidebar-noicon-item-text">
                          SKU
                        </span>
                      </a>
                    </li>
                    <li className='active-li p-0'>
                      <a href={LEGACY_DSZ_URL + '/rsds/download/postage/'} className='sidebar-noicon-item'
                        onClick={toggleSidebarMobile}>
                        <span className="sidebar-noicon-item-text">
                          Shipping
                        </span>
                      </a>
                    </li>
                    <li className='active-li p-0'>
                      <a href={LEGACY_DSZ_URL + '/rsds/order/invoice/'} className='sidebar-noicon-item'
                        onClick={toggleSidebarMobile}>
                        <span className="sidebar-noicon-item-text">
                          Monthly Invoices
                        </span>
                      </a>
                    </li>
                    <li className='active-li p-0'>
                      <a href={LEGACY_DSZ_URL + '/rsds/download/manual/'} className='sidebar-noicon-item'
                        onClick={toggleSidebarMobile}>
                        <span className="sidebar-noicon-item-text">
                          User Guides
                        </span>
                      </a>
                    </li>
                    {/* <li className='active-li p-0'>
                      <a href={DSZ_URL + '/rsds/download/menu/'} className='sidebar-noicon-item'
                        onClick={toggleSidebarMobile}>
                        <span className="sidebar-noicon-item-text">
                          Brand materials
                        </span>
                      </a>
                    </li> */}
                    <li className='active-li p-0'>
                      <a href={LEGACY_DSZ_URL + '/rsds/download/authorisation/'} className='sidebar-noicon-item'
                        onClick={toggleSidebarMobile}>
                        <span className="sidebar-noicon-item-text">
                          Authorisation Letters
                        </span>
                      </a>
                    </li>
                  </ul>
                </Collapse>
              </li>
              <li className='active-li'>
                <a href={LEGACY_DSZ_URL + '/rsds/order/message/'}
                  onClick={toggleSidebarMobile}>
                  <span className='sidebar-icon2'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M6.88889 8.05558H6.89667M10 8.05558H10.0078M13.1111 8.05558H13.1189M7.66667 12.7222H4.55556C3.69645 12.7222 3 12.0258 3 11.1667V4.94447C3 4.08536 3.69645 3.38892 4.55556 3.38892H15.4444C16.3036 3.38892 17 4.08536 17 4.94447V11.1667C17 12.0258 16.3036 12.7222 15.4444 12.7222H11.5556L7.66667 16.6111V12.7222Z" stroke="#E6E6EE" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                  </svg></span>
                  <span className="sidebar-item-label2">
                    Customer Service
                  </span>
                </a>
              </li>
              <li className='active-li'>
                <a href={LEGACY_DSZ_URL + '/rsds/statistics/'}
                  onClick={toggleSidebarMobile}>
                  <span className='sidebar-icon2'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M13.5 6.5V13.5M10 9.125V13.5M6.5 11.75V13.5M4.75 17H15.25C16.2165 17 17 16.2165 17 15.25V4.75C17 3.7835 16.2165 3 15.25 3H4.75C3.7835 3 3 3.7835 3 4.75V15.25C3 16.2165 3.7835 17 4.75 17Z" stroke="#E6E6EE" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                  </svg></span>
                  <span className="sidebar-item-label2">
                    Sales Data
                  </span>
                </a>
              </li>
            </ul>
            {(isShopifyUser || localStorageIsShopifyUser == 'true') && (
              <ul>
                <li className='line-nav-bar'>
                  <span style={{ width: '270px', height: '0.7px', backgroundColor: '#E6E6EE' }}></span>
                </li>
                <li className='title-box'>
                  <span className="sidebar-title">SHOPIFY</span>
                </li>
                <li className='active-li'>
                  <NavLink onClick={toggleSidebarMobile} to="/shopify-catalogue">
                    <span className='sidebar-icon2'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M3 3H4.55556L4.86667 4.55556M6.11111 10.7778H13.8889L17 4.55556H4.86667M6.11111 10.7778L4.86667 4.55556M6.11111 10.7778L4.32775 12.5611C3.83778 13.0511 4.1848 13.8889 4.87772 13.8889H13.8889M13.8889 13.8889C13.0298 13.8889 12.3333 14.5853 12.3333 15.4444C12.3333 16.3036 13.0298 17 13.8889 17C14.748 17 15.4444 16.3036 15.4444 15.4444C15.4444 14.5853 14.748 13.8889 13.8889 13.8889ZM7.66667 15.4444C7.66667 16.3036 6.97022 17 6.11111 17C5.252 17 4.55556 16.3036 4.55556 15.4444C4.55556 14.5853 5.252 13.8889 6.11111 13.8889C6.97022 13.8889 7.66667 14.5853 7.66667 15.4444Z" stroke="#E6E6EE" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                    </svg></span>
                    <span className="sidebar-item-label2">
                      Shopify Catalogue
                    </span>
                  </NavLink>
                </li>
                <li>
                  <a href='javascript:;' onClick={toggleMyStore}
                    className={clsx({ active: myStoreOpen })}>
                    <span className='sidebar-icon2'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M13.1111 9.22222V6.11111C13.1111 4.39289 11.7182 3 9.99999 3C8.28177 3 6.88888 4.39289 6.88888 6.11111V9.22222M4.55555 7.66667H15.4444L16.2222 17H3.77777L4.55555 7.66667Z" stroke="#E6E6EE" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                    </svg></span>
                    <span className="sidebar-item-label2">
                      My Store
                    </span>
                    <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                      <ChevronRight />
                    </span>
                  </a>
                  <Collapse isOpen={myStoreOpen}>
                    <ul>
                      <li className='active-li p-0'>
                        <NavLink onClick={toggleSidebarMobile} to="/my-products" className='sidebar-noicon-item'>
                          <span className="sidebar-noicon-item-text">
                            My Products
                          </span>
                        </NavLink>
                      </li>
                      <li className='active-li p-0'>
                        <NavLink onClick={toggleSidebarMobile} to="/product-content-updates" className='sidebar-noicon-item'>
                          <span className="sidebar-noicon-item-text">
                            Product Content Updates
                          </span>
                        </NavLink>
                      </li>
                      {/* <li className='active-li p-0'>
                        <NavLink
                          onClick={toggleSidebarMobile} className='sidebar-noicon-item'
                          to="/InventoryUpdateLog">
                          <span className="sidebar-noicon-item-text">
                            Inventory update log
                          </span>
                        </NavLink>
                      </li> */}
                    </ul>
                  </Collapse>
                </li>
                {localStorageIsAutoSync == 'false' &&
                  (<li>
                    <a href='javascript:;'
                      onClick={togglePriceUpdate}
                      className={clsx({ active: priceUpdateOpen })}>
                      <span className='sidebar-icon2'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M3 3V7.375H3.50883M16.9458 9.125C16.5153 5.67198 13.5697 3 10 3C7.06229 3 4.54726 4.80965 3.50883 7.375M3.50883 7.375H7.375M17 17V12.625H16.4912M16.4912 12.625C15.4527 15.1904 12.9377 17 10 17C6.43033 17 3.48474 14.328 3.05416 10.875M16.4912 12.625H12.625" stroke="#E6E6EE" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                      </svg></span>
                      <span className="sidebar-item-label2">
                        Price Change Log
                      </span>
                      <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                        <ChevronRight />
                      </span>
                    </a>
                    <Collapse isOpen={priceUpdateOpen}>
                      <ul>
                        <li className='active-li p-0'>
                          <NavLink
                            onClick={toggleSidebarMobile} className='sidebar-noicon-item'
                            to="/price-updates">
                            <span className="sidebar-noicon-item-text">
                              Price Updates
                            </span>
                          </NavLink>
                        </li>
                        <li className='active-li p-0'>
                          <NavLink
                            onClick={toggleSidebarMobile} className='sidebar-noicon-item'
                            to="/price-updates-log">
                            <span className="sidebar-noicon-item-text">
                              Price Updates Log
                            </span>
                          </NavLink>
                        </li>

                        {showDebug && (
                          <li className='active-li p-0'>
                            <NavLink className='sidebar-noicon-item'
                              onClick={toggleSidebarMobile}
                              to="/promotion-updates">
                              <span className="sidebar-noicon-item-text">
                                Promotion Updates
                              </span>
                            </NavLink>
                          </li>
                        )}
                        <li className='active-li p-0'>
                          <NavLink className='sidebar-noicon-item'
                            onClick={toggleSidebarMobile}
                            to="/promotion-updates-log">
                            <span className="sidebar-noicon-item-text">
                              Promotion Updates Log
                            </span>
                          </NavLink>
                        </li>

                        <li className='active-li p-0'>
                          <NavLink className='sidebar-noicon-item'
                            onClick={toggleSidebarMobile}
                            to="/price-updates-history">
                            <span className="sidebar-noicon-item-text">
                              Price Updates History
                            </span>
                          </NavLink>
                        </li>
                      </ul>
                    </Collapse>
                  </li>)}
                <li className='active-li'>
                  <NavLink
                    onClick={toggleSidebarMobileAutoSync}
                    to="/auto-price-sync">
                    <span className='sidebar-icon2'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M3 3V7.375H3.50883M16.9458 9.125C16.5153 5.67198 13.5697 3 10 3C7.06229 3 4.54726 4.80965 3.50883 7.375M3.50883 7.375H7.375M17 17V12.625H16.4912M16.4912 12.625C15.4527 15.1904 12.9377 17 10 17C6.43033 17 3.48474 14.328 3.05416 10.875M16.4912 12.625H12.625" stroke="#E6E6EE" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                    </svg></span>
                    <span className="sidebar-item-label2">
                      Auto Price Sync
                    </span>
                  </NavLink>
                </li>
                <li className='active-li'>
                  <NavLink className='sidebar-noicon-item'
                    onClick={toggleSidebarMobile}
                    to="/shipping-calculator">
                    <span className='sidebar-icon2'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M10.7778 13.1111V5.33332C10.7778 4.90376 10.4296 4.55554 10 4.55554H3.77778C3.34822 4.55554 3 4.90376 3 5.33332V13.1111C3 13.5407 3.34822 13.8889 3.77778 13.8889H4.55556M10.7778 13.1111C10.7778 13.5407 10.4296 13.8889 10 13.8889H7.66667M10.7778 13.1111L10.7778 6.88888C10.7778 6.45932 11.126 6.1111 11.5556 6.1111H13.5667C13.773 6.1111 13.9708 6.19304 14.1167 6.3389L16.7722 8.9944C16.9181 9.14026 17 9.33809 17 9.54437V13.1111C17 13.5407 16.6518 13.8889 16.2222 13.8889H15.4444M10.7778 13.1111C10.7778 13.5407 11.126 13.8889 11.5556 13.8889H12.3333M4.55556 13.8889C4.55556 14.748 5.252 15.4444 6.11111 15.4444C6.97022 15.4444 7.66667 14.748 7.66667 13.8889M4.55556 13.8889C4.55556 13.0298 5.252 12.3333 6.11111 12.3333C6.97022 12.3333 7.66667 13.0298 7.66667 13.8889M12.3333 13.8889C12.3333 14.748 13.0298 15.4444 13.8889 15.4444C14.748 15.4444 15.4444 14.748 15.4444 13.8889M12.3333 13.8889C12.3333 13.0298 13.0298 12.3333 13.8889 12.3333C14.748 12.3333 15.4444 13.0298 15.4444 13.8889" stroke="#E6E6EE" strokeWidth="1.67" />
                    </svg></span>
                    <span className="sidebar-item-label2">
                      Shipping Calculator
                    </span>
                  </NavLink>
                </li>
                <li className='active-li'>
                  <NavLink
                    onClick={toggleSidebarMobile}
                    to="/settings">
                    <span className='sidebar-icon2'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M10 4.75V3M10 4.75C9.0335 4.75 8.25 5.5335 8.25 6.5C8.25 7.4665 9.0335 8.25 10 8.25M10 4.75C10.9665 4.75 11.75 5.5335 11.75 6.5C11.75 7.4665 10.9665 8.25 10 8.25M4.75 15.25C5.7165 15.25 6.5 14.4665 6.5 13.5C6.5 12.5335 5.7165 11.75 4.75 11.75M4.75 15.25C3.7835 15.25 3 14.4665 3 13.5C3 12.5335 3.7835 11.75 4.75 11.75M4.75 15.25V17M4.75 11.75V3M10 8.25V17M15.25 15.25C16.2165 15.25 17 14.4665 17 13.5C17 12.5335 16.2165 11.75 15.25 11.75M15.25 15.25C14.2835 15.25 13.5 14.4665 13.5 13.5C13.5 12.5335 14.2835 11.75 15.25 11.75M15.25 15.25V17M15.25 11.75V3" stroke="#E6E6EE" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                    </svg></span>
                    <span className="sidebar-item-label2">
                      Settings
                    </span>
                  </NavLink>
                </li>
              </ul>)}
            {/* <div style={{ paddingTop: padding }}>
              <ul>
                <li className='active-li'>
                  <NavLink
                    onClick={toggleSidebarMobile}
                    to="/Help">
                    <span className='sidebar-icon2'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M7.06596 7.66667C7.49305 6.76041 8.64544 6.11111 10 6.11111C11.7183 6.11111 13.1112 7.15578 13.1112 8.44444C13.1112 9.5329 12.1174 10.4473 10.7733 10.7051C10.3515 10.7861 10 11.126 10 11.5556M10 13.8889H10.0078M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="#E6E6EE" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                    </svg></span>
                    <span className="sidebar-item-label2">
                      Support
                    </span>
                  </NavLink>
                </li>
                <li className='active-li'>
                  <NavLink
                    onClick={toggleSidebarMobile}
                    to="/MyAccount">
                    <span className='sidebar-icon2'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M13.1111 6.11111C13.1111 7.82933 11.7182 9.22222 9.99999 9.22222C8.28177 9.22222 6.88888 7.82933 6.88888 6.11111C6.88888 4.39289 8.28177 3 9.99999 3C11.7182 3 13.1111 4.39289 13.1111 6.11111Z" stroke="#E6E6EE" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M9.99999 11.5556C6.9931 11.5556 4.55554 13.9931 4.55554 17H15.4444C15.4444 13.9931 13.0069 11.5556 9.99999 11.5556Z" stroke="#E6E6EE" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                    </svg></span>
                    <span className="sidebar-item-label2">
                      My Account
                    </span>
                  </NavLink>
                </li>

                <li className='active-li'>
                  <a
                    onClick={(e) => handleSignOut(e)}>
                    <span className='sidebar-icon2'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                        <path d="M9.22222 13.7386L6.11111 10.6275M6.11111 10.6275L9.22222 7.51638M6.11111 10.6275L17 10.6275M13.1111 13.7386V14.5164C13.1111 15.805 12.0664 16.8497 10.7778 16.8497H5.33333C4.04467 16.8497 3 15.805 3 14.5164V6.73861C3 5.44994 4.04467 4.40527 5.33333 4.40527H10.7778C12.0664 4.40527 13.1111 5.44994 13.1111 6.73861V7.51638" stroke="#E6E6EE" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </span>
                    <span className="sidebar-item-label2">
                      Log Out
                    </span>
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>)}
      </PerfectScrollbar >
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  showFeatureItems: state.ThemeOptions.showFeatureItems,
  showFeatureItems2: state.ThemeOptions.showFeatureItems2,
  showFeatureItems3: state.ThemeOptions.showFeatureItems3,
  showFeatureItems5: state.ThemeOptions.showFeatureItems5,
  isAutoSync: state.ThemeOptions.isAutoSync
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
  setPriceSyncStateIntial: (enable) => dispatch(setPriceSyncStateIntial(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
