import {
  SET_PAGE_LOADING,
  CLEAR_ORDERS_DETAILS,
  SET_ORDER_DETAILS,
  SET_ORDERS_PAGE_NO,
  SET_ORDERS_STATUS,
  SET_STATUS_TOTAL,
  CLEAR_FILTER,
  SET_ORDER_TYPE,
  SET_ARCHIVE_ORDER_LIST,
  SET_ARCHIVE_ORDER_PAGE_NO,
  SET_ARCHIVE_ORDER_TOTAL,
  RESET_ORDER_STORE
} from './ordersConstants';

const initialState = {
  orderType: 'default', // default\archived
  pageLoading: false,

  ordersList: [],
  totalCount: 0,
  totalPages: 0,
  pageSize: 20,
  currentPage: 1,

  status: '',
  allTotal: 0,
  pendingTotal: 0,
  processingTotal: 0,
  unpaidTotal: 0,
  dispatchedTotal: 0,
  cancelledTotal: 0,

  archiveOrdersList: [],
  archiveOrderPageNo: 1,
  archiveOrderTotal: 0,
  archiveOrderLimit: 20
};

export default function ordersListReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_ORDER_TYPE:
      return {
        ...state,
        orderType: payload.orderType
      };
    case SET_ARCHIVE_ORDER_LIST:
      return {
        ...state,
        archiveOrdersList: payload.list
      };
    case SET_ARCHIVE_ORDER_PAGE_NO:
      return {
        ...state,
        archiveOrderPageNo: payload.pageNo
      };
    case SET_ARCHIVE_ORDER_TOTAL:
      return {
        ...state,
        archiveOrderTotal: payload.total
      };
    case SET_ORDER_DETAILS:
      return {
        ...state,
        ordersList: payload.ordersList,
        totalCount: payload.totalCount,
        totalPages: payload.totalPages,
        currentPage: payload.currentPage,
        pageSize: payload.pageSize,
        status: payload.status
      };
    case SET_PAGE_LOADING:
      return {
        ...state,
        pageLoading: payload.isLoading
      };

    case SET_ORDERS_PAGE_NO:
      return {
        ...state,
        currentPage: payload
      };
    case SET_ORDERS_STATUS:
      return {
        ...state,
        status: payload.status,
        currentPage: 1
      };
    case SET_STATUS_TOTAL:
      return {
        ...state,
        allTotal: payload.all_total,
        pendingTotal: payload.pending_total,
        processingTotal: payload.processing_total,
        unpaidTotal: payload.unpaid_total,
        dispatchedTotal: payload.dispatched_total,
        cancelledTotal: payload.cancelled_total
      };
    case CLEAR_FILTER:
      return {
        ...state,
        pageSize: 20,
        currentPage: 1,
        status: ''
      };
    case CLEAR_ORDERS_DETAILS:
      return {
        ...state,
        ordersList: []
      };
    case RESET_ORDER_STORE:
      return initialState;
    default:
      return state;
  }
}
