import React, { useEffect, lazy, Suspense } from 'react';
import { Switch, Route, Redirect, useLocation, useHistory} from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ClimbingBoxLoader } from 'react-spinners';
import { useSelector, useDispatch } from 'react-redux';
import {
  setFeatureFlag,
  setFeatureFlag4,
  setFeatureFlag5
} from './reducers/ThemeOptions';
import qs from 'qs';
// Layout Blueprints

import { LeftSidebar, MinimalLayout } from './layout-blueprints';

// Example Pages

import Logout from './components/auth/Logout';
import Login from './components/auth/LoginForm';

import Auth from './components/auth/Auth';
import AuthCallBack from './components/auth/AuthCallBack';

import PageError404 from './components/pages/PageError404';
import PageError500 from './components/pages/PageError500';
import PageError505 from './components/pages/PageError505';
import { isMvpTestUser } from './components/auth/authActions';

/* import {
  createNotification
} from './components/notification/notificationActions'; */
const Maintenance  = lazy(() => import('./components/pages/PageMaintenance/maintenance'));

//Sofortig Pages
const MyAccount = lazy(() => import('./components/myAccount'));
const ProductCatalogue = lazy(() =>
  import('./components/products/ProductCatalogue')
);

const UnpaidOrders = lazy(() =>
  import('./components/products/UnpaidOrders/Pages')
);
const PlaceOrders = lazy(() =>
  import('./components/products/PlaceOrders/Pages')
);
const Checkout = lazy(() =>
  import('./components/products/UnpaidOrders/Pages/checkout')
);
const OrderDetails = lazy(() =>
  import('./components/products/UnpaidOrders/Pages/orderDetails')
);
const OrdersList = lazy(() => import('./components/products/Orders/Pages'));
const OrderNew = lazy(() => import('./components/products/NewOrder/Pages'));
const OrderPay = lazy(() =>
  import('./components/products/NewOrder/Pages/ShippingAndPayment')
);
const OrderConfirmed = lazy(() =>
  import('./components/products/NewOrder/Pages/OrderConfirmed')
);
const OrderDetail = lazy(() => import('./components/products/OrderDetail'));

const BatchOrderNew = lazy(() =>
  import('./components/products/NewBatchOrder/Pages/NewOrder')
);
const BatchOrderReview = lazy(() =>
  import('./components/products/NewBatchOrder/Pages/ReviewAndShipping')
);
const BatchOrderPay = lazy(() =>
  import('./components/products/NewBatchOrder/Pages/Pay')
);
const BatchOrderConfirmed = lazy(() =>
  import('./components/products/NewBatchOrder/Pages/Confirmed')
);
//Payment
const Payment = lazy(() =>
  import('./components/products/UnpaidOrders/Pages/payment')
);

//aymentPaypal
const PaymentPaypal = lazy(() =>
  import('./components/products/UnpaidOrders/Pages/paymentPaypal')
);

const PaymentConfirmation = lazy(() =>
  import('./components/products/UnpaidOrders/Pages/paymentConfirmation')
);

const PaymentDatafeed = lazy(() =>
  import('./components/products/UnpaidOrders/Pages/paymentDatafeed')
);
const PaymentSuccess = lazy(() =>
  import('./components/products/UnpaidOrders/Pages/paymentSuccess')
);
const PaymentFail = lazy(() =>
  import('./components/products/UnpaidOrders/Pages/paymentFail')
);
const PaymentCancel = lazy(() =>
  import('./components/products/UnpaidOrders/Pages/paymentCancel')
);

const OrdersHistory = lazy(() =>
  import('./components/products/OrdersHistory/Pages')
);
const InventoryUpdateLog = lazy(() =>
  import('./components/products/InventoryUpdateLog')
);
const MyProducts = lazy(() => import('./components/products/MyProducts/Pages'));
const ProductInfoUpdate = lazy(() =>
  import('./components/products/ProductInfoUpdate')
);
const ShippingCostInquiry = lazy(() =>
  import('./components/shippingCostInquiry')
);
const AutoSync = lazy(() => import('./components/products/AutoSync'));
const ProductPriceUpdate = lazy(() =>
  import('./components/products/ProductPriceUpdate')
);
const ProductPriceUpdateLog = lazy(() =>
  import('./components/products/ProductPriceUpdateLog')
);
const SpecialPriceUpdate = lazy(() =>
  import('./components/products/SpecialPriceUpdate')
);
const SpecialPriceUpdateLog = lazy(() =>
  import('./components/products/SpecialPriceUpdateLog')
);
const PriceUpdateHistory = lazy(() =>
  import('./components/products/PriceUpdateHistory')
);

const Settings = lazy(() => import('./components/settings/Pages'));
const Help = lazy(() => import('./components/help'));
/* const PriceRule = lazy(() => import('./components/settings/PriceRule')); */
const PageRecover = lazy(() => import('./components/auth/PageRecover'));
const ProductDetailPage = lazy(() =>
  import('./components/products/productDetail')
);

const Routes = () => {
  const {
    showFeatureItems,
    showFeatureItems2,
    showFeatureItems3,
    showFeatureItems4,
    showFeatureItems5
  } = useSelector((state) => state.ThemeOptions);

  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    // console.log("Routes ->", showFeatureItems, showFeatureItems2, showFeatureItems3);
    //dispatch(createNotification('m 1','Info'));
    // console.log("qs.parse(location.search, { ignoreQueryPrefix: true }).ff",qs.parse(location.search, { ignoreQueryPrefix: true }).ff);
    let featureFlag2 = showFeatureItems2;
    let featureFlag3 = showFeatureItems3;
    if (!showFeatureItems) {
      let featureFlag =
        qs.parse(location.search, { ignoreQueryPrefix: true }).ff === 'true'
          ? true
          : false;
      featureFlag2 =
        qs.parse(location.search, { ignoreQueryPrefix: true }).ff2 === 'true'
          ? true
          : false;

      //if config enables the feature, ignore the query and blindly allow new feature
      if (process.env.REACT_APP_ENABLE_PRICE_REVIEW_FEATURE === 'true') {
        featureFlag = true;
      }

      // console.log("setFeatureFlag -> Routes =>", featureFlag, featureFlag2, featureFlag3);
      dispatch(
        setFeatureFlag('Route', featureFlag, featureFlag2, featureFlag3)
      );
    }
    /* else {
      return;
    } */

    //Start -> check auto sync flag
    //Note: This section only work through query string
    if (qs.parse(location.search, { ignoreQueryPrefix: true }).ff3) {
      featureFlag3 =
        qs.parse(location.search, { ignoreQueryPrefix: true }).ff3 === 'true'
          ? true
          : false;

      if (process.env.REACT_APP_FEATURE_AUTO_SYNC === 'true') {
        featureFlag3 = true;
      }
      // console.log("Route Auto Sync ->", featureFlag3 , showFeatureItems3);
      if (featureFlag3 !== showFeatureItems3) {
        dispatch(
          setFeatureFlag('Route2', showFeatureItems, featureFlag2, featureFlag3)
        );
      }
    }
    //End -> check auto sync flag
    //Start -> check paypal flag
    //Note: This section only work through query string
    if (qs.parse(location.search, { ignoreQueryPrefix: true }).ff4) {
      let featureFlag4 =
        qs.parse(location.search, { ignoreQueryPrefix: true }).ff4 === 'true'
          ? true
          : false;
      // console.log("Route Auto Sync ->", featureFlag3 , showFeatureItems3);
      if (featureFlag4 !== showFeatureItems4) {
        dispatch(setFeatureFlag4(featureFlag4));
      }
    }
    //End -> paypal flag
    //Start -> check rebranding flag
    //Note: This section only work through query string
    if (qs.parse(location.search, { ignoreQueryPrefix: true }).ff5) {
      let featureFlag5 =
        qs.parse(location.search, { ignoreQueryPrefix: true }).ff5 === 'true'
          ? true
          : false;
      // console.log("Route Auto Sync ->", featureFlag3 , showFeatureItems3);
      if (featureFlag5 !== showFeatureItems5) {
        dispatch(setFeatureFlag5(featureFlag5));
      }
    }

    const dszEmail = localStorage.getItem('dsz_email');
    // if(window.location.host.indexOf('dropshipzone.com.au') >= 0){
    //   dispatch(setFeatureFlag5(true));
    // }
    //End -> rebraning flag
    //Disable - Code for check future flag for selected user

    /* if (
      localStorage.getItem('user_id') &&
      process.env.REACT_APP_FEATURE_USER_LIST
    ) {
      const userList = process.env.REACT_APP_FEATURE_USER_LIST.split(',');

      if (userList.includes(localStorage.getItem('user_id'))) {
        // console.log('User List =>', localStorage.getItem('user_id'), process.env.REACT_APP_FEATURE_USER_LIST);
        dispatch(setFeatureFlag(true, featureFlag2, showFeatureItems3));
      }
    } */

    //add GA4 script
    const isGA = process.env.REACT_APP_ENABLE_GOOGLE_ANALYTICS;
    if (process.env.REACT_APP_ENABLE_GOOGLE_ANALYTICS) {
      //const gaScript = `<!-- Google tag (gtag.js) --><script async src="https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_MEASUREMENT_ID}"></script><script>window.dataLayer = window.dataLayer || [];function gtag() {dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${process.env.REACT_APP_GOOGLE_MEASUREMENT_ID}');</script>`;
      const head = document.getElementsByTagName('head')[0];
      const body = document.getElementsByTagName('body')[0];
      const gaScript = document.createElement('script');
      gaScript.setAttribute('async', '');
      gaScript.setAttribute(
        'src',
        'https://www.googletagmanager.com/gtag/js?id=' +
          process.env.REACT_APP_GOOGLE_MEASUREMENT_ID
      );

      const gaInnerScript = document.createElement('script');
      gaInnerScript.innerHTML =
        "window.dataLayer = window.dataLayer || [];function gtag() {dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '" +
        process.env.REACT_APP_GOOGLE_MEASUREMENT_ID +
        "');";

      head.appendChild(gaScript);
      head.appendChild(gaInnerScript);

      const gtmHeadScript = document.createElement('script');
      gtmHeadScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}');`
      const gtmBodyScript = document.createElement('noscript');
      const iframeScript = document.createElement('iframe');
      iframeScript.setAttribute('src','https://www.googletagmanager.com/ns.html?id=' + process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID)
      iframeScript.setAttribute('height', 0);
      iframeScript.setAttribute('width', 0);
      iframeScript.setAttribute('style', 'display:none;visibility:hidden');
      gtmBodyScript.appendChild(iframeScript);
      head.appendChild(gtmHeadScript);
      body.appendChild(gtmBodyScript);


      //add share GA
      const gaScriptShare = document.createElement('script');
      gaScriptShare.setAttribute('async', '');
      gaScriptShare.setAttribute(
        'src',
        'https://www.googletagmanager.com/gtag/js?id=' +
          process.env.REACT_APP_GOOGLE_MEASUREMENT_ID_SHARE
      );

      const gaInnerScriptShare = document.createElement('script');
      gaInnerScriptShare.innerHTML =
        "window.dataLayer = window.dataLayer || [];function gtag() {dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '" +
        process.env.REACT_APP_GOOGLE_MEASUREMENT_ID_SHARE +
        "');";

      head.appendChild(gaScriptShare);
      head.appendChild(gaInnerScriptShare);

      const gtmHeadScriptShare = document.createElement('script');
      gtmHeadScriptShare.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID_SHARE}');`
      const gtmBodyScriptShare = document.createElement('noscript');
      const iframeScriptShare = document.createElement('iframe');
      iframeScriptShare.setAttribute('src','https://www.googletagmanager.com/ns.html?id=' + process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID_SHARE)
      iframeScriptShare.setAttribute('height', 0);
      iframeScriptShare.setAttribute('width', 0);
      iframeScriptShare.setAttribute('style', 'display:none;visibility:hidden');
      gtmBodyScriptShare.appendChild(iframeScriptShare);
      head.appendChild(gtmHeadScriptShare);
      body.appendChild(gtmBodyScriptShare);
    }
  }, [dispatch]);

  useEffect(() =>{
    if(window.location.host.indexOf('dropshipzone.com.au') >= 0 || window.location.host.indexOf('localhost') >= 0){
      dispatch(setFeatureFlag5(true));
    }

    if(process.env.REACT_APP_MAINTENANCE_MODE == 'true'){
      console.log('to maintenance');
      history.push('/maintenance')
    }

    const isDevEnvironment = window.location.href.indexOf("dev.sofortig") >= 0
    if(window.location.host.indexOf('sofortig.com.au') >= 0){
      console.log('redirect ro retail.dropshipzone.com.au');
      if(isDevEnvironment){
        window.location.href ='http://devretail.dropshipzone.com.au';
      }else{
        window.location.href ='https://retail.dropshipzone.com.au';
      }
    }
  },[dispatch, location.pathname])


  const getTitle = (pathname) => {
    const titleMap = {
      '/login': 'Login | Retailer Portal | Dropshipzone',
      '/orders': 'Order Summary | Retailer Portal | Dropshipzone',
      '/order/new': 'Order - Create | Retailer Portal | Dropshipzone',
      '/order/pay': 'Order - Payment | Retailer Portal | Dropshipzone',
      '/order/confirmed': 'Order - Confirmation | Retailer Portal | Dropshipzone',
      '/order/detail': 'Order Details | Retailer Portal | Dropshipzone',
      '/batch-order/new': 'Batch Order - Upload | Retailer Portal | Dropshipzone',
      '/batch-order/review': 'Batch Order - Review | Retailer Portal | Dropshipzone',
      '/batch-order/pay': 'Batch Order - Payment | Retailer Portal | Dropshipzone',
      '/batch-order/confirmed': 'Batch Order - Confirmation | Retailer Portal | Dropshipzone',

      '/shopify-catalogue': 'Shopify Catalogue | Retailer Portal | Dropshipzone',
      '/my-products': 'My Products | Retailer Portal | Dropshipzone',
      '/product-content-updates': 'Product Content Updates | Retailer Portal | Dropshipzone',
      '/price-updates': 'Price Updates | Retailer Portal | Dropshipzone',
      '/price-updates-log': 'Price Updates Log | Retailer Portal | Dropshipzone',
      '/promotion-updates': 'Promotion Updates | Retailer Portal | Dropshipzone',
      '/promotion-updates-log': 'Promotion Updates Log | Retailer Portal | Dropshipzone',
      '/price-updates-history': 'Price Updates History | Retailer Portal | Dropshipzone',
      '/auto-price-sync': 'Auto Price Sync | Retailer Portal | Dropshipzone',
      '/shipping-calculator': 'Shipping Calculator | Retailer Portal | Dropshipzone',
      '/settings': 'Settings | Retailer Portal | Dropshipzone',
      '/help': 'Help & Support | Retailer Portal | Dropshipzone',
    }
    if (pathname) {
      if (titleMap[pathname]) {
        return titleMap[pathname]
      } else {
        for (const key in titleMap) {
          if (pathname.includes(key)) {
            return titleMap[key]
          }
        }
      }
    }
  }
  const pathname = window.location.pathname;
  const title = getTitle(pathname)
  document.title = title ? title : 'Dropshipzone';


  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  const SuspenseLoading = () => {
    return (
      <>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <ClimbingBoxLoader color={'#3c44b1'} loading={true} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            Please wait
            <span className="font-size-lg d-block text-dark">
              Please wait while we load the page
            </span>
          </div>
        </div>
      </>
    );
  };
  const DSZ_URL = process.env.REACT_APP_DSZ_WEBSITE_URL
  const LEGACY_DSZ_URL = process.env.REACT_APP_DSZ_LEGACY_WEBSITE_URL || process.env.REACT_APP_DSZ_WEBSITE_URL
  return (
    <AnimatePresence>
      <Suspense fallback={<SuspenseLoading />}>
        <Switch>
          {showFeatureItems5 && <Redirect exact from="/" to="/orders" />}
          {showFeatureItems5 && <Redirect exact from="/UnpaidOrders" to="/orders" />}
          {showFeatureItems5 && <Redirect exact from="/OrdersHistory" to="/orders" />}
          {showFeatureItems5 && <Redirect exact from="/Checkout" to="/orders" />}
          {showFeatureItems5 && <Redirect exact from="/OrderDetails" to="/orders" />}
          {showFeatureItems5 && <Redirect exact from="/Payment" to="/orders" />}
          {showFeatureItems5 && <Redirect exact from="/PaymentPaypal" to="/orders" />}
          {showFeatureItems5 && <Redirect exact from="/PaymentConfirmation" to="/orders" />}
          {showFeatureItems5 && <Redirect exact from="/PaymentSuccess" to="/orders" />}
          {showFeatureItems5 && <Redirect exact from="/PaymentCancel" to="/orders" />}
          {showFeatureItems5 && <Redirect exact from="/PaymentFail" to="/orders" />}
          {showFeatureItems5 && <Redirect exact from="/PlaceOrders" to="/orders" />}
          {showFeatureItems5 && <Redirect exact from="/PaymentDatafeed" to="/orders" />}
          {!showFeatureItems5 && (
            <Redirect exact from="/" to="/ShopifyCatalogue" />
          )}
          <Redirect exact from="/ProductInfoUpdate" to="/product-content-updates" />
          <Redirect exact from="/ShopifyCatalogue" to="/shopify-catalogue" />
          <Redirect exact from="/MyProducts" to="/my-products" />
          <Redirect exact from="/ProductPriceUpdate" to="/price-updates" />
          <Redirect exact from="/ProductPriceUpdateLog" to="/price-updates-log" />
          <Redirect exact from="/SpecialPriceUpdate" to="/promotion-updates" />
          <Redirect exact from="/SpecialPriceUpdateLog" to="/promotion-updates-log" />
          <Redirect exact from="/PriceUpdateHistory" to="/price-updates-history" />
          <Redirect exact from="/AutoSync" to="/auto-price-sync" />
          <Redirect exact from="/ShippingCostInquiry" to="/shipping-calculator" />
          <Redirect exact from="/MyAccount" to="/my-account" />
          <Redirect exact from="/InventoryUpdateLog" to="/inventory-updates-log" />
          <Route
            path={[
              '/ShopifyCatalogue',
              '/orders',
              '/order/new',
              '/order/pay',
              '/order/confirmed',
              '/order/detail',
              '/batch-order/new',
              '/batch-order/review',
              '/batch-order/pay',
              '/batch-order/confirmed',
              '/UnpaidOrders',
              '/PlaceOrders',
              '/Checkout',
              '/OrderDetails',
              '/Payment',
              '/PaymentPaypal',
              '/PaymentConfirmation',
              '/PaymentSuccess',
              '/PaymentCancel',
              '/PaymentFail',
              '/PaymentDatafeed',
              '/OrdersHistory',
              '/MyAccount',
              '/ProductPriceUpdate',
              '/ProductPriceUpdateLog',
              '/SpecialPriceUpdate',
              '/SpecialPriceUpdateLog',
              '/PriceUpdateHistory',
              '/ProductInfoUpdate',
              '/ShippingCostInquiry',
              '/AutoSync',
              '/MyProducts',
              '/InventoryUpdateLog',
              '/settings',
              '/Product',
              '/help',

              '/shopify-catalogue',
              '/my-products',
              '/product-content-updates',
              '/price-updates',
              '/price-updates-log',
              '/promotion-updates',
              '/promotion-updates-log',
              '/price-updates-history',
              '/auto-price-sync',
              '/shipping-calculator',
              '/my-account',
              '/inventory-updates-log'

            ]}>
            <LeftSidebar>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route
                    path="/ShopifyCatalogue"
                    component={ProductCatalogue}
                  />
                  <Route
                    path="/shopify-catalogue"
                    component={ProductCatalogue}
                  />

                  <Route path="/orders" component={OrdersList} />
                  <Route path="/order/new" component={OrderNew} />
                  <Route path="/order/pay/:id?" component={OrderPay} />
                  <Route
                    path="/order/confirmed/:id/:transaction_id?"
                    component={OrderConfirmed}
                  />
                  <Route path="/order/detail/:id" component={OrderDetail} />
                  <Route path="/batch-order/new" component={BatchOrderNew} />
                  <Route
                    path="/batch-order/review/:id"
                    component={BatchOrderReview}
                  />
                  <Route path="/batch-order/pay/:id" component={BatchOrderPay} />
                  <Route
                    path="/batch-order/confirmed/:id/:transaction_id?"
                    component={BatchOrderConfirmed}
                  />
                  <Route path="/UnpaidOrders" component={UnpaidOrders} />
                  <Route path="/PlaceOrders" component={PlaceOrders} />
                  <Route path="/Checkout" component={Checkout} />
                  <Route
                    path="/OrderDetails/:id/:p?"
                    component={OrderDetails}
                  />
                  <Route path="/Payment" component={Payment} />
                  <Route path="/PaymentPaypal" component={PaymentPaypal} />
                  <Route
                    path="/PaymentConfirmation"
                    component={PaymentConfirmation}
                  />
                  <Route path="/PaymentDatafeed" component={PaymentDatafeed} />
                  <Route
                    path="/PaymentSuccess/:id"
                    component={PaymentSuccess}
                  />
                  <Route path="/PaymentCancel" component={PaymentCancel} />
                  <Route path="/PaymentFail" component={PaymentFail} />
                  <Route path="/OrdersHistory" component={OrdersHistory} />
                  {!showFeatureItems5 && <Route path="/MyAccount" component={MyAccount} />}
                  {showFeatureItems5 &&<Route path="/MyAccount" component={()=>{window.location.href = `${LEGACY_DSZ_URL}/customer/account/`;return null;}} />}
                  <Route path="/my-account" component={MyAccount} />
                  <Route
                    path="/ProductPriceUpdate"
                    component={ProductPriceUpdate}
                  />
                  <Route
                    path="/price-updates"
                    component={ProductPriceUpdate}
                  />
                  <Route
                    path="/ProductPriceUpdateLog"
                    component={ProductPriceUpdateLog}
                  />
                  <Route
                    path="/price-updates-log"
                    component={ProductPriceUpdateLog}
                  />
                  <Route
                    path="/SpecialPriceUpdate"
                    component={SpecialPriceUpdate}
                  />
                  <Route
                    path="/promotion-updates"
                    component={SpecialPriceUpdate}
                  />
                  <Route
                    path="/SpecialPriceUpdateLog"
                    component={SpecialPriceUpdateLog}
                  />
                  <Route
                    path="/promotion-updates-log"
                    component={SpecialPriceUpdateLog}
                  />
                  <Route
                    path="/PriceUpdateHistory"
                    component={PriceUpdateHistory}
                  />
                  <Route
                    path="/price-updates-history"
                    component={PriceUpdateHistory}
                  />
                  <Route
                    path="/ProductInfoUpdate"
                    component={ProductInfoUpdate}
                  />
                  <Route
                    path="/product-content-updates"
                    component={ProductInfoUpdate}
                  />
                  <Route
                    path="/ShippingCostInquiry"
                    component={ShippingCostInquiry}
                  />
                  <Route
                    path="/shipping-calculator"
                    component={ShippingCostInquiry}
                  />
                  <Route path="/AutoSync" component={AutoSync} />
                  <Route path="/auto-price-sync" component={AutoSync} />
                  <Route path="/MyProducts" component={MyProducts} />
                  <Route path="/my-products" component={MyProducts} />
                  <Route path="/inventory-updates-log" component={InventoryUpdateLog} />
                  <Route
                    path="/InventoryUpdateLog"
                    component={InventoryUpdateLog}
                  />
                  <Route path="/Product/:id" component={ProductDetailPage} />
                  <Route path="/settings" component={Settings} />
                  <Route path="/help" component={Help} />
                </motion.div>
              </Switch>
            </LeftSidebar>
          </Route>

          <Route
            path={[
              '/logout',
              '/auth/callback',
              '/auth',
              '/Recover',
              '/PageError404',
              '/PageError500',
              '/PageError505',
              '/login',
              '/maintenance'
            ]}>
            <MinimalLayout>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/logout" component={Logout} />
                  <Route path="/login" component={Login} />
                  <Route path="/auth/callback" component={AuthCallBack} />
                  <Route
                    path="/auth"
                    render={(props) => {
                      if (props.location.pathname == '/auth') {
                        return <Auth {...props} />;
                      } else {
                        return null;
                      }
                    }}
                  />
                  <Route path="/Recover" component={PageRecover} />

                  <Route path="/PageError404" component={PageError404} />
                  <Route path="/PageError500" component={PageError500} />
                  <Route path="/PageError505" component={PageError505} />
                  <Route path="/maintenance" component={Maintenance} />
                </motion.div>
              </Switch>
            </MinimalLayout>
          </Route>
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
};

export default Routes;
