export const FETCH_MY_ORDERS = 'FETCH_MY_ORDERS';
export const CLEAR_MY_ORDERS = 'CLEAR_MY_ORDERS';
export const RETAIN_MY_ORDERS_STATE = 'RETAIN_MY_ORDERS_STATE';
export const SET_MY_ORDERS_PAGE_SIZE = 'SET_MY_ORDERS_PAGE_SIZE';
export const SET_MY_ORDERS_PAGE_NO = 'SET_MY_ORDERS_PAGE_NO';

export const SET_MY_ORDERS_SEARCH_KEYWORDS = 'SET_MY_ORDERS_SEARCH_KEYWORDS';
export const SET_SEARCH_BINDED_ORDERS = 'SET_SEARCH_BINDED_ORDERS';
export const SET_SEARCH_UNBINDED_ORDERS = 'SET_SEARCH_UNBINDED_ORDERS';

export const SET_BINDED_ORDERS = 'SET_BINDED_ORDERS';
export const SET_UNBINDED_ORDERS = 'SET_UNBINDED_ORDERS';

export const GET_MY_ORDER_DETAIL = 'GET_MY_ORDER_DETAIL';
export const SET_MY_SELECTED_PRODUCT_ID = 'SET_MY_SELECTED_PRODUCT_ID';
export const CLEAR_MY_SELECTED_PRODUCT= 'CLEAR_MY_SELECTED_PRODUCT';

export const CLEAR_MY_SELECTED_UNPAIDORDERS = 'CLEAR_MY_SELECTED_UNPAIDORDERS';
export const SET_UPDATE_PRODUCT_RESPONSE_VIEW = 'SET_UPDATE_PRODUCT_RESPONSE_VIEW';
export const SET_SELECT_MY_ORDERS = 'SET_SELECT_MY_ORDERS';
export const UPDATE_ORDERS_LIST = 'UPDATE_ORDERS_LIST';
export const SET_FREE_SHIPPING_MY_PRODUCT = 'SET_FREE_SHIPPING_MY_PRODUCT';
export const SET_EXTRA_SHIPPING_MY_PRODUCT = 'SET_EXTRA_SHIPPING_MY_PRODUCT';
export const SET_GONE_FROM_SUPPLIER = 'SET_GONE_FROM_SUPPLIER';
export const SET_BELOW_DSZ_PRICE = 'SET_BELOW_DSZ_PRICE';
export const REMOVE_ORDERS_LIST = 'REMOVE_ORDERS_LIST';
export const CLEAR_REMOVE_ORDERS_RESPONSE = 'CLEAR_REMOVE_ORDERS_RESPONSE';
export const RETAIN_MY_ORDERS_DETAILS_STATE = 'RETAIN_MY_ORDERS_DETAILS_STATE';
export const CLEAR_MY_ORDER_DETAILS = 'CLEAR_MY_ORDER_DETAILS';
export const RETAIN_ORDERS_BUNDLE_STATE = 'RETAIN_ORDERS_BUNDLE_STATE';
export const GET_ORDERS_BUNDLE_DETAILS = 'GET_ORDERS_BUNDLE_DETAILS';
export const CLEAR_ORDERS_BUNDLE_DETAILS = 'CLEAR_ORDERS_BUNDLE_DETAILS';
export const SET_BUNDLE_PAYMENT_TYPE = 'SET_BUNDLE_PAYMENT_TYPE';
export const SET_BUNDLE_PAYMENT_ID = 'SET_BUNDLE_PAYMENT_ID';
export const SET_ORDER_STATUS = 'SET_ORDER_STATUS';
export const GET_CREDIT_POINTS = 'GET_CREDIT_POINTS';
export const CLEAR_MY_ORDER_DETAILS_STATE = 'CLEAR_MY_ORDER_DETAILS_STATE';
export const SET_PAY_POPUP = 'SET_PAY_POPUP';
export const GET_CLIENT_TOKEN = 'GET_CLIENT_TOKEN';
export const CLEAR_CLIENT_TOKEN = 'CLEAR_CLIENT_TOKEN';
export const SET_CLIENT_TOKEN = 'SET_CLIENT_TOKEN';
