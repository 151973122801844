export const FETCH_MY_PRODUCTS = 'FETCH_MY_PRODUCTS';
export const CLEAR_MY_PRODUCTS = 'CLEAR_MY_PRODUCTS';
export const RETAIN_MY_PRODUCTS_STATE = 'RETAIN_MY_PRODUCTS_STATE';
export const SET_MY_PRODUCTS_PAGE_SIZE = 'SET_MY_PRODUCTS_PAGE_SIZE';
export const SET_MY_PRODUCTS_PAGE_NO = 'SET_MY_PRODUCTS_PAGE_NO';

export const SET_MY_PRODUCTS_SEARCH_KEYWORDS = 'SET_MY_PRODUCTS_SEARCH_KEYWORDS';
export const SET_SEARCH_BINDED_PRODUCTS = 'SET_SEARCH_BINDED_PRODUCTS';
export const SET_SEARCH_UNBINDED_PRODUCTS = 'SET_SEARCH_UNBINDED_PRODUCTS';

export const SET_BINDED_PRODUCTS = 'SET_BINDED_PRODUCTS';
export const SET_UNBINDED_PRODUCTS = 'SET_UNBINDED_PRODUCTS';

export const GET_MY_PRODUCT_DETAIL = 'GET_MY_PRODUCT_DETAIL';
export const SET_MY_SELECTED_PRODUCT_ID = 'SET_MY_SELECTED_PRODUCT_ID';
export const CLEAR_MY_SELECTED_PRODUCT= 'CLEAR_MY_SELECTED_PRODUCT';

export const CLEAR_MY_SELECTED_PRODUCTS = 'CLEAR_MY_SELECTED_PRODUCTS';
export const SET_UPDATE_PRODUCT_RESPONSE_VIEW = 'SET_UPDATE_PRODUCT_RESPONSE_VIEW';
export const SET_SELECT_MY_PRODUCTS = 'SET_SELECT_MY_PRODUCTS';
export const UPDATE_PRODUCTS_LIST = 'UPDATE_PRODUCTS_LIST';
export const SET_FREE_SHIPPING_MY_PRODUCT = 'SET_FREE_SHIPPING_MY_PRODUCT';
export const SET_EXTRA_SHIPPING_MY_PRODUCT = 'SET_EXTRA_SHIPPING_MY_PRODUCT';
export const SET_GONE_FROM_SUPPLIER = 'SET_GONE_FROM_SUPPLIER';
export const SET_BELOW_DSZ_PRICE = 'SET_BELOW_DSZ_PRICE';
export const REMOVE_PRODUCTS_LIST = 'REMOVE_PRODUCTS_LIST';
export const CLEAR_REMOVE_PRODUCTS_RESPONSE = 'CLEAR_REMOVE_PRODUCTS_RESPONSE';