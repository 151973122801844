import service from '../../../services/request';
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart
} from '../../../reducers/asyncReducer';
import {
  FETCH_SPECIAL_PRICE_UPDATES,
  SPECIAL_PRICE_SET_PAGE_NO,
  SPECIAL_PRICE_SET_PAGE_SIZE,
  SET_SPECIAL_PRICE_UPDATE_PROGRESS_VIEW,
  SET_SPECIAL_PRICE_UPDATE_LOADING_VIEW,
  FETCH_SPECIAL_PRICE_UPDATE_PROGRESS,
  CLEAR_SPECIAL_PRICE_UPDATES
} from './specialPriceConstants';
import React from 'react';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import SpecialPriceUpdateProgress from './specialPriceUpdateProgress';
import { saveOnlyNew } from '../../../utils/util';

const specialPriceUpdateProgressToastId = 'special-price-update-progress';
const specialPriceUpdateProgressFinishId =
  'special-price-update-progress-finish';

export function fetchSpecialPriceUpdates(
  pageNo,
  pageSize,
  searchKeywords,
  fromTime,
  toTime,
  updateType
) {
  let pageParams = {
    page_no: pageNo,
    limit: pageSize,
    keywords: searchKeywords,
    from_time: fromTime ? fromTime.format('YYYY-MM-DD') : null,
    to_time: toTime ? toTime.format('YYYY-MM-DD') : null,
    update_type: updateType
  };
  return async (dispatch) => {
    dispatch(asyncActionStart());
    dispatch(clearSpecialPriceUpdates());
    service
      .get('api/products/productupdate/getspecialpriceupdate', {
        params: pageParams
      })
      .then((response) => {
        dispatch({
          type: FETCH_SPECIAL_PRICE_UPDATES,
          payload: {
            productsInfoUpdate:
              response.data.data.data !== undefined
                ? response.data.data.data
                : [],
            totalCount: response.data.data.total_count,
            totalPages: response.data.data.total_pages,
            currentPage: response.data.data.cur_page
          }
        });
        dispatch(asyncActionFinish());
      })
      .catch((error) => dispatch(asyncActionError(error)));
  };
}

export function clearSpecialPriceUpdates(){
  return {
    type: CLEAR_SPECIAL_PRICE_UPDATES
  };
}

export function updateSpecialPrice(
  skus,
  pageNo,
  pageSize,
  searchKeywords,
  fromTime,
  toTime,
  updateType
) {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    service
      .post(`api/products/productupdate/updatespecialprice`, { skus })
      .then(() => {
        dispatch(
          fetchSpecialPriceUpdates(
            pageNo,
            pageSize,
            searchKeywords,
            fromTime,
            toTime,
            updateType
          )
        ).then(() => {
          dispatch(asyncActionFinish());
          dispatch(fetchSpecialPriceUpdateProgress());
          Swal.fire({
            icon: 'success',
            title: 'Submission Received',
            text:
              'Track your progress through the progress bar in the upper right',
            onDestroy: () => dispatch(fetchSpecialPriceUpdateProgress(true))
          });
        });
      })
      .catch((error) => dispatch(asyncActionError(error)));
  };
}

export function setCurrentPage(value) {
  return function (dispatch) {
    dispatch({ type: SPECIAL_PRICE_SET_PAGE_NO, payload: value });
  };
}

export function setPageSize(value) {
  return function (dispatch) {
    dispatch({ type: SPECIAL_PRICE_SET_PAGE_SIZE, payload: value });
  };
}

export function set() {}

export function setSpecialPriceUpdateLoadingView(value) {
  return {
    type: SET_SPECIAL_PRICE_UPDATE_LOADING_VIEW,
    payload: value
  };
}

export function setSpecialPriceUpdateProgressView(value) {
  return function (dispatch) {
    dispatch({
      type: SET_SPECIAL_PRICE_UPDATE_PROGRESS_VIEW,
      payload: { value }
    });

    if (value) {
      toast(<SpecialPriceUpdateProgress />, {
        autoClose: false,
        closeOnClick: false,
        toastId: specialPriceUpdateProgressToastId,
        onClose: () => {
          dispatch({
            type: SET_SPECIAL_PRICE_UPDATE_PROGRESS_VIEW,
            payload: { value: false }
          });
          dispatch({
            type: SET_SPECIAL_PRICE_UPDATE_LOADING_VIEW,
            payload: { value: true }
          });
        }
      });
    } else {
      dismissSpecialPriceUpdateProgress();
    }
  };
}

export function fetchSpecialPriceUpdateProgress(isUpdate = false) {
  const url = 'api/products/productupdate/getspecialpriceupdateprogress';
  const params = {};
  return async function (dispatch, getState) {
    const {
      isPriceUpdateLoadingShow,
      isPriceUpdateProgressShow,
      isPriceUpdateRunning
    } = getState().productPrice;
    if (!isUpdate) {
      if (isPriceUpdateLoadingShow || isPriceUpdateProgressShow) {
        return;
      }
    }

    if(!isUpdate)
    dispatch(asyncActionStart());

    service
      .get(url, { params })
      .then(function (response) {
        if (response.data.status === true) {
          const data = response.data.data || {};
          const isFinished =
            (data && data.all > 0 && data.all <= data.success + data.fail) ||
            (data && data.all === 0);
          const inProgress = data && data.all > 0 && !isFinished;

          dispatch({
            type: FETCH_SPECIAL_PRICE_UPDATE_PROGRESS,
            payload: {
              priceUpdateProgress: data,
              isPriceUpdateRunning: inProgress
            }
          });

          if (inProgress && !isUpdate) {
            dispatch(setSpecialPriceUpdateProgressView(true));
            dispatch(setSpecialPriceUpdateLoadingView(false));
          }
          if (!inProgress || isFinished) {
            dispatch(setSpecialPriceUpdateProgressView(false));
            dispatch(setSpecialPriceUpdateLoadingView(false));
            if (data.all > 0 && isFinished) {
              const finishStr = new Date().toISOString().split('T')[0] + '-' + data.all;
              const isNew = saveOnlyNew(
                specialPriceUpdateProgressFinishId,
                finishStr
              );
              if (isNew) {
                toast.success('Special price update finished!', {
                  toastId: specialPriceUpdateProgressFinishId,
                  autoClose: 8000
                });
              }
            }
          }
          if(!isUpdate)
          dispatch(asyncActionFinish());
        }
      })
      .catch(function (error) {
        console.error(error.data);
        dispatch(asyncActionError(error));
      });
  };
}

export function dismissSpecialPriceUpdateProgress() {
  toast.dismiss(specialPriceUpdateProgressToastId);
}
