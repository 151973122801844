import {
  FETCH_MY_PRODUCTS,
  CLEAR_MY_PRODUCTS,
  SET_MY_PRODUCTS_PAGE_SIZE,
  SET_MY_PRODUCTS_PAGE_NO,
  SET_MY_PRODUCTS_SEARCH_KEYWORDS,
  SET_SELECT_MY_PRODUCTS,
  SET_SEARCH_BINDED_PRODUCTS,
  SET_SEARCH_UNBINDED_PRODUCTS,
  SET_BINDED_PRODUCTS,
  SET_UNBINDED_PRODUCTS,
  GET_MY_PRODUCT_DETAIL,
  SET_MY_SELECTED_PRODUCT_ID,
  CLEAR_MY_SELECTED_PRODUCT,
  CLEAR_MY_SELECTED_PRODUCTS,
  SET_UPDATE_PRODUCT_RESPONSE_VIEW,
  RETAIN_MY_PRODUCTS_STATE,
  UPDATE_PRODUCTS_LIST,
  SET_FREE_SHIPPING_MY_PRODUCT,
  SET_EXTRA_SHIPPING_MY_PRODUCT,
  SET_GONE_FROM_SUPPLIER,
  SET_BELOW_DSZ_PRICE,
  REMOVE_PRODUCTS_LIST,
  CLEAR_REMOVE_PRODUCTS_RESPONSE
} from './myProductConstants';

const initialState = {
  myProductsList: [],
  selectedProducts: loadFromLocalStorage(),
  isProductToUpdateResponse: false,
  productToUpdateResponse: [],
  productToRemoveResponse: '',
  retainState: false,
  totalCount: 0,
  totalPages: 0,
  pageSize: 50,
  currentPage: 1,
  searchKeywords: '',
  bindedProducts: false,
  unbindedProducts: false,
  freeShipping: false,
  extraShipping: false,
  goneFormSupplier: false,
  belowDszPrice: false,
  sortParams: [],
  selectedProductID: null,
  selectedProduct: null,
  isProductSelectAll: false,
  btnAll: false,
  btnShowProductImportList: true,
  btnProceedProductImportList: true,
  isSelected: true,
  bindingMode: '',
  selectedExportProduct: []
};

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('myselpro');
    //console.log("serializedState",serializedState.length)
    if (serializedState === null || serializedState.length === 0) return [];
    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
    return [];
  }
}

export default function myProductReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FETCH_MY_PRODUCTS:
      return {
        ...state,
        myProductsList: [...state.myProductsList, ...payload.myProductsList],
        totalCount: payload.totalCount,
        totalPages: payload.totalPages,
        currentPage: payload.currentPage,
        btnShowProductImportList: payload.showProductImportbButton,
        isSelected: payload.showProductImportbButton
      };

    case SET_SELECT_MY_PRODUCTS:
      return {
        ...state,

        myProductsList: setProductSelection(
          state.myProductsList,
          payload.id,
          payload.isSelectAllMode,
          payload.isSelectAll
        ),
        selectedProducts: setProductSelectionList(
          state.myProductsList,
          state.selectedProducts,
          payload.id,
          payload.isSelectAllMode,
          payload.isSelectAll
        ),
        isProductSelectAll: payload.isSelectAll,
        selectedExportProduct: selectedProducts(state.myProductsList),
        isSelected: isProductImport(state.myProductsList)
      };
    case UPDATE_PRODUCTS_LIST:
      return {
        ...state,
        productToUpdateResponse: [
          ...state.productToUpdateResponse,
          ...payload.productToUpdateResponse
        ],
        retainState: false,
        isProductToUpdateResponse: true,
        bindingMode: payload.bindingMode
      };
    case REMOVE_PRODUCTS_LIST:
      return {
        ...state,
        productToRemoveResponse: payload.productToUpdateResponse,
        retainState: false,
        selectedProducts: []
      };
    case CLEAR_REMOVE_PRODUCTS_RESPONSE:
      return {
        ...state,
        productToRemoveResponse: '',
        retainState: true
      };
    case SET_UPDATE_PRODUCT_RESPONSE_VIEW:
      return {
        ...state,
        retainState: true,
        isProductToUpdateResponse: payload,
        productToUpdateResponse: [],
        bindingMode: ''
      };
    case SET_FREE_SHIPPING_MY_PRODUCT:
      return {
        ...state,
        retainState: false,
        freeShipping: payload,
        isProductSelectAll: false,
        currentPage: 1
      };
    case SET_EXTRA_SHIPPING_MY_PRODUCT:
      return {
        ...state,
        retainState: false,
        extraShipping: payload,
        isProductSelectAll: false,
        currentPage: 1
      };
    case SET_GONE_FROM_SUPPLIER:
      return {
        ...state,
        retainState: false,
        goneFormSupplier: payload,
        isProductSelectAll: false,
        currentPage: 1
      };
    case SET_BELOW_DSZ_PRICE:
      return {
        ...state,
        retainState: false,
        belowDszPrice: payload,
        isProductSelectAll: false,
        currentPage: 1
      };
    case CLEAR_MY_PRODUCTS:
      return {
        ...state,
        myProductsList: []
        // currentPage: 1
      };
    case CLEAR_MY_SELECTED_PRODUCT:
      return {
        ...state,
        retainState: false,
        selectedProductID: null,
        selectedProduct: null
      };
    case CLEAR_MY_SELECTED_PRODUCTS:
      return {
        ...state,
        selectedProducts: loadFromLocalStorage()
      };
    case GET_MY_PRODUCT_DETAIL:
      return {
        ...state,
        retainState: true,
        selectedProduct: payload
      };

    case SET_MY_SELECTED_PRODUCT_ID:
      return {
        ...state,
        retainState: false,
        selectedProductID: payload
      };
    case SET_MY_PRODUCTS_PAGE_SIZE:
      return {
        ...state,
        retainState: false,
        pageSize: payload
      };
    case SET_MY_PRODUCTS_PAGE_NO:
      return {
        ...state,
        retainState: false,
        currentPage: payload,
        isProductSelectAll: false
      };
    case SET_MY_PRODUCTS_SEARCH_KEYWORDS:
      return {
        ...state,
        retainState: false,
        searchKeywords: payload,
        currentPage: 1
      };
    case SET_SEARCH_BINDED_PRODUCTS:
      return {
        ...state,
        retainState: false,
        bindedProducts: payload,
        isProductSelectAll: false,
        currentPage: 1
      };
    case SET_SEARCH_UNBINDED_PRODUCTS:
      return {
        ...state,
        retainState: false,
        unbindedProducts: payload,
        isProductSelectAll: false,
        currentPage: 1
      };

    case RETAIN_MY_PRODUCTS_STATE:
      return {
        ...state,
        retainState: true
      };

    default:
      return state;
  }
}

//Enable button
const isProductImport = (productList) => {
  //console.log("productList",productList)
  let index = productList.findIndex((p) => p.isSelected === true);
  //console.log("Selected count", index);
  if (index === -1) return true;
  else return false;
};

//Set main product list
const setProductSelection = (
  productList,
  productID,
  isSelectAllMode,
  isSelectAll
) => {
  const newList = [];

  if (isSelectAllMode) {
    productList.forEach((product, index) => {
      product.isSelected = isSelectAll;
      newList.push(product);
    });
  } else {
    productList.forEach((product, index) => {
      if (product.product_id === productID) {
        product.isSelected = !product.isSelected;
      }
      newList.push(product);
    });
  }
  return newList;
};

//Set product import list
const setProductSelectionList = (
  productList,
  selectedProductsList,
  productID,
  isSelectAllMode,
  isSelectAll
) => {
  /* console.log("isSelectAllMode",isSelectAllMode);
  console.log("isSelectAll",isSelectAll);
  console.log("productID",productID); */
  const newList = [];
  //add existing value
  selectedProductsList.forEach((product, index) => {
    newList.push(product);
  });
  /*  console.log('newList before', newList);
  console.log('productList', productList);
  console.log('productID', productID);
  console.log('isSelectAllMode', isSelectAllMode); */

  //mode:add/remove all
  if (isSelectAllMode) {
    productList.forEach((product, index) => {
      var itemIndex = selectedProductsList.findIndex(
        (p) => p === product.product_id
      );
      if (itemIndex === -1 && isSelectAll) {
        newList.push(product.product_id);
      } else if (itemIndex > -1 && !isSelectAll) {
        // console.log('splice', itemIndex);
        selectedProductsList.splice(itemIndex, 1);
        var itemIndexTemp = newList.findIndex((p) => p === product.product_id);
        if (itemIndex > -1 && !isSelectAll) {
          newList.splice(itemIndexTemp, 1);
        }
      }
    });
  } else {
    //Single product update
    productList.forEach((product, index) => {
      if (product.product_id === productID) {
        //console.log(product.product_id, productID)
        var itemIndex = selectedProductsList.findIndex(
          (p) => p === product.product_id
        );
        //console.log("itemIndex 1",itemIndex)
        if (itemIndex === -1 && product.isSelected) {
          // console.log('itemIndex 2', itemIndex);
          newList.push(product.product_id);
        } else if (itemIndex > -1 && !product.isSelected) {
          // console.log('itemIndex 3', itemIndex);
          newList.splice(itemIndex, 1);
        }
      }
    });
  }

  //console.log('selectedProductsList', newList);
  saveToLocalStorage(newList);
  return newList;
};

function saveToLocalStorage(productList) {
  try {
    // console.log('saveToLocalStorage', productList);
    const serializedState = JSON.stringify(productList);
    localStorage.setItem('myselpro', serializedState);
  } catch (e) {
    console.log(e);
  }
}
const sortProductByKey = (list, key, direction) => {
  if (direction === 'asc') return list.slice().sort(sortByKey(key));
  else if (direction === 'desc') return list.slice().sort(sortDescByKey(key));
  else return list;
};

const sortByKey = (key) => {
  return (a, b) => (a[key] > b[key] ? 1 : -1);
};
const sortDescByKey = (key) => {
  return (a, b) => (a[key] > b[key] ? -1 : 1);
};

const selectedProducts = (selectProductList) => {
  const selectedList = selectProductList.filter((order) => order.isSelected);

  const mArray = [];
  if (selectedList && selectedList.length !== 0)
    selectedList.forEach((obj) => {
      let container = {};

      container.sku = obj.sku;
      container.title = obj.title;
      container.bind = obj.bind ? 'Bound' : 'Unbound';
      container.cost = obj.cost;
      container.rrp = obj.rrp;
      container.freeshipping = obj.freeshipping ? 'Y' : 'N';
      container.discontinued = obj.discontinued;

      mArray.push(container)

    });

  // console.log("Array", mArray);
  return mArray;

};
