import {
  CLEAR_PRODUCTS_INFO,
  FETCH_PRODUCTS_INFO_UPDATE,
  FETCH_STORE_PRODUCT_COUNT_UPDATE,
  FETCH_UPDATE_REQUIRED_COUNT_UPDATE,
  PRODUCTS_INFO_RETAIN_STATE,
  PRODUCTS_INFO_SET_PAGE_NO,
  PRODUCTS_INFO_SET_PAGE_SIZE,
  PRODUCTS_INFO_SET_SORT_PARAMS,
  PRODUCTS_INFO_SET_ALL_RECORDS,
  PRODUCTS_INFO_SET_ALL_RECORDS_LIST,
  CLEAR_SELECTED_PRODUCTS_INFO
} from './productInfoConstants';

const initialState = {
  storedProductCount: 0,
  updateRequiredCount: 0,
  productsInfoUpdateList: [],
  selectedProductsInfoList: [],
  retainState: false,
  totalCount: 0,
  totalPages: 0,
  currentPage: 1,
  pageSize: 10,
  sortParams: [],
  isSelectAll: false
};

export default function productInfoReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FETCH_STORE_PRODUCT_COUNT_UPDATE:
      return {
        ...state,
        storedProductCount: payload
      };

    case FETCH_UPDATE_REQUIRED_COUNT_UPDATE:
      return {
        ...state,
        updateRequiredCount: payload
      };

    case FETCH_PRODUCTS_INFO_UPDATE:
      return {
        ...state,
        productsInfoUpdateList: payload.productsInfoUpdate,
        totalCount: payload.totalCount,
        totalPages: payload.totalPages,
        currentPage: payload.currentPage
      };

    case CLEAR_PRODUCTS_INFO:
      return {
        ...state,
        productsInfoUpdate: [],
        currentPage: 1
      };

    case PRODUCTS_INFO_SET_PAGE_SIZE:
      return {
        ...state,
        retainState: false,
        pageSize: payload
      };

    case PRODUCTS_INFO_SET_PAGE_NO:
      return {
        ...state,
        retainState: false,
        currentPage: payload
      };

    case PRODUCTS_INFO_SET_SORT_PARAMS:
      return {
        ...state,
        sortParams: payload
      };

    case PRODUCTS_INFO_RETAIN_STATE:
      return {
        ...state,
        retainState: true
      };
    case PRODUCTS_INFO_SET_ALL_RECORDS:
      return {
        ...state,
        isSelectAll: payload
      };
    case PRODUCTS_INFO_SET_ALL_RECORDS_LIST:
      return {
        ...state,
        selectedProductsInfoList: payload
      };
    case CLEAR_SELECTED_PRODUCTS_INFO:
      return {
        ...state,
        selectedProductsInfoList: []
      };
    default:
      return state;
  }
}
