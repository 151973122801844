import {
  SIGN_IN_USER,
  SIGN_OUT_USER,
  LOGIN_FAIL,
  SET_MESSAGE,
  CLEAR_MESSAGE
} from './authConstants';

import { APP_LOADED } from '../../reducers/asyncReducer';
import service from '../../services/request';

import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from '../../reducers/asyncReducer';

import { fetchSettings } from '../settings/settingsActions';
import { clearStorage } from '../../utils/util';

import { getUserProfile } from '../myAccount/profileActions';

import { setFeatureFlag3, setFeatureFlag5 } from '../../reducers/ThemeOptions';
import { toast } from 'react-toastify';

export function signInUser(user, email, shopname, token, dszEmail, authCode) {
  return {
    type: SIGN_IN_USER,
    payload: { user, email, shopname, token, dszEmail, authCode }
  };
}

export const auth = (shopName, redirectUrl) => {
  let pageParams = {};
  pageParams['shop'] = shopName;
  if (redirectUrl) {
    pageParams['redirectUrl'] = redirectUrl;
  }

  // console.log('auth call', pageParams);
  return async function (dispatch) {
    dispatch(asyncActionStart());
    service.get('api/auth', { params: pageParams }).then(
      (response) => {
        console.log('response auth', response);
        if (response.data) {
          window.location.href = response.data;
        }
        dispatch(asyncActionFinish());
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch(asyncActionFinish());
      }
    );
  };
};

export const authCallback = (pageParams) => () => {
  /*  let pageParams = {};
  pageParams['code'] = shopCode;
  pageParams['hmac'] = hmac;
  pageParams['state'] = state;
  pageParams['shop'] = shopName;
  pageParams['timestamp'] = timestamp; */
  //console.log('callback call', pageParams);
  /* return async function (dispatch) {
    dispatch(asyncActionStart());
    service.get('api/auth/callback', { params: pageParams }).then(
      (response) => {
        console.log('response auth', response);
        if (response) {
          if (response.data.binding) {
           // window.location.href = response.data.url + '/MyAccount';
          } else {
            // window.open(response.data.url)
            //window.location.href = response.data.url + '/Login';
          }
          sessionStorage.setItem('user_id', response.data.user_id);
          sessionStorage.setItem('binding', response.data.binding);
          sessionStorage.setItem('email', response.data.email);
          sessionStorage.setItem('dsz_email', response.data.dsz_email);
          sessionStorage.setItem('shop_name', response.data.shop_name);

          localStorage.setItem('dsz_email', response.data.email);
          localStorage.setItem('binding', response.data.binding);
          localStorage.setItem('user_id', response.data.user_id);
          localStorage.setItem('email', response.data.email);
          localStorage.setItem('shop_name', response.data.shop_name);
        }
        dispatch(asyncActionFinish());
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

          dispatch(asyncActionFinish());;
      }
    );
  }; */
};

export const login = (username, password, ssoEnabled, isVerifyRecaptcha, recaptchaResponse, theReturnUrl) => {
  let params = {};
  params['email'] = username;
  params['password'] = password;
  if (ssoEnabled == true) {
    params['ssoEnabled'] = ssoEnabled
  }
  if (isVerifyRecaptcha) {
    params['isVerifyRecaptcha'] = isVerifyRecaptcha
    params['recaptchaResponse'] = recaptchaResponse
  }
  if(theReturnUrl){
    params['redirect_url'] = theReturnUrl;
  }

  return async function (dispatch) {
    //await delay(1000);
    await dispatch(asyncActionStart());
    await dispatch(clearMessage());
    return service.post('api/verify', params).then(
      async function (response) {
        // console.log('data');
        // console.log("response -> login",response);
        //Clear data before signin
        clearStorage();
        sessionStorage.clear();

        if (response.data.status === true) {
          // sessionStorage.setItem('dsz_email', response.data.email);
          // sessionStorage.setItem('binding', response.data.binding);
          // sessionStorage.setItem('user_id', response.data.user_id);
          // sessionStorage.setItem('email', response.data.email);
          // sessionStorage.setItem('shop_name', response.data.shop_name);

          localStorage.setItem('dsz_email', response.data.dsz_email);
          localStorage.setItem('binding', response.data.binding);
          localStorage.setItem('user_id', response.data.user_id);
          localStorage.setItem('email', response.data.email);
          localStorage.setItem('shop_name', response.data.shop_name);
          localStorage.setItem('token', response.data.token);
          if (response.data.customer_id) {
            localStorage.setItem('customer_id', response.data.customer_id);
          }

          //Clear local values
          /*   localStorage.setItem('selectedProduct', '');
        localStorage.setItem('myselpro', []);
 */
          //const RESET = 'RESET';
          //await dispatch({ type: 'RESET' });
          await dispatch(
            signInUser(
              response.data,
              response.data.email,
              response.data.shop_name,
              response.data.token,
              response.data.dsz_email,
              response.data.auth_code
            )
          );
          await dispatch({ type: APP_LOADED });

          //Check Feature Flag for Auto Sync
          if (process.env.REACT_APP_FEATURE_AUTO_SYNC === 'true') {
            await dispatch(setFeatureFlag3('Auth', true));
          }
          // if (isAutoSyncTestUser(response.data.user_id)) {
          //   dispatch(setFeatureFlag3('AutoSyncTest', true));
          // }

          if (window.location.host.indexOf('dropshipzone.com.au') >= 0) {
            if (isMvpTestUser(response.data.dsz_email)) {
              dispatch(setFeatureFlag5(true));
            } else {
              dispatch(setFeatureFlag5(false));
            }
          }

          await dispatch(getUserProfile());
          await dispatch(asyncActionFinish());
          return {status: true, data: response.data}

          // setTimeout(function () {}, 5000);
          //load setting
          //dispatch(fetchSettings());
        } else if (response.data.status === false) {
          await dispatch({
            type: LOGIN_FAIL
          });
          if (response.data.data.indexOf('no customer') < 0 && response.data.data.indexOf('invalid user or password') < 0) {
            await dispatch({
              type: SET_MESSAGE,
              payload: response.data.data
            });
          }
          await dispatch(asyncActionFinish());
          return {status: false, data: response.data.data}
        } else {
          await dispatch(signOutUser());
          await dispatch({ type: APP_LOADED });
          await dispatch(asyncActionFinish());
          return {status: false, data: response.data.data}
        }
      },
      async (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        await dispatch({
          type: LOGIN_FAIL
        });

        await dispatch({
          type: SET_MESSAGE,
          payload: message
        });

        console.error(error.data);
        await dispatch(asyncActionError(error));
        return {status: false, data: message}
      }
    );
  };
};

/* export function signOutUser() {
  clearStorage();
  sessionStorage.clear();
  return {
    type: SIGN_OUT_USER
  };
} */

export const signOutUser = () => {
  return async function (dispatch) {
    //await delay(1000);
    await dispatch(asyncActionStart());
    return service.post('api/logout').then(
      async function (response) {
        //console.log("signout response",response);
        clearStorage();
        sessionStorage.clear();
        await dispatch({ type: SIGN_OUT_USER });
        await dispatch(asyncActionFinish());
        return true
      },
      async (error) => {
        toast.error(error.message);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.error(error.data);
        await dispatch(asyncActionError(error));
        return false
      }
    );
  };
};

export const setMessage = (message) => ({
  type: SET_MESSAGE,
  payload: message
});

export const clearMessage = () => ({
  type: CLEAR_MESSAGE
});

export function verifyAuth() {
  return function (dispatch) {
    if (localStorage.getItem('user_id')) {
      // console.log('shop_name', sessionStorage.getItem('shop_name'));
      const user = {
        dszemail: localStorage.getItem('dsz_email'),
        email: localStorage.getItem('email'),
        uid: localStorage.getItem('user_id'),
        shopname: localStorage.getItem('shop_name'),
        binding: localStorage.getItem('binding')
      };

      // console.log('dispatch(signInUser(user))', user);

      dispatch(
        signInUser(
          user,
          localStorage.getItem('email'),
          localStorage.getItem('shop_name'),
          localStorage.getItem('token'),
          localStorage.getItem('dsz_email'),
        )
      );
      dispatch({ type: APP_LOADED });
      //Check Feature Flag for Auto Sync
      if (process.env.REACT_APP_FEATURE_AUTO_SYNC === 'true') {
        dispatch(setFeatureFlag3('Auth', true));
      }
      // if (isAutoSyncTestUser(user.uid)) {
      //   dispatch(setFeatureFlag3('AutoSyncTest', true));
      // }
      if (window.location.host.indexOf('dropshipzone.com.au') >= 0) {
        if (isMvpTestUser(user.dszemail)) {
          dispatch(setFeatureFlag5(true));
        } else {
          dispatch(setFeatureFlag5(false));
        }
      }

      dispatch(getUserProfile());
    } else {
      //console.log('verifyAuth', sessionStorage.getItem('user_id'));
      dispatch(signOutUser());
    }
  };
}

function isAutoSyncTestUser(userId) {
  let result = false
  const testUserListStr = process.env.REACT_APP_AUTO_SYNC_TEST_USERS || ''
  if (userId && testUserListStr && testUserListStr.length > 0) {
    const theUserId = parseInt(userId)
    const testUserIds = testUserListStr.trim().split(',').map((id) => parseInt(id.trim()))
    result = testUserIds.includes(theUserId)
  }
  return result
}


// export const verifyRecaptcha = (response) => {
//   let data = {
//     response: response
//   }
//   return async function () {
//     return service.post('api/verify-recaptcha', data).then(
//       async function (response) {
//         if (response.data && response.data.success === true) {
//           return true
//         } else if (response.data && response.data.success === false) {
//           return false
//         }
//       },
//       async (error) => {
//         return false
//       }
//     );
//   };
// };
export function isMvpTestUser(dszEmail) {
  return true
  // let result = false
  // const mvpUserEmailList = ['gary@inkstation.com.au', 'myhappygiftau@gmail.com', 'Info@bugoutaustralia.com', 'info@everblossom.com.au', 'vlad_klyur@yahoo.com',
  //   'info@nestz.com.au', 'Kelanneent@hotmail.com', 'aplusbuyau@gmail.com', 'vincentfang@crazysales.com.au', 'contact@techsale.com.au', 'liam@arcadianliving.com.au',
  //   'yessales@klika.com.au', 'sales@ozstylz.com.au', 'support@homemygarden.com.au', 'support@affordable-beds.com.au', 'ray.ryan@rayzatech.com.au', 'info@decortrends.com.au',
  //   'hello@friendlymascots.com', 'shopfrenzypro@gmail.com', 'theurbanemporium.com.au@gmail.com', 'contact@thecatden.com.au', 'admin@sokoni.com.au', 'cathlene@windsormail.com.au',
  //   'petsgearau@gmail.com', 'info@ozhammocks.com.au', 'hello@conchoutdoors.com.au', 'dylanscott2487@gmail.com', 'info@factorybuys.com.au', 'rivercityaus@gmail.com',
  //   'outdoordirectaus@gmail.com','shopperbeats@gmail.com', 'coclearance@gmail.com', 'support@elegantshop.com.au', 'info@dropshipzone.com.au', 'kennectdigital@gmail.com', 'support@barstoolcentre.com.au',
  //   'cloe.mckenzie@dropshipzone.com.au',
  //   'jenny_leon@163.com', 'darren.teo@newaim.com.au']
  // if (dszEmail) {
  //   const lowerCaseMvpUserEmailList = mvpUserEmailList.map((email) => email.toLowerCase())
  //   if(lowerCaseMvpUserEmailList.includes(dszEmail.toLowerCase())){
  //     result = true
  //   }
  // }
  // return result
}

/* name: 'MyAccount',
created: function () {
  this.$eventHub.$emit('hideSearchBar', false)
  if (sessionStorage.getItem('user_id') === null) {
    this.$router.push({path: '/'})
    return
  }

  let url = this.HOME + '/users/getuserinfo'
  let me = this
  let pageParams = {}

  if (sessionStorage.getItem('email') !== null) {
    pageParams.email = sessionStorage.getItem('email')
  }
  me.$store.dispatch('showloadingMask')
  me.$axios.get(url, {params: pageParams}).then(function (response) {
    // console.log(response)
    if (response.data.status === true) {
      me.$store.dispatch('hideloadingMask')
      me.$store.dispatch('getuserInfo', response.data.data)

      me.update_stock = response.data.data.update_stock
      me.update_product = response.data.data.update_product
      me.update_price = response.data.data.update_price
      me.bind = response.data.data.binding
      me.dsz_email = sessionStorage.getItem('dsz_email')
    }
  }).catch(function (error) {
    me.$store.dispatch('hideloadingMask')
    me.$message.error('Failed to obtain user information. Please try again later.')
    console.error(error.data)
    // me.$router.push({path: '/'})
  }) */
