import { LISTEN_TO_CURRENT_USER_PROFILE,
  BINDING_ACCOUNT, LISTEN_FOR_DSZ_BELOW_PRICE, CLEAR_DSZ_PRICE_NOTIFICATION } from './profileConstants';


const initialState = {
    currentUserProfile: null,
    userBinding: true,
    bindStatus: null,
    bindResponseData: null,
    DszNotificationFlag: false,
    isAutoSyncAccount: false,
    isShopifyUser: false
  };
  
  export default function profileReducer(
    state = initialState,
    { type, payload }
  ) {
    switch (type) {
      case LISTEN_TO_CURRENT_USER_PROFILE:
        return {
          ...state,
          currentUserProfile: payload.userProfile,
          userBinding: payload.userBinding,
          isAutoSyncAccount: payload.isAutoSync,
          isShopifyUser: payload.isShopifyUser
        };
        case BINDING_ACCOUNT:
          return {
            ...state,
            userBinding: payload.isBinding,
            bindStatus: payload.bindStatus,
            bindResponseData: payload.bindResponseData
          };
          case LISTEN_FOR_DSZ_BELOW_PRICE:
          return {
            ...state,
            DszNotificationFlag: payload.DszNotificationFlag
          };
          case CLEAR_DSZ_PRICE_NOTIFICATION:
          return {
            ...state,
            DszNotificationFlag: false
          };
      default: {
        return state;
      }
    }
  }
  