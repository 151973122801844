import {
  SET_PAGELOADING,
  GET_ORDERS_BUNDLE_DETAILS,
  CLEAR_DETAILS,
  SET_ALL_ORDER_IDS,
  SET_STEP_CONFIG
} from './batchOrderNewConstants';

const initialState = {
  pageLoading: false,

  allOrderIDS: [],
  orderBundleDetails: null,
  stepConfig: []
};

export default function batchOrderNewReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_PAGELOADING:
      return {
        ...state,
        pageLoading: payload.isLoading
      };
    case SET_ALL_ORDER_IDS:
      return {
        ...state,
        allOrderIDS: payload.allOrderIDS
      };
    case GET_ORDERS_BUNDLE_DETAILS:
      return {
        ...state,
        orderBundleDetails: payload.orderBundleDetails
      };
    case SET_STEP_CONFIG:
      return {
        ...state,
        stepConfig: payload.config
      }
    case CLEAR_DETAILS:
      return {
        ...state,
        orderBundleDetails: null,
        allOrderIDS: []
      };

    default: {
      return state;
    }
  }
}
