export const FETCH_PRODUCT_CATEGORIES = 'FETCH_PRODUCT_CATEGORIES';
export const FETCH_PRODUCTS_NEW_ARRIVAL = 'FETCH_PRODUCTS_NEW_ARRIVAL';
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const SET_CATEGORY_ID = 'SET_CATEGORY_ID';
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';
export const RETAIN_STATE = 'RETAIN_STATE';
export const RETAIN_STATE_PRODUCT_DETAIL = 'RETAIN_STATE_PRODUCT_DETAIL';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
export const SET_PAGE_NO = 'SET_PAGE_NO';
export const SET_SEARCH_KEYWORDS = 'SET_SEARCH_KEYWORDS';
export const SET_FREE_SHIPPING = 'SET_FREE_SHIPPING';
export const SET_EXTRA_SHIPPING = 'SET_EXTRA_KEYWORDS';
export const SORT_BY_FREESHIPPING = 'SORT_BY_FREESHIPPING';
export const GET_PRODUCT_DETAIL = 'GET_PRODUCT_DETAIL';
export const SET_SELECTED_PRODUCT_ID = 'SET_SELECTED_PRODUCT_ID';
export const CLEAR_SELECTED_PRODUCT= 'CLEAR_SELECTED_PRODUCT';
export const SET_IMPORT_PRODUCT = 'SET_IMPORT_PRODUCT';
export const IMPORT_PRODUCTS_LIST = 'IMPORT_PRODUCTS_LIST';
export const CLEAR_SELECTED_PRODUCTS = 'CLEAR_SELECTED_PRODUCTS';
export const SET_IMPORT_PRODUCT_RESPONSE_VIEW = 'SET_IMPORT_PRODUCT_RESPONSE_VIEW';
export const CLEAR_PRODUCTS_IMPORT_RESPONSE = 'CLEAR_PRODUCTS_IMPORT_RESPONSE';
export const SET_SEARCH_KEYWORDS_CLEAR = 'SET_SEARCH_KEYWORDS_CLEAR';
export const FETCH_PRODUCT_IMPORT_TASK = 'FETCH_PRODUCT_IMPORT_TASK';
export const SET_PRODUCT_IMPORT_PROGRESS_VIEW = 'SET_PRODUCT_IMPORT_PROGRESS_VIEW';
export const SET_PRODUCT_IMPORT_LOADING_VIEW = 'SET_PRODUCT_IMPORT_LOADING_VIEW';
export const SET_PRODUCT_FILTER_BY = 'SET_PRODUCT_FILTER_BY';
export const SET_SORT_BY_PRODUCTS = 'SET_SORT_BY_PRODUCTS';
