import {
  AUTO_SYNC_RESPONSE_STATUS,
  CLEAR_PRICE_SYNC_LIST,
  FETCH_PRICE_SYNC_LIST,
  FETCH_SELECTED_SYNC_ITEM,
  SET_AUTO_SYNC_PAGE_NO,
  RETAIN_AUTO_SYNC_STATE,
  SET_AUTO_SYNC_STATE,
  SET_AUTO_SYNC_STATE_INTIAL
} from './autoSyncConstants';

const initialState = {
  retainState: false,
  retainStateInterval: false,
  isAutoSync: false,
  autoSyncStatus: '',
  autoSyncResponseData: '',

  priceSyncListPending: [],
  priceSyncListProcessing: [],
  priceSyncListCompleted: [],
  selectedSyncItem: null,

  totalCountCompleted: 0,
  totalPagesCompleted: 0,
  currentPageCompleted: 1,
  pageSizeCompleted: 50
};

export default function autoSyncReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case AUTO_SYNC_RESPONSE_STATUS:
      return {
        ...state,
        isAutoSync: payload.isAutoSync,
        autoSyncStatus: payload.autoSyncStatus,
        autoSyncResponseData: payload.autoSyncResponseData
      };
    case FETCH_SELECTED_SYNC_ITEM:
      return {
        ...state,
        selectedSyncItem: payload
      };
    case FETCH_PRICE_SYNC_LIST:
      return {
        ...state,

        priceSyncListCompleted: payload.priceSyncList,
        priceSyncListPending: payload.priceSyncListPending,
        priceSyncListProcessing: payload.priceSyncListProcessing,
        totalCountCompleted: payload.totalCount,
        totalPagesCompleted: payload.totalPages,
        currentPageCompleted: payload.currentPage,
        pageSizeCompleted: payload.pageSize
      };
    case CLEAR_PRICE_SYNC_LIST:
      return {
        ...state,
        priceSyncListPending: [],
        priceSyncListProcessing: [],
        priceSyncListCompleted: [],

        totalCountCompleted: 0,
        totalPagesCompleted: 0,
        currentPageCompleted: 1,
        pageSizeCompleted: 50
      };
    case SET_AUTO_SYNC_PAGE_NO:
      return {
        ...state,
        retainState: false,
        currentPageCompleted: payload,
        retainStateInterval: false
      };
    case RETAIN_AUTO_SYNC_STATE:
      return {
        ...state,
        retainState: true
      };
    case SET_AUTO_SYNC_STATE:
      return {
        ...state,
        retainStateInterval: payload
      };
    case SET_AUTO_SYNC_STATE_INTIAL:
      return {
        ...state,
        retainState: false,
        retainStateInterval: false,
        isAutoSync: false,
        autoSyncStatus: '',
        autoSyncResponseData: '',
      
        priceSyncListPending: [],
        priceSyncListProcessing: [],
        priceSyncListCompleted: [],
        selectedSyncItem: null,
      
        totalCountCompleted: 0,
        totalPagesCompleted: 0,
        currentPageCompleted: 1,
        pageSizeCompleted: 50
      };
    default:
      return state;
  }
}
