import {
  FETCH_MY_ORDERS_HISTORY,
  CLEAR_MY_ORDERS_HISTORY,
  SET_MY_ORDERS_PAGE_SIZE_HISTORY,
  SET_MY_ORDERS_PAGE_NO_HISTORY,
  SET_MY_ORDERS_SEARCH_KEYWORDS_HISTORY,
  SET_SELECT_MY_ORDERS_HISTORY,
  SET_SEARCH_BINDED_ORDERS_HISTORY,
  SET_SEARCH_UNBINDED_ORDERS_HISTORY,
  GET_MY_ORDER_DETAIL_HISTORY,
  SET_MY_SELECTED_PRODUCT_ID_HISTORY,
  CLEAR_MY_SELECTED_PRODUCT_HISTORY,
  CLEAR_MY_SELECTED_ORDERS_HISTORY,
  SET_UPDATE_PRODUCT_RESPONSE_VIEW_HISTORY,
  RETAIN_MY_ORDERS_STATE_HISTORY,
  UPDATE_ORDERS_LIST_HISTORY,
  SET_FREE_SHIPPING_MY_PRODUCT_HISTORY,
  SET_EXTRA_SHIPPING_MY_PRODUCT_HISTORY,
  SET_GONE_FROM_SUPPLIER_HISTORY,
  SET_BELOW_DSZ_PRICE_HISTORY,
  REMOVE_ORDERS_LIST_HISTORY,
  CLEAR_REMOVE_ORDERS_RESPONSE_HISTORY,
  RETAIN_MY_ORDERS_DETAILS_STATE_HISTORY,
  CLEAR_MY_ORDER_DETAILS_HISTORY,
  RETAIN_ORDERS_BUNDLE_STATE_HISTORY,
  GET_ORDERS_BUNDLE_DETAILS_HISTORY,
  CLEAR_ORDERS_BUNDLE_DETAILS_HISTORY,
  SET_BUNDLE_PAYMENT_TYPE_HISTORY,
  SET_BUNDLE_PAYMENT_ID_HISTORY,
  SET_ORDER_STATUS_HISTORY,
  SET_ORDER_HISTORY_STATE
} from './ordersHistoryConstants';

const initialState = {
  myOrdersHistoryList: [],
  selectedProducts: loadFromLocalStorage(),
  isProductToUpdateResponse: false,
  productToUpdateResponse: [],
  productToRemoveResponse: '',
  retainState: false,
  totalCount: 0,
  totalPages: 0,
  pageSize: 30,
  currentPage: 1,
  searchKeywords: '',
  bindedProducts: false,
  unbindedProducts: false,
  freeShipping: false,
  extraShipping: false,
  goneFormSupplier: false,
  belowDszPrice: false,
  sortParams: [],
  selectedProductID: null,
  selectedProduct: null,
  isProductSelectAll: false,
  btnAll: false,
  btnShowProductImportList: true,
  btnProceedProductImportList: true,
  isSelected: true,
  bindingMode: '',
  selectedExportProduct: [],
  myOrderDetails: null,
  retainOrderDetailsState: false,
  retainOrderBundleState: false,
  orderBundleDetails: null,
  paymentType: 'paypal',
  bundlePaymentID: 0,
  searchOrderStatus: 'processing',
  activeTabVal: '1'
};

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('myselhistory');
    //console.log("serializedState",serializedState.length)
    if (serializedState === null || serializedState.length === 0) return [];
    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
    return [];
  }
}

export default function ordersHistoryReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FETCH_MY_ORDERS_HISTORY:
      return {
        ...state,
        myOrdersHistoryList: [
          ...state.myOrdersHistoryList,
          ...payload.myOrdersHistoryList
        ],
        totalCount: payload.totalCount,
        totalPages: payload.totalPages,
        currentPage: payload.currentPage,
        pageSize: payload.pageSize,
        isSelected: payload.showPayButton,
        retainOrderDetailsState: false,
        retainOrderBundleState: false,
        myOrderDetails: null
      };
    case GET_MY_ORDER_DETAIL_HISTORY:
      return {
        ...state,
        myOrderDetails: payload.myOrderDetails,
        retainState: false,
        retainOrderDetailsState: true
      };
    case CLEAR_MY_ORDER_DETAILS_HISTORY:
      return {
        ...state,
        retainOrderDetailsState: false,
        retainOrderBundleState: false,
        myOrderDetails: null
      };
    case GET_ORDERS_BUNDLE_DETAILS_HISTORY:
      return {
        ...state,
        orderBundleDetails: payload.orderBundleDetails,
        retainState: true
      };
    case CLEAR_ORDERS_BUNDLE_DETAILS_HISTORY:
      return {
        ...state,
        orderBundleDetails: null
      };
    case SET_SELECT_MY_ORDERS_HISTORY:
      return {
        ...state,

        myOrdersHistoryList: setProductSelection(
          state.myOrdersHistoryList,
          payload.id,
          payload.isSelectAllMode,
          payload.isSelectAll
        ),
        selectedProducts: setProductSelectionList(
          state.myOrdersHistoryList,
          state.selectedProducts,
          payload.id,
          payload.isSelectAllMode,
          payload.isSelectAll
        ),
        isProductSelectAll: payload.isSelectAll,
        selectedExportProduct: selectedProducts(state.myOrdersHistoryList),
        isSelected: isProductImport(state.myOrdersHistoryList)
      };
    case UPDATE_ORDERS_LIST_HISTORY:
      return {
        ...state,
        productToUpdateResponse: [
          ...state.productToUpdateResponse,
          ...payload.productToUpdateResponse
        ],
        retainState: false,
        isProductToUpdateResponse: true,
        bindingMode: payload.bindingMode
      };
    case REMOVE_ORDERS_LIST_HISTORY:
      return {
        ...state,
        productToRemoveResponse: payload.productToUpdateResponse,
        retainState: false,
        selectedProducts: []
      };
    case CLEAR_REMOVE_ORDERS_RESPONSE_HISTORY:
      return {
        ...state,
        productToRemoveResponse: '',
        retainState: true
      };
    case SET_UPDATE_PRODUCT_RESPONSE_VIEW_HISTORY:
      return {
        ...state,
        retainState: true,
        isProductToUpdateResponse: payload,
        productToUpdateResponse: [],
        bindingMode: ''
      };
    case SET_FREE_SHIPPING_MY_PRODUCT_HISTORY:
      return {
        ...state,
        retainState: false,
        freeShipping: payload,
        isProductSelectAll: false,
        currentPage: 1
      };
    case SET_EXTRA_SHIPPING_MY_PRODUCT_HISTORY:
      return {
        ...state,
        retainState: false,
        extraShipping: payload,
        isProductSelectAll: false,
        currentPage: 1
      };
    case SET_GONE_FROM_SUPPLIER_HISTORY:
      return {
        ...state,
        retainState: false,
        goneFormSupplier: payload,
        isProductSelectAll: false,
        currentPage: 1
      };
    case SET_BELOW_DSZ_PRICE_HISTORY:
      return {
        ...state,
        retainState: false,
        belowDszPrice: payload,
        isProductSelectAll: false,
        currentPage: 1
      };
    case CLEAR_MY_ORDERS_HISTORY:
      return {
        ...state,
        myOrdersHistoryList: []
        // currentPage: 1
      };
    case CLEAR_MY_SELECTED_PRODUCT_HISTORY:
      return {
        ...state,
        retainState: false,
        selectedProductID: null,
        selectedProduct: null
      };
    case CLEAR_MY_SELECTED_ORDERS_HISTORY:
      return {
        ...state,
        selectedProducts: loadFromLocalStorage()
      };

    case SET_MY_SELECTED_PRODUCT_ID_HISTORY:
      return {
        ...state,
        retainState: false,
        selectedProductID: payload
      };
    case SET_MY_ORDERS_PAGE_SIZE_HISTORY:
      return {
        ...state,
        retainState: false,
        pageSize: payload
      };
    case SET_MY_ORDERS_PAGE_NO_HISTORY:
      return {
        ...state,
        retainState: false,
        currentPage: payload,
        isProductSelectAll: false
      };
    case SET_MY_ORDERS_SEARCH_KEYWORDS_HISTORY:
      return {
        ...state,
        retainState: false,
        searchKeywords: payload,
        currentPage: 1
      };
    case SET_SEARCH_BINDED_ORDERS_HISTORY:
      return {
        ...state,
        retainState: false,
        bindedProducts: payload,
        isProductSelectAll: false,
        currentPage: 1
      };
    case SET_SEARCH_UNBINDED_ORDERS_HISTORY:
      return {
        ...state,
        retainState: false,
        unbindedProducts: payload,
        isProductSelectAll: false,
        currentPage: 1
      };
    case SET_BUNDLE_PAYMENT_TYPE_HISTORY:
      return {
        ...state,
        paymentType: payload
      };
    case SET_BUNDLE_PAYMENT_ID_HISTORY:
      return {
        ...state,
        bundlePaymentID: payload
      };
    case SET_ORDER_STATUS_HISTORY:
      return {
        ...state,
        searchOrderStatus: payload.searchOrderStatus,
        retainState: false,
        currentPage: 1,
        activeTabVal: payload.activeTab
      };
    case RETAIN_MY_ORDERS_STATE_HISTORY:
      return {
        ...state,
        retainState: true
      };
    case SET_ORDER_HISTORY_STATE:
      return {
        ...state,
        retainState: false
      };
    case RETAIN_MY_ORDERS_DETAILS_STATE_HISTORY:
      return {
        ...state,
        retainOrderDetailsState: true
      };
    case RETAIN_ORDERS_BUNDLE_STATE_HISTORY:
      return {
        ...state,
        retainOrderBundleState: true
      };
    default:
      return state;
  }
}

//Enable button
const isProductImport = (productList) => {
  //console.log("productList",productList)
  let index = productList.findIndex((p) => p.isSelected === true);
  //console.log("Selected count", index);
  if (index === -1) return true;
  else return false;
};

//Set main product list
const setProductSelection = (
  productList,
  productID,
  isSelectAllMode,
  isSelectAll
) => {
  const newList = [];

  if (isSelectAllMode) {
    productList.forEach((product, index) => {
      product.isSelected = isSelectAll;
      newList.push(product);
    });
  } else {
    productList.forEach((product, index) => {
      if (product.order_id === productID) {
        product.isSelected = !product.isSelected;
      }
      newList.push(product);
    });
  }
  return newList;
};

//Set product import list
const setProductSelectionList = (
  productList,
  selectedProductsList,
  productID,
  isSelectAllMode,
  isSelectAll
) => {
  /* console.log("isSelectAllMode",isSelectAllMode);
  console.log("isSelectAll",isSelectAll);
  console.log("productID",productID); */
  const newList = [];
  //add existing value
  selectedProductsList.forEach((product, index) => {
    newList.push(product);
  });
  /*  console.log('newList before', newList);
  console.log('productList', productList);
  console.log('productID', productID);
  console.log('isSelectAllMode', isSelectAllMode); */

  //mode:add/remove all
  if (isSelectAllMode) {
    productList.forEach((product, index) => {
      var itemIndex = selectedProductsList.findIndex(
        (p) => p === product.order_id
      );
      if (itemIndex === -1 && isSelectAll) {
        newList.push(product.order_id);
      } else if (itemIndex > -1 && !isSelectAll) {
        // console.log('splice', itemIndex);
        selectedProductsList.splice(itemIndex, 1);
        var itemIndexTemp = newList.findIndex((p) => p === product.order_id);
        if (itemIndex > -1 && !isSelectAll) {
          newList.splice(itemIndexTemp, 1);
        }
      }
    });
  } else {
    //Single product update
    productList.forEach((product, index) => {
      if (product.order_id === productID) {
        //console.log(product.order_id, productID)
        var itemIndex = selectedProductsList.findIndex(
          (p) => p === product.order_id
        );
        //console.log("itemIndex 1",itemIndex)
        if (itemIndex === -1 && product.isSelected) {
          // console.log('itemIndex 2', itemIndex);
          newList.push(product.order_id);
        } else if (itemIndex > -1 && !product.isSelected) {
          // console.log('itemIndex 3', itemIndex);
          newList.splice(itemIndex, 1);
        }
      }
    });
  }

  //console.log('selectedProductsList', newList);
  saveToLocalStorage(newList);
  return newList;
};

function saveToLocalStorage(productList) {
  try {
    // console.log('saveToLocalStorage', productList);
    const serializedState = JSON.stringify(productList);
    localStorage.setItem('myselhistory', serializedState);
  } catch (e) {
    console.log(e);
  }
}
const sortProductByKey = (list, key, direction) => {
  if (direction === 'asc') return list.slice().sort(sortByKey(key));
  else if (direction === 'desc') return list.slice().sort(sortDescByKey(key));
  else return list;
};

const sortByKey = (key) => {
  return (a, b) => (a[key] > b[key] ? 1 : -1);
};
const sortDescByKey = (key) => {
  return (a, b) => (a[key] > b[key] ? -1 : 1);
};

const selectedProducts = (selectProductList) => {
  const selectedList = selectProductList.filter((order) => order.isSelected);

  const mArray = [];
  if (selectedList && selectedList.length !== 0)
    selectedList.forEach((obj) => {
      let container = {};

      container.sku = obj.sku;
      container.title = obj.title;
      container.bind = obj.bind ? 'Bound' : 'Unbound';
      container.cost = obj.cost;
      container.rrp = obj.rrp;
      container.freeshipping = obj.freeshipping ? 'Y' : 'N';
      container.discontinued = obj.discontinued;

      mArray.push(container);
    });

  // console.log("Array", mArray);
  return mArray;
};
