import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from '../../../reducers/asyncReducer';
import service from '../../../services/request';

const initialState = {
  dataList: [],
  filter: {
    keywords: null,
    fromTime: moment().subtract(7, 'days'),
    toTime: moment().endOf('day'),
    status: 'all',
    searchFlag: false
  },
  pagination: {
    totalCount: 0,
    totalPages: 0,
    currentPage: 1,
    pageSize: 10
  }
};

const commonReducers = {
  setPageSize(state, action) {
    const { payload } = action;
    state.pagination.pageSize = payload;
    return state;
  },
  setCurrentPage(state, action) {
    const { payload } = action;
    state.pagination.currentPage = payload;
    return state;
  }
};

export const fetchList = createAsyncThunk(
  'priceUpdateHistory/fetchList',
  async (params, { dispatch, getState }) => {
    dispatch(asyncActionStart());
    const { keywords, fromTime, toTime, status = 'all' } = params;
    const { priceUpdateHistory = {} } = getState();
    const { pagination } = priceUpdateHistory;

    const data = {
      page_no: pagination.currentPage,
      limit: pagination.pageSize,
      status: status === 'all' ? 'complete,respawned' : status,
      keywords: keywords,
      from_time: fromTime ? fromTime.format('YYYY-MM-DD') : null,
      to_time: toTime ? toTime.format('YYYY-MM-DD') : null
    };

    const result = await service
      .get('api/products/productupdate/getpriceupdaterecord', { params: data })
      .then((response) => {
        dispatch(asyncActionFinish());
        return response.data;
      })
      .catch((error) => dispatch(asyncActionError(error)));
    return result;
  }
);

const priceUpdateHistorySlice = createSlice({
  name: 'priceUpdateHistory',
  initialState,
  reducers: {
    ...commonReducers,
    updateFilter: (state, action) => {
      const { payload } = action;
      state.filter = {
        ...state.filter,
        ...payload
      };
      return state;
    },
    reset: (state, action) => {
      state = {
        ...initialState
      };
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchList.fulfilled, (state, action) => {
      const { data = {} } = action.payload;
      state = {
        ...state,
        pagination: {
          ...state.pagination,
          totalCount: data.total_count || 0,
          totalPages: data.total_pages || 0,
          currentPage: data.cur_page || 1
        },
        dataList: data.data
      };
      return state;
    });
  }
});

const { actions, reducer } = priceUpdateHistorySlice;

export const { setCurrentPage, setPageSize, updateFilter, reset } = actions;

export default reducer;
