import {
  FETCH_PRODUCT_CATEGORIES,
  FETCH_PRODUCTS,
  SET_CATEGORY_ID,
  CLEAR_PRODUCTS,
  RETAIN_STATE,
  RETAIN_STATE_PRODUCT_DETAIL,
  SET_PAGE_SIZE,
  SET_PAGE_NO,
  SET_SEARCH_KEYWORDS,
  SET_FREE_SHIPPING,
  SET_EXTRA_SHIPPING,
  SORT_BY_FREESHIPPING,
  GET_PRODUCT_DETAIL,
  SET_SELECTED_PRODUCT_ID,
  CLEAR_SELECTED_PRODUCT,
  SET_IMPORT_PRODUCT,
  IMPORT_PRODUCTS_LIST,
  CLEAR_SELECTED_PRODUCTS,
  SET_IMPORT_PRODUCT_RESPONSE_VIEW,
  CLEAR_PRODUCTS_IMPORT_RESPONSE,
  SET_SEARCH_KEYWORDS_CLEAR,
  FETCH_PRODUCT_IMPORT_TASK,
  SET_PRODUCT_IMPORT_PROGRESS_VIEW,
  SET_PRODUCT_IMPORT_LOADING_VIEW,
  SET_PRODUCT_FILTER_BY,
  SET_SORT_BY_PRODUCTS
} from './productConstants';

const initialState = {
  categories: [],
  products: [],
  productToImport: loadFromLocalStorage(),
  productToImportResponse: [],
  isProductToImportResponse: false,
  productImportProgress: {},
  isProductImportRunning: false,
  isProductImportProgressShow: false,
  isProductImportLoadingShow: false,
  retainState: false,
  retainStateProductDetail: false,
  selectedCategoryID: 'NEW_ARRIVAL', //New Arrival - Default Category
  selectedCategoryName: 'New Arrivals',
  selectedPreCategoryID: 191, //New Arrival - Default Category
  selectedPreCategoryName: 'New Arrivals',
  selectedCategoryLevel: 1,
  totalCount: 0,
  totalPages: 0,
  pageSize: 50,
  currentPage: 1,
  searchKeywords: '',
  freeShipping: false,
  extraShipping: false,
  sortParams: [],
  selectedProductID: null,
  selectedProduct: null,
  isProductSelectAll: false,
  btnAll: false,
  btnShowProductImportList: true,
  btnProceedProductImportList: false,
  filterBy: '',
  sortBy: ''
};

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('selectedProduct');
   // console.log("loadFromLocalStorage - serializedState",serializedState)
    if (serializedState === null || serializedState === '') return [];
    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
    return [];
  }
}

export default function productReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_PRODUCT_IMPORT_LOADING_VIEW:
      return {
        ...state,
        isProductImportLoadingShow: !!payload.value,
      };    
    case SET_PRODUCT_IMPORT_PROGRESS_VIEW:
      return {
        ...state,
        isProductImportProgressShow: !!payload.value,
      };
    case FETCH_PRODUCT_IMPORT_TASK:
      return {
        ...state,
        isProductImportRunning: payload.isProductImportRunning || false,
        productImportProgress: payload.productImportProgress || {},
      };      
    case FETCH_PRODUCT_CATEGORIES:
      return {
        ...state,
        categories: [...state.categories, ...payload.categories]
      };
    case FETCH_PRODUCTS:
      return {
        ...state,
        products: [...state.products, ...payload.products],
        totalCount: payload.totalCount,
        totalPages: payload.totalPages,
        currentPage: payload.currentPage,
        btnShowProductImportList: payload.showProductImportbButton
      };
    case SET_IMPORT_PRODUCT:
      return {
        ...state,

        products: setProductImport(
          state.products,
          payload.id,
          payload.isSelectAllMode,
          payload.isSelectAll,
          payload.isSelected
        ),
        productToImport: setProductImportList(
          state.products,
          state.productToImport,
          payload.id,
          payload.isSelectAllMode,
          payload.isSelectAll,
          payload.isSelected
        ),
        isProductSelectAll: payload.isSelectAll,
        // btnShowProductImportList: isProductImport(state.products),
        btnShowProductImportList: isProductImport(loadFromLocalStorage()),
        btnProceedProductImportList: isProductImport(loadFromLocalStorage())
      };
    case IMPORT_PRODUCTS_LIST:
      return {
        ...state,
        // productToImportResponse: [
        //   ...state.productToImportResponse,
        //   ...payload.productToImportResponse
        // ],
        retainState: false,
        isProductToImportResponse: true,
        productImportProgress: {},
        isProductImportRunning: false,
        isProductImportLoadingShow: false,
        isProductImportProgressShow: false,
        //btnShowProductImportList: true,
        btnProceedProductImportList: true
      };
   
    case SET_IMPORT_PRODUCT_RESPONSE_VIEW:
      return {
        ...state,
        retainState: true,
        isProductToImportResponse: payload,
        btnProceedProductImportList: false
      };
    case CLEAR_PRODUCTS_IMPORT_RESPONSE:
      return {
        ...state,
        productToImportResponse: []
      };
    case CLEAR_PRODUCTS:
      return {
        ...state,
        products: [],
      //  currentPage: 1
      };
    case CLEAR_SELECTED_PRODUCT:
      return {
        ...state,
        retainState: false,
        selectedProductID: null,
        selectedProduct: null
      };
    case CLEAR_SELECTED_PRODUCTS:
      return {
        ...state,
        productToImport: loadFromLocalStorage()
      };
    case GET_PRODUCT_DETAIL:
      return {
        ...state,
        retainState: true,
        retainStateProductDetail: true,
        selectedProduct: payload
      };
    case SET_CATEGORY_ID:
      return {
        ...state,
        currentPage: payload.currentPage || state.currentPage,
        retainState: false,
        selectedCategoryLevel: payload.level,
        selectedCategoryID: payload.id,
        selectedCategoryName: payload.name,
        selectedPreCategoryID: payload.id,
        selectedPreCategoryName: payload.name,
        filterBy: ''
      };
    case SET_SELECTED_PRODUCT_ID:
      return {
        ...state,
        retainState: true,
        retainStateProductDetail: false,
        selectedProductID: payload
      };
    case SET_PAGE_SIZE:
      return {
        ...state,
        retainState: false,
        pageSize: payload
      };
    case SET_PAGE_NO:
      return {
        ...state,
        retainState: false,
        currentPage: payload,
        isProductSelectAll: false
      };
    case SET_SEARCH_KEYWORDS:
      return {
        ...state,
        retainState: false,
        searchKeywords: payload,
        selectedCategoryID: '',
        selectedCategoryLevel: '',
        selectedCategoryName: 'Search Result(s)',
        currentPage: 1
      };
    case SET_SEARCH_KEYWORDS_CLEAR:
      return {
        ...state,
        retainState: false,
        searchKeywords: payload,
        selectedCategoryID: state.selectedPreCategoryID,
        selectedCategoryLevel: state.selectedCategoryLevel,
        selectedCategoryName: state.selectedPreCategoryName,
        currentPage: 1
      };
    case SET_FREE_SHIPPING:
      return {
        ...state,
        retainState: false,
        freeShipping: payload,
        isProductSelectAll: false,
        currentPage: 1
      };
    case SET_EXTRA_SHIPPING:
      return {
        ...state,
        retainState: false,
        extraShipping: payload,
        isProductSelectAll: false,
        currentPage: 1
      };
    case SET_PRODUCT_FILTER_BY:
      return {
        ...state,
        filterBy: payload,
        retainState: false,
        isProductSelectAll: false,
        currentPage: 1
      };
    case SORT_BY_FREESHIPPING:
      return {
        ...state,

        products: sortProductByKey(
          state.products,
          payload.key,
          payload.direction
        )
        /*  products: state.products.slice().sort(function (a, b) {
          var nameA = a.freeshipping,
            nameB = b.freeshipping;
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        }) */
      };
    /*   case SET_SORT_PARAMS:
      return {
        ...state,
        sortParams: payload
      }; */
      case SET_SORT_BY_PRODUCTS:
        return {
          ...state,
          sortBy: payload,
          retainState: false,
          isProductSelectAll: false,
          currentPage: 1
        };
    case RETAIN_STATE:
      return {
        ...state,
        retainState: true
      };
    case RETAIN_STATE_PRODUCT_DETAIL:
      return {
        ...state,
        retainStateProductDetail: true
      };
    default:
      return state;
  }
}

//Enable button
const isProductImport = (productToImportList) => {
  // console.log("--------------------count", productToImportList.length);
  if (productToImportList.length <= 0) return true;
  else return false;
};

//Set main product list
const setProductImport = (
  productList,
  // productToImportList,
  productID,
  isSelectAllMode,
  isSelectAll,
  isSelected
) => {
  const newList = [];
  // console.log("--------------------------------------", productID, isSelectAllMode, isSelectAll, isSelected);
  if (isSelectAllMode) {
    productList.forEach((product, index) => {
      if (product.is_product != 1) {
        // console.log('--------------', product);
        product.isSelected = isSelectAll;
        // newList.push(product);
      }
      newList.push(product);
    });
  } else {
    productList.forEach((product, index) => {
      if (product.product_id === productID) {
        // console.log('==============--------', product, product.isSelected);
        product.isSelected = !product.isSelected;
      }
      newList.push(product);
    });
  }
  return newList;
};

//Set product import list
const setProductImportList = (
  productList,
  productToImportList,
  productID,
  isSelectAllMode,
  isSelectAll,
  isSelected
) => {
  const newList = [];
  // console.log("========================productID", productID, isSelectAllMode, isSelectAll, isSelected);
  //add existing value
  productToImportList.forEach((product, index) => {
    newList.push(product);
  });
  //mode:add/remove all
  if (isSelectAllMode) {
    
    productList.forEach((product, index) => {
      var itemIndex = productToImportList.findIndex(
        (p) => p.product_id === product.product_id
      );
      if (itemIndex === -1 && isSelectAll) {
        if (product.is_product != 1) {
          // console.log('product', product);
          newList.push(product);
        }
      } else if (itemIndex > -1 && !isSelectAll) {
        // console.log('splice', itemIndex);
        productToImportList.splice(itemIndex, 1);
        var itemIndexTemp = newList.findIndex(
          (p) => p.product_id === product.product_id
        );
        if (itemIndex > -1 && !isSelectAll) {
          newList.splice(itemIndexTemp, 1);
        }
      }
    });
  } else if (isSelected && productID != -1) {
    // console.log("=============remove");
    var itemIndex = newList.findIndex(
      (p) => p.product_id === productID
    );
    // console.log("--------------itemIndex", itemIndex);
    if (itemIndex > -1) {
      newList.splice(itemIndex, 1);
    }
  } else {
    //Single product update
    productList.forEach((product, index) => {
      if (product.product_id === productID) {
        //console.log(product.product_id, productID)
        var itemIndex = productToImportList.findIndex(
          (p) => p.product_id === product.product_id
        );
        //console.log("itemIndex 1",itemIndex)
        if (itemIndex === -1 && product.isSelected) {
          //console.log('itemIndex 2', itemIndex);
          newList.push(product);
        } else if (itemIndex > -1 && !product.isSelected) {
          // console.log('itemIndex 3', itemIndex);
          newList.splice(itemIndex, 1);
        }
      }
    });
  }

  // console.log('productToImportList', newList);
  saveToLocalStorage(newList);
  return newList;
};

function saveToLocalStorage(productList) {
  try {
    //console.log('saveToLocalStorage', productList);
    const serializedState = JSON.stringify(productList);
    localStorage.setItem('selectedProduct', serializedState);
  } catch (e) {
    console.log(e);
  }
}
/* const productFilterByExtraShipping = (
  productList,
  isExtraShipping,
  productsTemp,
  isFreeShipping
) => {
  if (isFreeShipping && isExtraShipping) return productsTemp;
  else if (isExtraShipping)
    return productList.filter((p) => p.freeshipping === false);
  else return productsTemp;
};

const productFilterByFreeShipping = (
  productList,
  isFreeShipping,
  productsTemp,
  isExtraShipping
) => {
  if (isFreeShipping && isExtraShipping) return productsTemp;
  else if (isFreeShipping) return productList.filter((p) => p.freeshipping === true);
  else return productsTemp;
}; */

const sortProductByKey = (list, key, direction) => {
  if (direction === 'asc') return list.slice().sort(sortByKey(key));
  else if (direction === 'desc') return list.slice().sort(sortDescByKey(key));
  else return list;
};

const sortByKey = (key) => {
  return (a, b) => (a[key] > b[key] ? 1 : -1);
};
const sortDescByKey = (key) => {
  return (a, b) => (a[key] > b[key] ? -1 : 1);
};
