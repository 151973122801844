export const SET_ORDER_DETAILS = 'SET_ORDER_DETAILS';
export const CLEAR_ORDERS_DETAILS = 'CLEAR_ORDERS_DETAILS';
export const SET_ORDERS_PAGE_NO = 'SET_ORDERS_PAGE_NO';

export const SET_PAGE_LOADING = 'SET_PAGE_LOADING';
export const SET_ORDERS_STATUS = 'SET_ORDERS_STATUS';
export const SET_STATUS_TOTAL = 'SET_STATUS_TOTAL';
export const CLEAR_FILTER = 'CLEAR_FILTER';

export const SET_ORDER_TYPE = 'SET_ORDER_TYPE';
export const SET_ARCHIVE_ORDER_LIST = 'SET_ARCHIVE_ORDER_LIST';
export const SET_ARCHIVE_ORDER_PAGE_NO = 'SET_ARCHIVE_ORDER_PAGE_NO';
export const SET_ARCHIVE_ORDER_TOTAL = 'SET_ARCHIVE_ORDER_TOTAL';

export const RESET_ORDER_STORE = 'RESET_ORDER_STORE';