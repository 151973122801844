export const FETCH_SPECIAL_PRICE_UPDATES = 'FETCH_SPECIAL_PRICE_UPDATES';
export const CLEAR_SPECIAL_PRICES = 'CLEAR_SPECIAL_PRICES';
export const SPECIAL_PRICE_RETAIN_STATE = 'SPECIAL_PRICE_RETAIN_STATE';
export const SPECIAL_PRICE_SET_PAGE_SIZE = 'SPECIAL_PRICE_SET_PAGE_SIZE';
export const SPECIAL_PRICE_SET_PAGE_NO = 'SPECIAL_PRICE_SET_PAGE_NO';
export const SPECIAL_PRICE_SET_SORT_PARAMS = 'SPECIAL_PRICE_SET_SORT_PARAMS';
export const FETCH_SPECIAL_PRICE_UPDATE_PROGRESS = 'FETCH_SPECIAL_PRICE_UPDATE_PROGRESS';
export const SET_SPECIAL_PRICE_UPDATE_PROGRESS_VIEW = 'SET_SPECIAL_PRICE_UPDATE_PROGRESS_VIEW';
export const SET_SPECIAL_PRICE_UPDATE_LOADING_VIEW = 'SET_SPECIAL_PRICE_UPDATE_LOADING_VIEW';
export const CLEAR_SPECIAL_PRICE_UPDATES = 'CLEAR_SPECIAL_PRICE_UPDATES';
