// @ts-check

import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Label, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as qs from 'query-string';

import { signOutUser } from '../../components/auth/authActions';
import logo from '../../assets/images/logo_new.png';
import { useSsoAuth } from '../../hooks/useSsoAuth';
import BlockUi from 'react-block-ui';
import { GridLoader } from 'react-spinners';

export default function Logout(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { returnUrl, return_url } = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const theReturnUrl = returnUrl || return_url

  const [loading, setLoding] = useState(false)
  const { dszEmail, token } = useSelector((state) => state.auth);
  const { ssoEnabled, ssoReady, ssoLogout } = useSsoAuth(dszEmail, token)

  async function handleSignOut(e) {
    //e.preventDefault();
    console.log('start handleSignOut')
    try {
      setLoding(true);
      await ssoLogout();
      await dispatch(signOutUser());
      setLoding(false);
      if (theReturnUrl) {
        window.location.href = theReturnUrl;
      } else {
        history.push('/login');
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function handleGoHome(e) {
    e.preventDefault();
    if (theReturnUrl) {
      window.location.href = theReturnUrl;
    } else {
      history.push('/');
    }
  }

  useEffect(() => {
    handleSignOut()
  }, [])

  return (
    <>
      {/* <div className="app-wrapper bg-white min-vh-100">
        <div className="app-main min-vh-100">
          <div className="app-content p-0">
            <div className="app-content--inner d-flex align-items-center">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content py-5">
                  <Container>
                    <Row>
                      <Col xs={{ order: 2 }} md={{ order: 1 }} lg="6" className="d-flex align-items-center">
                        <div className="divider-v d-none d-lg-block divider-v-md" />
                        <div className="w-100 pr-0">
                          <div className="text-black mt-3">
                            <span className="text-center">
                              <h1 className="display-4 mb-1 font-weight-bold">
                                Logout dropshipzone
                              </h1>
                              <p></p>
                            </span>

                            <div className="flex-grow-1 d-flex align-items-center">
                              <div className="bg-composed-wrapper--content py-5">
                                <Container>
                                  <Row>
                                    <Col
                                      className="d-flex d-flex align-items-center justify-content-center text-center not-found-container">
                                      <Button
                                        onClick={handleSignOut}
                                        size="lg"
                                        className="text-uppercase font-weight-bold font-size-sm"
                                        color="primary"
                                        disabled={loading || (ssoEnabled && !ssoReady)}>
                                        {loading && (
                                          <span
                                            className="btn-wrapper--icon spinner-border spinner-border-sm mb-1 mr-2"
                                            role="status"
                                            aria-hidden="true"></span>
                                        )}
                                        Logout
                                      </Button>
                                    </Col>
                                    <Col className="d-flex d-flex align-items-center justify-content-center text-center not-found-container">
                                      <Button
                                        onClick={handleGoHome}
                                        size="lg"
                                        className="text-uppercase font-weight-bold font-size-sm"
                                        color="light">
                                        Cancel
                                      </Button>
                                    </Col>
                                  </Row>
                                </Container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={{ order: 1 }} md={{ order: 2 }}
                        lg="6"
                        className="d-lg-flex align-items-center">
                        <img
                          alt="..."
                          className="w-90 mx-auto d-block img-fluid"
                          src={logo}
                        />
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <BlockUi
        tag="div"
        style={{ minHeight: 500 }}
        blocking={loading}
        loader={
          <GridLoader color={'var(--primary)'} loading={true} />
        }></BlockUi>
    </>
  );
}
