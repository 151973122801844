export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function toServerDate(momentDate) {
  return momentDate.format('YYYY-MM-DD');
}

export function toServerDateTime(momentDate) {
  return momentDate.format('YYYY-MM-DD HH:mm:ss');
}

const PERMANENT_PREFIX = 'p_';

/**
 * Save key/value to localstorage, only if the new value is not equal to the old one
 * @param {string} key
 * @param {string} value
 */
export function saveOnlyNew(key, value) {
  let result = false;
  if (key && key.length) {
    let oldValue = localStorage.getItem(PERMANENT_PREFIX + key);
    if (oldValue !== value) {
      localStorage.setItem(PERMANENT_PREFIX + key, value);
      result = true;
    }
  }
  return result;
}

/**
 * Clear localstorage values which is temporary
 */
export function clearStorage() {
  for (const key in localStorage) {
    if (key && key.length && !key.startsWith(PERMANENT_PREFIX)) {
      localStorage.removeItem(key);
    }
  }
}

/**
 * file to base64
 */
export const fileTobase64 = (file) => {
  return new Promise(function (resolve, reject) {
    let reader = new FileReader();
    let fileResult = '';
    reader.readAsDataURL(file);
    reader.onload = function () {
      fileResult = reader.result;
      fileResult = fileResult.replace(
        /^data:[a-zA-Z0-9]+\/[a-zA-Z0-9]+;base64,/,
        ''
      );
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.onloadend = function () {
      resolve(fileResult);
    };
  });
};


/**
* @function handleNumber
* @param {number} num - Number entered
* @param {number} fixedNum - Number of decimal places to retain
* @param {boolean} isThousandSeparators - Whether to separate by thousand separators
* @param {string} prefix - prefix added
* @returns {string} Formatted numbers
*/
export const handleNumber = (num, fixedNum, isThousandSeparators, prefix) => {
  const parsedNum = parseFloat(num);
  if (isNaN(parsedNum)) {
    return 'N/A';
  }
 
  let formattedNum = parsedNum.toFixed(fixedNum);
 
  if (isThousandSeparators) {
    formattedNum = formatWithThousandSeparators(formattedNum);
  }
 
  return prefix ? prefix + formattedNum : formattedNum;
 };
 
 /**
 * @function formatWithThousandSeparators
 * @param {string} number -Number entered
 * @returns {string} Formatted numbers, separated by thousandths
 */
 export const formatWithThousandSeparators = (number) => {
  const regex = /(\d)(?=(\d{3})+(?!\d))/g;
  return number.replace(regex, '$1,');
 };
