// @ts-check

import { useLocalStorage, useCookie } from 'react-use';
import { createAxios } from './common';
import _ from 'lodash';
import Cookies from 'js-cookie';
import { isMvpTestUser } from '../components/auth/authActions';

const protocol = window.location.href.indexOf("https://") == 0 ? "https" : "http"
const isDevEnvironment = process.env.REACT_APP_ENVIRONMENT !== "production"; 

const DSZ_API_URL = process.env.REACT_APP_SSO_DSZ_API_URL || 'https://services.dev.dropshipzone.com.au/api/dsz-api';
const DSZ_SITE_URL = process.env.REACT_APP_DSZ_WEBSITE_URL
const LEGACY_SITE_URL = process.env.REACT_APP_DSZ_LEGACY_WEBSITE_URL || DSZ_SITE_URL;
const SSO_COOKIE_NAME = "dsz_sso"
const SSO_COOKIE_DOMAIN = '.dropshipzone.com.au'
const SSO_COOKIE_MINUTES = 60*16
const SSO_COOKIE_EXPIRES_FN = () => new Date(new Date().getTime() + SSO_COOKIE_MINUTES * 60 * 1000)

const API_CONFIG = {
  SSO_SIGNATURE: {
    method: 'POST',
    url: DSZ_API_URL + '/sso/signature',
  },
  SSO_LOGIN: {
    method: 'POST',
    url: LEGACY_SITE_URL + '/singleLogin.php?action=login'
  },
  SSO_LOGOUT: {
    method: 'GET',
    url: LEGACY_SITE_URL + '/singleLogin.php?action=logout'
  },
  SITE_LOGOUT: {
    method: 'POST',
    url : DSZ_SITE_URL + '/api/logout'
  }
}


/**
 * @param {string} [dszEmail]
 * @param {string} [dszApiToken] 
 * @param {string} [loginEmail] 
 */
export function useSsoAuth(dszEmail, dszApiToken, loginEmail) {

  let ssoEnabled = false
  if (window.location.host.indexOf('dropshipzone.com.au') >= 0) {
    ssoEnabled = true
  }

  let authRedirectUrl
  if (ssoEnabled) {
    if (window.location.host.indexOf('dropshipzone.com.au') >= 0) {
      authRedirectUrl = `${protocol}://${isDevEnvironment ? "devretail" : "retail"}.dropshipzone.com.au`
    } else if (window.location.host.indexOf('test.com') >= 0) {
      authRedirectUrl = 'http://sell.test.com'
    } else if (window.location.host.indexOf('localhost:3000') >= 0) {
      authRedirectUrl = 'http://localhost:3000'
    }
  }

  let ssoReady = true
  if (!ssoEnabled || !dszEmail || !dszApiToken) {
    ssoReady = false
  }

  const storageKey = `useSsoAuth_ssoLoggedIn`
  const [ssoLoggedIn, setSsoLoggedIn, removeSsoLoggedIn] = useLocalStorage(storageKey, false);

  async function ssoLogin() {

    let loginResult = false;
    try {
      console.log('start useSsoAuth')

      // request dsz api to get sso signature
      /**
       * @type {{ data: { success: boolean, message: string, result: { email: string, timestamp: number, nonce: string, signature: string }}}}
       */
      const ssoSignatureRes = await createAxios().request({
        headers: {
          'Authorization': 'jwt ' + dszApiToken
        },
        url: API_CONFIG.SSO_SIGNATURE.url,
        method: "POST",
        data: {
          email: dszEmail,
        }
      });

      // call magento to do sso login
      const signatureData = ssoSignatureRes.data
      if (signatureData && signatureData.success) {
        const { email, timestamp, nonce, signature } = signatureData.result
        const ssoLoginRes = await createAxios(true).request({
          url: API_CONFIG.SSO_LOGIN.url,
          method: API_CONFIG.SSO_LOGIN.method,
          data: {
            email: dszEmail,
            rand_code: nonce,
            timestamp: timestamp,
            sign: signature,
          },
        })
      }
    } catch (error) {
      //if magento is not accessable or fail to get response of sso success, simply log the error but allow the user to access
      console.error(`useSsoAuth error: ${error}`);
    } finally {
      //for now regardless success or failure, change redux status and setup cookie
      loginResult = true
      setSsoLoggedIn(true)
      Cookies.set(SSO_COOKIE_NAME, 'true', {domain: SSO_COOKIE_DOMAIN, expires: SSO_COOKIE_EXPIRES_FN()})
      console.log('finish useSsoAuth')
    }
    return loginResult
  }

  async function ssoLogout() {
    // if (!ssoEnabled) {
    //   console.error(`sso disabled`)
    //   return false
    // }
    let logoutResult = false;
    try {
      await createAxios(true).request({
        url: API_CONFIG.SSO_LOGOUT.url,
        method: API_CONFIG.SSO_LOGOUT.method,
      })
      logoutResult = true
      setSsoLoggedIn(false)
      Cookies.remove(SSO_COOKIE_NAME, {domain: SSO_COOKIE_DOMAIN})
    } catch (error) {
      logoutResult = false
      console.error(`dsz legacy site sso logout error: ${error}`);
    }

    try {
      await createAxios(true).request({
        url: API_CONFIG.SITE_LOGOUT.url,
        method: API_CONFIG.SITE_LOGOUT.method,
      })
    } catch (error) {
      console.error(`dsz site sso logout error: ${error}`);
    }

    return logoutResult
  }

  /**
   * @param {boolean} [remove] whether to remove the ssoLoggedIn flag 
   */
  async function resetSsoLoggedIn(remove) {
    setSsoLoggedIn(false)
    if (remove) {
      removeSsoLoggedIn()
    }
  }

  return {
    ssoEnabled, ssoReady, authRedirectUrl, ssoLoggedIn, ssoLogin, ssoLogout, resetSsoLoggedIn
  }
}
