import {
  CREATE_NOTIFICATION
} from './notificationConstants';

const initialState = {
  notifications: []
};

export default function notificationReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case CREATE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.push({
          message: payload.message,
          type: payload.messageType
        })
      };
   
    default:
      return state;
  }
}
