// @ts-check

import axios from 'axios';

/**
 * Create a base instance of Axios
 *
 * @param {boolean} [withCredentials]
 * @returns
 */
export function createAxios(withCredentials = false) {
  const instance = axios.create({
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    withCredentials: withCredentials,
    transformResponse: [
      (data) => {
        if (typeof data === 'string' && data.startsWith('{')) {
          data = JSON.parse(data);
        }
        return data;
      }
    ],
    timeout: 30000
  });
  return instance;
}

