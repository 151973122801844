const ASYNC_ACTION_START = 'ASYNC_ACTION_START';
const ASYNC_ACTION_FINISH = 'ASYNC_ACTION_FINISH';
const ASYNC_ACTION_ERROR = 'ASYNC_ACTION_ERROR';
export const APP_LOADED = 'APP_LOADED';

const ASYNC_ACTION_START_SECONDARY = 'ASYNC_ACTION_START_SECONDARY';
const ASYNC_ACTION_FINISH_SECONDARY = 'ASYNC_ACTION_FINISH_SECONDARY';

const ASYNC_ACTION_START_EXPORT_ALL = 'ASYNC_ACTION_START_EXPORT_ALL';
const ASYNC_ACTION_FINISH_EXPORT_ALL = 'ASYNC_ACTION_FINISH_EXPORT_ALL';
const ASYNC_ACTION_START_EXPORT_PARTIAL = 'ASYNC_ACTION_START_EXPORT_PARTIAL';
const ASYNC_ACTION_FINISH_EXPORT_PARTIAL = 'ASYNC_ACTION_FINISH_EXPORT_PARTIAL';

export function asyncActionStart() {
  return {
    type: ASYNC_ACTION_START
  };
}

export function asyncActionFinish() {
  return {
    type: ASYNC_ACTION_FINISH
  };
}

export function asyncActionStartSecondary() {
  return {
    type: ASYNC_ACTION_START_SECONDARY
  };
}

export function asyncActionFinishSecondary() {
  return {
    type: ASYNC_ACTION_FINISH_SECONDARY
  };
}

export function asyncActionStartExportALL() {
  return {
    type: ASYNC_ACTION_START_EXPORT_ALL
  };
}

export function asyncActionFinishExportALL() {
  return {
    type: ASYNC_ACTION_FINISH_EXPORT_ALL
  };
}

export function asyncActionStartExportPartial() {
  return {
    type: ASYNC_ACTION_START_EXPORT_PARTIAL
  };
}

export function asyncActionFinishExportPartial() {
  return {
    type: ASYNC_ACTION_FINISH_EXPORT_PARTIAL
  };
}

export function asyncActionError(error) {
  console.log(error);
  return {
    type: ASYNC_ACTION_ERROR,
    payload: error
  };
}

const initialState = {
  loading: true,
  loadingSecondary: false,
  loadingExportAll: false,
  loadingExportPartial: false,
  error: null,
  initialized: false
};

export default function asyncReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ASYNC_ACTION_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ASYNC_ACTION_FINISH:
      return {
        ...state,
        loading: false
      };
    case ASYNC_ACTION_START_SECONDARY:
      return {
        ...state,
        loadingSecondary: true,
        error: null
      };
    case ASYNC_ACTION_FINISH_SECONDARY:
      return {
        ...state,
        loadingSecondary: false
      };
    case ASYNC_ACTION_START_EXPORT_ALL:
      return {
        ...state,
        loadingExportAll: true,
        error: null
      };
    case ASYNC_ACTION_FINISH_EXPORT_ALL:
      return {
        ...state,
        loadingExportAll: false
      };
    case ASYNC_ACTION_START_EXPORT_PARTIAL:
      return {
        ...state,
        loadingExportPartial: true,
        error: null
      };
    case ASYNC_ACTION_FINISH_EXPORT_PARTIAL:
      return {
        ...state,
        loadingExportPartial: false
      };
    case ASYNC_ACTION_ERROR:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case APP_LOADED:
      return {
        ...state,
        initialized: true
      };
    default:
      return state;
  }
}
