// @ts-check

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import * as qs from 'query-string';
import BlockUi from 'react-block-ui';
import { GridLoader } from 'react-spinners';
import { toast } from 'react-toastify';

import { APP_LOADED } from '../../reducers/asyncReducer';
import { signInUser, signOutUser } from './authActions';
import { useSsoAuth } from '../../hooks/useSsoAuth';

function saveUserData(response) {
  localStorage.setItem('dsz_email', response.data.dsz_email);
  localStorage.setItem('binding', response.data.binding);
  localStorage.setItem('user_id', response.data.user_id);
  localStorage.setItem('email', response.data.email);
  localStorage.setItem('shop_name', response.data.shop_name);
  localStorage.setItem('token', response.data.token == undefined ? "" : response.data.token);

  sessionStorage.setItem('user_id', response.data.user_id);
  sessionStorage.setItem('binding', response.data.binding);
  sessionStorage.setItem('email', response.data.email);
  sessionStorage.setItem('dsz_email', response.data.dsz_email);
  sessionStorage.setItem('shop_name', response.data.shop_name);
}

export default function AuthCallBack(props) {
  const dispatch = useDispatch();
  const { showFeatureItems5 } = useSelector((state) => state.ThemeOptions);
  const { message, dszEmail, token } = useSelector((state) => state.auth);

  const [nextPageUrl, setNextPageUrl] = useState('')
  const { ssoEnabled, ssoReady, authRedirectUrl, ssoLogin, resetSsoLoggedIn } = useSsoAuth(dszEmail, token)

  useEffect(() => {
    async function asyncFuncCallBack() {
      const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
      return axios
        .get('api/auth/callback', { params })
        .then(
          async (response) => {
            console.log('api auth/callback response', response.data);
            const binding = response.data.binding
            let nextUrl
            if (binding) {
              await dispatch({ type: 'RESET' });
              saveUserData(response)
              await dispatch(
                signInUser(
                  response.data.email,
                  response.data.email,
                  response.data.shop_name,
                  response.data.token,
                  response.data.dsz_email,
                )
              );
              await dispatch({ type: APP_LOADED });
              if (window.location.host.indexOf('dropshipzone.com.au') >= 0) {
                nextUrl = (authRedirectUrl || response.data.url) + '/orders';
              } else {
                nextUrl = (authRedirectUrl || response.data.url) + '/MyAccount';
              }
            } else {
              if (ssoEnabled) {
                await resetSsoLoggedIn()
              }
              toast.error(`The current account is not bound to a dropshipzone account.`);
              saveUserData(response)
              if (response.data.url) {
                nextUrl = (authRedirectUrl || response.data.url) + '/login';
              } else {
                nextUrl = '/login';
              }
            }

            setNextPageUrl(nextUrl)
            if (nextUrl && (!ssoEnabled || (ssoEnabled && !binding))) {
              window.location.href = nextUrl
            }
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              `Failed to log in via shopify, please try again later.`;
            toast.error(message)
            props.history.push('/login');
          }
        );
    }

    asyncFuncCallBack();
  }, [])

  useEffect(() => {
    const handleSsoLogin = async () => {
      console.log(`handleSsoLogin: ` + JSON.stringify({ dszEmail, token }))
      if (ssoEnabled && ssoReady && nextPageUrl) {
        const ssoLoginResult = await ssoLogin();
        if (ssoLoginResult) {
          window.location.href = nextPageUrl;
        } else {
          toast.error(`Failed to log in via shopify, please try again later.`);
          await dispatch(signOutUser())
          props.history.push('/login');
        }
      }
    };

    handleSsoLogin();
  }, [ssoEnabled, ssoReady, nextPageUrl])

  return (
    <>
      <BlockUi
        tag="div"
        style={{ minHeight: 500 }}
        blocking={true}
        loader={
          <GridLoader color={'var(--primary)'} loading={true} />
        }></BlockUi>
    </>
  );
}
