// @ts-check

import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { auth } from './authActions';
import BlockUi from 'react-block-ui';

import { GridLoader } from 'react-spinners';
import { useSsoAuth } from '../../hooks/useSsoAuth';

export default function Auth() {
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const shopName = params.get('shop');

  const { ssoEnabled, authRedirectUrl } = useSsoAuth()

  useEffect(() => {
    if (shopName) {
      if (ssoEnabled && authRedirectUrl) {
        dispatch(auth(shopName, authRedirectUrl));
      } else {
        dispatch(auth(shopName));
      }
    }
  }, []);

  return (
    <>
      <BlockUi
        tag="div"
        style={{ minHeight: 500 }}
        blocking={true}
        loader={<GridLoader color={'var(--primary)'} loading={true} />}>
      </BlockUi>
      <div className="app-wrapper bg-white min-vh-100"></div>
    </>
  );
}
