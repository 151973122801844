import {
  SET_PAGE_LOADING,
  SET_MESSAGE_ORDER_DETAILS,
  SET_TICKET_DETAILS,
  SET_TICKET_USER,
  SET_SHOW,
  CLEAR_DATA
} from './messageOptionConstants';
const initialState = {
  show: false,
  loading: false,

  orderDetails: null,
  ticketDetails: null,
  ticketUser: null
};

export default function messageOptionReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_SHOW:
      return {
        ...state,
        show: true
      };
    case SET_PAGE_LOADING:
      return {
        ...state,
        pageLoading: payload.pageLoading
      };
    case SET_MESSAGE_ORDER_DETAILS:
      return {
        ...state,
        orderDetails: payload.orderDetails
      };
    case SET_TICKET_DETAILS:
      return {
        ...state,
        ticketDetails: payload.ticketDetails
      };
    case SET_TICKET_USER:
      return {
        ...state,
        ticketUser: payload.ticketUser
      };
    case CLEAR_DATA:
      return {
        ...state,
        show: false,
        loading: false,
        hasTicket: payload?.hasTicket ? payload.hasTicket : false,
        ticketDetails: null,
        ticketUser: null
      };
    default: {
      return state;
    }
  }
}
