import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import ThemeOptions from './ThemeOptions';
import asyncReducer from './asyncReducer';
import authReducer from '../components/auth/authReducer';
import productsReducer from '../components/products/productReducer';
import productsInfoReducer from '../components/products/ProductInfoUpdate/productInfoReducer';
import productPriceReducer from '../components/products/ProductPriceUpdate/productPriceReducer';
import productPriceUpdateLogReducer from '../components/products/ProductPriceUpdateLog/productPriceUpdateLogReducer';
import specialPriceReducer from '../components/products/SpecialPriceUpdate/specialPriceReducer';
import specialPriceUpdateLogReducer from '../components/products/SpecialPriceUpdateLog/specialPriceUpdateLogReducer';
import profileReducer from '../components/myAccount/profileReducer';
import myProductReducer from '../components/products/MyProducts/myProductReducer';
import inventoryUpdateReducer from '../components/products/InventoryUpdateLog/inventoryUpdateReducer';
import settingsReducer from '../components/settings/settingsReducer';
import shippingCostInquiryReducer from '../components/shippingCostInquiry/shippingCostInquiryReducer';
import priceUpdateHistoryReducer from '../components/products/PriceUpdateHistory/priceUpdateHistorySlice';
import unpaidOrderReducer from '../components/products/UnpaidOrders/unpaidOrdersReducer';
import orderHistoryReducer from '../components/products/OrdersHistory/ordersHistoryReducer';
import notificationReducer from '../components/notification/notificationReducer';
import autoSyncReducer from '../components/products/AutoSync/autoSyncReducer';

import OrderNewReducer from '../components/products/NewOrder/orderNewReducer';
import PaymentRedurce from '../components/PaymentNew/paymentReducer';
import OrderRedurce from '../components/products/Orders/ordersReducer';
import batchOrderNewReducer from '../components/products/NewBatchOrder/batchOrderNewReducer';
import orderDetailRedurce from '../components/products/OrderDetail/orderDetailRedurce';
import messageOptionReducer from '../components/messageOption/messageOptionReducer';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    ThemeOptions: ThemeOptions,
    async: asyncReducer,
    auth: authReducer,
    productCatalogue: productsReducer,
    productsInfo: productsInfoReducer,
    shippingCostInquiry: shippingCostInquiryReducer,
    productPrice: productPriceReducer,
    productPriceLog: productPriceUpdateLogReducer,
    specialPrice: specialPriceReducer,
    specialPriceLog: specialPriceUpdateLogReducer,
    inventoryUpdate: inventoryUpdateReducer,
    profile: profileReducer,
    myProducts: myProductReducer,
    settings: settingsReducer,
    priceUpdateHistory: priceUpdateHistoryReducer,
    unpaidOrder: unpaidOrderReducer,
    orderHistory: orderHistoryReducer,
    notifications: notificationReducer,
    autoSync: autoSyncReducer,
    orderNew: OrderNewReducer,
    payment: PaymentRedurce,
    order: OrderRedurce,
    batchOrder: batchOrderNewReducer,
    orderDetail: orderDetailRedurce,
    messageOption: messageOptionReducer
  });

export default rootReducer;
