import { SIGN_IN_USER, SIGN_OUT_USER, SET_MESSAGE, CLEAR_MESSAGE } from './authConstants';
import { LOCATION_CHANGE } from 'connected-react-router';

const initialState = {
  authenticated: false,
  currentUser: null,
  email: null,
  shopname: null,
  prevLocation: null,
  currentLocation: null,
  message: '',
  token: '',
  dszEmail: null,
  authCode: null,
};

export default function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SIGN_IN_USER:
      return {
        ...state,
        shopname: payload.shopname,
        email: payload.email,
        authenticated: true,
        token: payload.token,
        dszEmail: payload.dszEmail,
        myShopifyDomain: payload.myShopifyDomain,
        authCode: payload.authCode
        /*  currentUser: {
           dszemail: payload.user.dsz_email,
           email: payload.user.email,
           uid: payload.user.user_id,
           shopname: payload.user.shop_name
         }, */
      };
    case SIGN_OUT_USER:
      return {
        ...state,
        ...initialState,
        authenticated: false,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        prevLocation: state.currentLocation,
        currentLocation: payload.location
      }
    case SET_MESSAGE:
      return { message: payload };

    case CLEAR_MESSAGE:
      return { message: "" };
    default:
      return state;
  }
}

