import {
  SET_FORM_1_DATA,
  SET_SELECT_SKU_LIST,
  SET_USERINFO_DATA,
  SET_PAGE_LOADING,
  SET_ORDER_DETAILS,
  SET_CONFIRMED_ORDERS,
  CLEAR_ORDERS
} from './orderNewConstants';

const initialState = {
  form1: {},
  selectSkuList: [],
  userInfo: {},
  pageLoading: false,

  bundleId: null,
  orders: [],
  shippingFee: 0,
  suppliers: [],
  totalAmount: 0,

  // /Order/Confirmed
  confirmedOrders: []
};

export default function orderNewReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_FORM_1_DATA:
      return {
        ...state,
        form1: payload.form1
      };
    case SET_SELECT_SKU_LIST:
      return {
        ...state,
        selectSkuList: payload.list
      };
    case SET_USERINFO_DATA:
      return {
        ...state,
        userInfo: payload.userInfo
      };
    case SET_CONFIRMED_ORDERS:
      return {
        ...state,
        confirmedOrders: payload.confirmedOrders
      };
    case SET_PAGE_LOADING:
      return {
        ...state,
        pageLoading: payload.pageLoading
      };
    case SET_ORDER_DETAILS: {
      return {
        ...state,
        bundleId: payload?.orderDetails?.bundleId,
        orders: payload?.orderDetails?.orders,
        shippingFee: payload?.orderDetails?.shippingFee,
        suppliers: payload?.orderDetails?.suppliers,
        totalAmount: payload?.orderDetails?.totalAmount
      };
    }
    case CLEAR_ORDERS: {
      return {
        ...state,
        form1: {},
        selectSkuList: {},
        bundleId: null,
        orders: [],
        shippingFee: 0,
        suppliers: [],
        totalAmount: 0,
        confirmedOrders: []
      };
    }
    default: {
      return state;
    }
  }
}
