import {
  FETCH_MY_ORDERS,
  CLEAR_MY_ORDERS,
  SET_MY_ORDERS_PAGE_SIZE,
  SET_MY_ORDERS_PAGE_NO,
  SET_MY_ORDERS_SEARCH_KEYWORDS,
  SET_SELECT_MY_ORDERS,
  SET_SEARCH_BINDED_ORDERS,
  SET_SEARCH_UNBINDED_ORDERS,
  SET_BINDED_ORDERS,
  SET_UNBINDED_ORDERS,
  GET_MY_ORDER_DETAIL,
  SET_MY_SELECTED_PRODUCT_ID,
  CLEAR_MY_SELECTED_PRODUCT,
  CLEAR_MY_SELECTED_UNPAIDORDERS,
  SET_UPDATE_PRODUCT_RESPONSE_VIEW,
  RETAIN_MY_ORDERS_STATE,
  UPDATE_ORDERS_LIST,
  SET_FREE_SHIPPING_MY_PRODUCT,
  SET_EXTRA_SHIPPING_MY_PRODUCT,
  SET_GONE_FROM_SUPPLIER,
  SET_BELOW_DSZ_PRICE,
  REMOVE_ORDERS_LIST,
  CLEAR_REMOVE_ORDERS_RESPONSE,
  RETAIN_MY_ORDERS_DETAILS_STATE,
  CLEAR_ORDERS_BUNDLE_DETAILS,
  GET_ORDERS_BUNDLE_DETAILS,
  SET_BUNDLE_PAYMENT_TYPE,
  SET_BUNDLE_PAYMENT_ID,
  SET_ORDER_STATUS,
  GET_CREDIT_POINTS,
  SET_PAY_POPUP,
  GET_CLIENT_TOKEN,
} from './unpaidOrdersConstants';

import { SET_ORDER_HISTORY_STATE } from '../OrdersHistory/ordersHistoryConstants';

import axios from 'axios';

import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from '../../../reducers/asyncReducer';

import serviceToken from '../../../services/requestToken';
import { toast } from 'react-toastify';

const API_URL = process.env.REACT_APP_API_URL_DSZ;

export function fetchMyOrder(
  pageNo,
  pageSize,
  searchKeywords,
  fromTime,
  toTime,
  orderStaus
) {
  let url = API_URL + 'rp/orders';
  let pageParams = {
    start_date: fromTime,
    end_date: toTime,
    status: orderStaus,
    page_no: pageNo,
    limit: pageSize,
    keywords: searchKeywords
  };

  //console.log('fetchMyOrder pageParams', pageParams);
  //console.log('pageParams', pageParams);
  /*  const response = {
    data: {
      success: true,
      message: 'OK',
      result: [
        {
          order_id: '3939889',
          reference_number: '#28825252',
          order_date: '2022-06-24T18:57:37.000Z',
          amount: 689.2,
          status: 'unpaid'
        },
        {
          order_id: '3939890',
          reference_number: '#28825252',
          order_date: '2022-01-09T17:57:37.000Z',
          amount: 300.5,
          status: 'unpaid'
        },
        {
          order_id: '3939894',
          reference_number: '#28825260',
          order_date: '2022-07-07T18:57:37.000Z',
          amount: 169.25,
          status: 'unpaid'
        }
      ],
      total: 6,
      total_pages: 2,
      current_page: 1,
      page_size: 3
    }
  }; */

  return async function (dispatch) {
    dispatch(asyncActionStart());
    dispatch(clearOrders());

    serviceToken
      .get(url, { params: pageParams })
      .then(function (response) {
        // console.log('fetch order response', response);
        if (response.data.success === true) {
          const myOrdersList = response.data.result;
          const totalCount = response.data.total;
          const totalPages = response.data.total_page;
          const currentPage = response.data.current_page;
          const pageSize = response.data.page_size;

          //Get existing selected products
          const existingSelectedOrders = loadFromLocalStorage();
          //Enable button
          let showPayButton = true;
          let index = existingSelectedOrders.length;

          if (index !== 0) showPayButton = false;

          for (let i = 0; i < myOrdersList.length; i++) {
            myOrdersList[i].isSelected = false;

            //Check existing selected
            if (index !== -1) {
              const isSelected = existingSelectedOrders.findIndex(
                (p) => p == myOrdersList[i].order_id
              );

              if (isSelected === -1) myOrdersList[i].isSelected = false;
              else myOrdersList[i].isSelected = true;
            }
          }

          dispatch({
            type: FETCH_MY_ORDERS,
            payload: {
              myOrdersList,
              totalCount,
              totalPages,
              currentPage,
              pageSize,
              showPayButton
            }
          });
        }
        dispatch(asyncActionFinish());
      })
      .catch(function (error) {
        if (error.code === 'ECONNABORTED') {
          // network error
          toast.error('Network Error. Please try later');
        }
        console.error(error.data);
        dispatch(asyncActionError(error));
      });
  };
}

export function fetchMyOrderDetails(orderID, mode) {
  let url = API_URL + 'rp/orders/' + orderID;

  return async function (dispatch) {
    dispatch(asyncActionStart());
    serviceToken
      .get(url, {})
      .then(function (response) {
        // console.log('fetchMyOrderDetails response', response);
        if (response.data.success === true) {
          const myOrderDetails = response.data.result;

          dispatch({
            type: GET_MY_ORDER_DETAIL,
            payload: {
              myOrderDetails
            }
          });

          dispatch({
            type: SET_ORDER_HISTORY_STATE
          });
        }
        dispatch(asyncActionFinish());
      })
      .catch(function (error) {
        console.error(error.data);
        dispatch(asyncActionError(error));
      });
  };
}

export function fetchClientToken() {
  let url = API_URL + 'rp/paypal/client_token';

  return async function (dispatch) {
    dispatch(asyncActionStart());
    serviceToken
      .get(url, {})
      .then(function (response) {
      //  console.log('fetchClientToken response', response);
        if (response.data.success === true) {
          const clientToken = response.data.result.client_token;
          const tokenExpireIn = response.data.result.expires_in;
          
          dispatch({
            type: GET_CLIENT_TOKEN,
            payload: {
              clientToken,
              tokenExpireIn
            }
          });

          dispatch({
            type: SET_ORDER_HISTORY_STATE
          });
        }
        dispatch(asyncActionFinish());
      })
      .catch(function (error) {
        console.error(error.data);
        dispatch(asyncActionError(error));
      });
  };
}

export function getBundleDetails(orderIDs) {
  let url = API_URL + 'rp/bundles';
  let order_ids = {
    order_ids: orderIDs
  };
  let orderBundleDetails = null;
  console.log('getBundleDetails order_ids', order_ids);

  return async function (dispatch) {
    dispatch(asyncActionStart());
    //dispatch(clearOrders());
    dispatch({ type: CLEAR_ORDERS_BUNDLE_DETAILS });

    serviceToken
      .post(url, order_ids)
      .then(function (response) {
        // console.log('getBundleDetails response', response);
        if (response.data.success === true) {
          orderBundleDetails = response.data.result;
          // console.log('orderBundleDetails', orderBundleDetails);

          //let supplierIDs = [];
          let supplierItems = [];
          let shippingTotal = 0;
          for (let i = 0; i < orderBundleDetails.suppliers.length; i++) {
            orderBundleDetails.suppliers[i].isOpen = false;
            supplierItems = [];
            shippingTotal = 0;
            //supplierIDs.push(orderBundleDetails.suppliers[i].supplier_id);

            for (let ii = 0; ii < orderBundleDetails.orders.length; ii++) {
              for (
                let iii = 0;
                iii < orderBundleDetails.orders[ii].items.length;
                iii++
              ) {
                /*  console.log("items" , orderBundleDetails.orders[ii].items[iii].supplier_id , orderBundleDetails.suppliers[i].supplier_id); */
                if (
                  orderBundleDetails.orders[ii].items[iii].supplier_id ==
                  orderBundleDetails.suppliers[i].supplier_id
                ) {
                  //Order no
                  orderBundleDetails.orders[ii].items[iii].reference_number =
                    orderBundleDetails.orders[ii].reference_number;
                  //address
                  orderBundleDetails.orders[ii].items[
                    iii
                  ].address = addressString(orderBundleDetails.orders[ii]);
                  //shipping total
                  /* console.log("shippingTotal => ",orderBundleDetails.orders[ii].items[iii]
                  .shipping_fee); */
                  shippingTotal =
                    shippingTotal +
                    orderBundleDetails.orders[ii].items[iii].shipping_fee *
                      orderBundleDetails.orders[ii].items[iii].qty;
                  supplierItems.push(orderBundleDetails.orders[ii].items[iii]);
                }
              }
              orderBundleDetails.suppliers[i].reference_number =
                orderBundleDetails.orders[ii].reference_number;
            }
            //  console.log("shippingTotal out => ",shippingTotal);
            orderBundleDetails.suppliers[i].shippingSubTotal = shippingTotal;
            orderBundleDetails.suppliers[i].supplierItems = supplierItems;
          }

          //const r = orderBundleDetails.orders.filter(d => d.items.every(s => supplierIDs.includes(s.supplier_id)));
          //const r = orderBundleDetails.orders.filter(d => d.items.supplier_id == 160);
          //console.log('item list final', orderBundleDetails);

          dispatch({
            type: GET_ORDERS_BUNDLE_DETAILS,
            payload: { orderBundleDetails }
          });
          // clearSelectedOrders();
        } else {
          toast.error(response.data.message);
          //console.log('else message', response.data.message);
          dispatch({
            type: GET_ORDERS_BUNDLE_DETAILS,
            payload: { orderBundleDetails }
          });
        }
        dispatch(asyncActionFinish());
      })
      .catch(function (error) {
        console.error(error.data);
        dispatch(asyncActionError(error));
      });
  };
}

export function payOrderBundle(bundleID, paymentMethod) {
  let url = API_URL + 'rp/bundles/' + bundleID;
  let payment_method = {
    payment_method: paymentMethod
  };

  // console.log('payment_method', payment_method);

  return async function (dispatch) {
    dispatch(asyncActionStart());
    //dispatch(clearOrders());
    //dispatch({ type: CLEAR_ORDERS_BUNDLE_DETAILS });

    serviceToken
      .post(url, payment_method)
      .then(function (response) {
        console.log('payment ID response', response);
        if (response.data.success === true) {
          const paymentID = response.data.result.payment_id;
          //  console.log('orderBundleDetails', orderBundleDetails);

          dispatch({
            type: SET_BUNDLE_PAYMENT_ID,
            payload: paymentID
          });
          dispatch(clearSelectedOrders());
        } else {
          dispatch({ type: SET_PAY_POPUP, payload: false });
          toast.error(response.data.message);
        }
        dispatch(asyncActionFinish());
      })
      .catch(function (error) {
        console.error(error.data);
        dispatch(asyncActionError(error));
      });
  };
}

export function getCreditPoints() {
  let url = API_URL + 'rp/credit_points';

  return async function (dispatch) {
    dispatch(asyncActionStart());
    //dispatch(clearOrders());
    //dispatch({ type: CLEAR_ORDERS_BUNDLE_DETAILS });

    serviceToken
      .get(url)
      .then(function (response) {
        // console.log('getCreditPoints response', response);
        if (response.data.success === true) {
          const creditPoints = response.data.result.points;
          const creditPointsEnable = response.data.result.enable;
          //  console.log('orderBundleDetails', orderBundleDetails);

          dispatch({
            type: GET_CREDIT_POINTS,
            payload: { creditPoints, creditPointsEnable }
          });
        } else {
          toast.error(response.data.message);
        }
        dispatch(asyncActionFinish());
      })
      .catch(function (error) {
        console.error(error.data);
        dispatch(asyncActionError(error));
      });
  };
}

export function setBundlePaymentType(value) {
  return function (dispatch) {
    dispatch({ type: SET_BUNDLE_PAYMENT_TYPE, payload: value });
  };
}

export function setOrderStatus(searchOrderStatus, activeTab) {
  return function (dispatch) {
    dispatch({
      type: SET_ORDER_STATUS,
      payload: { searchOrderStatus, activeTab }
    });
  };
}

function addressString(order) {
  var address = order.address_line1;
  if (order.address_line2) address = address + ' ' + order.address_line2;
  address =
    address +
    ' ' +
    order.suburb +
    ' ' +
    order.state +
    ' ' +
    order.postcode +
    ' ' +
    order.country +
    ' T: ' +
    order.phone;
  return address;
}

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('myselunp');
    //console.log("serializedState",serializedState);
    if (!serializedState) return [];
    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
    return [];
  }
}

export function setSelectedProductID(value) {
  return function (dispatch) {
    /* dispatch(clearSelectedProduct());
    dispatch(clearOrders()); */
    dispatch({ type: SET_MY_SELECTED_PRODUCT_ID, payload: value });
  };
}

export function setSelectOrders(id, isSelectAllMode, isSelectAll) {
  return function (dispatch) {
    dispatch({
      type: SET_SELECT_MY_ORDERS,
      payload: { id, isSelectAllMode, isSelectAll }
    });
  };
}

export function setCurrentPage(value) {
  return function (dispatch) {
    dispatch(clearOrders());
    dispatch({ type: SET_MY_ORDERS_PAGE_NO, payload: value });
  };
}

export function setPageSize(value) {
  return function (dispatch) {
    dispatch(clearOrders());
    dispatch({ type: SET_MY_ORDERS_PAGE_SIZE, payload: value });
  };
}

export function setSearchKeywards(value) {
  return function (dispatch) {
    //dispatch(clearOrders());
    dispatch({ type: SET_MY_ORDERS_SEARCH_KEYWORDS, payload: value });
  };
}
//CLEAR_REMOVE_ORDERS_RESPONSE
export function setRemoveProductsResponse() {
  return function (dispatch) {
    //dispatch(clearOrders());
    dispatch({ type: CLEAR_REMOVE_ORDERS_RESPONSE });
  };
}
export function setSearchBindedProducts(value) {
  return function (dispatch) {
    dispatch(clearOrders());
    dispatch({ type: SET_SEARCH_BINDED_ORDERS, payload: value });
  };
}

export function setSearchUnbindedProducts(value) {
  return function (dispatch) {
    dispatch(clearOrders());
    dispatch({ type: SET_SEARCH_UNBINDED_ORDERS, payload: value });
  };
}

export function setFreeShipping(value) {
  return function (dispatch) {
    dispatch(clearOrders());
    dispatch({ type: SET_FREE_SHIPPING_MY_PRODUCT, payload: value });
  };
}

export function setExtraShipping(value) {
  return function (dispatch) {
    dispatch(clearOrders());
    dispatch({ type: SET_EXTRA_SHIPPING_MY_PRODUCT, payload: value });
  };
}

export function setGoneFromSupplier(value) {
  return function (dispatch) {
    dispatch(clearOrders());
    dispatch({ type: SET_GONE_FROM_SUPPLIER, payload: value });
  };
}

export function setBelowDszPrice(value) {
  return function (dispatch) {
    dispatch(clearOrders());
    dispatch({ type: SET_BELOW_DSZ_PRICE, payload: value });
  };
}

export function setUpdateProductResponseView(value) {
  return function (dispatch) {
    dispatch({ type: SET_UPDATE_PRODUCT_RESPONSE_VIEW, payload: value });
  };
}
export function clearOrders() {
  return {
    type: CLEAR_MY_ORDERS
  };
}

export function clearSelectedProduct() {
  return {
    type: CLEAR_MY_SELECTED_PRODUCT
  };
}

export function clearSelectedOrders() {
  localStorage.setItem('myselunp', '');
  return function (dispatch) {
    dispatch({ type: CLEAR_MY_SELECTED_UNPAIDORDERS });
    dispatch(asyncActionFinish());
  };
}
