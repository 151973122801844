import {
    GET_CLIENT_TOKEN,
    SET_CLIENT_TOKEN,
    GET_CREDIT_POINTS,

    GET_ORDERS_BUNDLE_DETAILS_HISTORY,
    CLEAR_ORDERS_BUNDLE_DETAILS_HISTORY,

    SET_ACTIVE_METHOD,
    SET_PAY_CREDITCARD_SUBMIT,
    SET_PAY_WALLET_SUBMIT,
    SET_PAY_PAYPAL_SUBMIT,

    SET_PAY_LOADING,
    IS_CREDITCARD_RESOLVED,

    SET_CREDITCARD_VALID

} from './paymentConstants'

const initialState = {
    clientToken: '',
    isClientToken: false,
    tokenExpireIn: '',

    creditPoints: 0,
    creditPointsEnable: false,

    orderBundleDetails: null,


    activeMethod: 0, // 0 creditCard  1 wallet  2 paypal
    isCreditCardSubmit: false,
    isWalletPaySubmit: false,
    isPaypalSubmit: false,

    pageLoading: false,
    isCreditcardResolved: false,

    creditCardValid: {
        number: null,
        expirationDate: null,
        cvv: null,
        name: null
    }
}

export default function PaymentRedurce(
    state = initialState,
    { type, payload }
) {
    switch (type) {
        case GET_CLIENT_TOKEN:
            return {
                ...state,
                clientToken: payload.clientToken,
                tokenExpireIn: payload.tokenExpireIn,
                isClientToken: true
            };
        case SET_CLIENT_TOKEN:
            return {
                ...state,
                isClientToken: payload
            };
        case GET_CREDIT_POINTS:
            return {
                ...state,
                creditPoints: payload.creditPoints,
                creditPointsEnable: payload.creditPointsEnable
            };
        case GET_ORDERS_BUNDLE_DETAILS_HISTORY:
            return {
                ...state,
                orderBundleDetails: payload.orderBundleDetails
            };
        case CLEAR_ORDERS_BUNDLE_DETAILS_HISTORY:
            return {
                ...state,
                orderBundleDetails: null
            };
        case SET_ACTIVE_METHOD:
            return {
                ...state,
                activeMethod: payload.activeMethod
            };
        case SET_PAY_CREDITCARD_SUBMIT:
            return {
                ...state,
                isCreditCardSubmit: payload.isCreditCardSubmit
            };
        case SET_PAY_WALLET_SUBMIT:
            return {
                ...state,
                isWalletPaySubmit: payload.isWalletPaySubmit
            };
        case SET_PAY_PAYPAL_SUBMIT:
            return {
                ...state,
                isPaypalSubmit: payload.isPaypalSubmit
            };

        case SET_PAY_LOADING:
            return {
                ...state,
                payLoading: payload.payLoading
            }
        case IS_CREDITCARD_RESOLVED:
            return {
                ...state,
                isCreditcardResolved: payload.isCreditcardResolved
            }
        case SET_CREDITCARD_VALID:
            return {
                ...state,
                creditCardValid: payload.creditCardValid
            }
        default:
            return state;
    }
}