export const GET_CLIENT_TOKEN = 'GET_CLIENT_TOKEN';
export const SET_CLIENT_TOKEN = 'SET_CLIENT_TOKEN';
export const GET_CREDIT_POINTS = 'GET_CREDIT_POINTS';


export const GET_ORDERS_BUNDLE_DETAILS_HISTORY = 'GET_ORDERS_BUNDLE_DETAILS_HISTORY';
export const CLEAR_ORDERS_BUNDLE_DETAILS_HISTORY = 'CLEAR_ORDERS_BUNDLE_DETAILS_HISTORY'



export const SET_ACTIVE_METHOD = 'SET_ACTIVE_METHOD'
export const SET_PAY_CREDITCARD_SUBMIT = 'SET_PAY_CREDITCARD_SUBMIT'
export const SET_PAY_WALLET_SUBMIT = 'SET_PAY_WALLET_SUBMIT';
export const SET_PAY_PAYPAL_SUBMIT = 'SET_PAY_PAYPAL_SUBMIT';


export const SET_PAY_LOADING = 'SET_PAY_LOADING'

export const IS_CREDITCARD_RESOLVED = 'IS_CREDITCARD_RESOLVED'

export const SET_CREDITCARD_VALID = 'SET_CREDITCARD_VALID'
