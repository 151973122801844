export const FETCH_STORE_PRODUCT_COUNT_UPDATE = 'FETCH_STORE_PRODUCT_COUNT_UPDATE';
export const FETCH_UPDATE_REQUIRED_COUNT_UPDATE = 'FETCH_UPDATE_REQUIRED_COUNT_UPDATE';
export const FETCH_PRODUCTS_INFO_UPDATE = 'FETCH_PRODUCTS_INFO_UPDATE';
export const CLEAR_PRODUCTS_INFO = 'CLEAR_PRODUCTS_INFO';
export const PRODUCTS_INFO_RETAIN_STATE = 'PRODUCTS_INFO_RETAIN_STATE';
export const PRODUCTS_INFO_SET_PAGE_SIZE = 'PRODUCTS_INFO_SET_PAGE_SIZE';
export const PRODUCTS_INFO_SET_PAGE_NO = 'PRODUCTS_INFO_SET_PAGE_NO';
export const PRODUCTS_INFO_SET_SORT_PARAMS = 'PRODUCTS_INFO_SET_SORT_PARAMS';
export const PRODUCTS_INFO_SET_ALL_RECORDS = 'PRODUCTS_INFO_SET_ALL_RECORDS';
export const PRODUCTS_INFO_SET_ALL_RECORDS_LIST = 'PRODUCTS_INFO_SET_ALL_RECORDS_LIST';
export const CLEAR_SELECTED_PRODUCTS_INFO = 'CLEAR_SELECTED_PRODUCTS_INFO'
