export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const UPDATE_PRICING_RULES = 'UPDATE_PRICING_RULES';
export const RETAIN_SETTINGS_STATE = 'RETAIN_SETTINGS_STATE';
export const SET_SHIPPING_RULE = 'SET_SHIPPING_RULE';
export const SET_STOCK_RULE = 'SET_STOCK_RULE';
export const SET_ORDER_RULE = 'SET_ORDER_RULE';
export const SET_PRICING_RULE_LIST = 'SET_PRICING_RULE_LIST';
export const CLEAR_PRICING_RULE_LIST = 'CLEAR_PRICING_RULE_LIST';
export const SET_FREE_SHIPPING_TAG = 'SET_FREE_SHIPPING_TAG';
export const SET_AUTO_FULLFILL_ORDER = 'SET_AUTO_FULLFILL_ORDER';
export const SET_IS_BRAND_USER = 'SET_IS_BRAND_USER';
export const SET_AUTO_UPDATE_PRICE = 'SET_AUTO_UPDATE_PRICE';
