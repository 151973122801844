import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import './../../fonts/SCTO_GROTESK_A/Scto-Grotesk-A-Regular.ttf';
import './../../fonts/caslon_ionic/CaslonIonic-Regular.otf';
import './fontStyle.css';
//import GetLegacyStyle from "./getLegacyStyle";
//import GetCurrentStyle from "./getCuurentStyle";

export default function GetCommonStyle() {
  const { showFeatureItems5 } = useSelector((state) => state.ThemeOptions);

  useEffect(() => {
    //console.log("useEffect => GetLegacyStyle", showFeatureItems5);
    if (showFeatureItems5) import('../common/getCuurentStyle');
    else import('../common/getLegacyStyle');
    //checkTheme();
  }, [showFeatureItems5]);

  //const B = showFeatureItems5 && import('../common/getLegacyStyle');

  return <></>;
}
