import {
  FETCH_INVENTORY_UPDATES,
  INVENTORY_UPDATE_SET_PAGE_NO,
  INVENTORY_UPDATE_SET_PAGE_SIZE,
  CLEAR_INVENTORY_UPDATE
} from './inventoryUpdateConstants';

const initialState = {
  inventoryUpdates: [],
  totalCount: 3,
  currentPage: 1,
  pageSize: 10
};

export default function inventoryUpdateReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FETCH_INVENTORY_UPDATES:
      return {
        ...state,
        inventoryUpdates: payload.productsInfoUpdate,
        totalCount: payload.totalCount,
        currentPage: payload.currentPage
      };
      case CLEAR_INVENTORY_UPDATE:
        return {
          ...state,
          inventoryUpdates: [],
          currentPage: 1
        };

    case INVENTORY_UPDATE_SET_PAGE_SIZE:
      return {
        ...state,
        retainState: false,
        pageSize: payload
      };

    case INVENTORY_UPDATE_SET_PAGE_NO:
      return {
        ...state,
        retainState: false,
        currentPage: payload
      };

    default:
      return state;
  }
}
