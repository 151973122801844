export const FETCH_MY_ORDERS_HISTORY = 'FETCH_MY_ORDERS_HISTORY';
export const CLEAR_MY_ORDERS_HISTORY = 'CLEAR_MY_ORDERS_HISTORY';
export const RETAIN_MY_ORDERS_STATE_HISTORY = 'RETAIN_MY_ORDERS_STATE_HISTORY';
export const SET_MY_ORDERS_PAGE_SIZE_HISTORY = 'SET_MY_ORDERS_PAGE_SIZE_HISTORY';
export const SET_MY_ORDERS_PAGE_NO_HISTORY = 'SET_MY_ORDERS_PAGE_NO_HISTORY';

export const SET_MY_ORDERS_SEARCH_KEYWORDS_HISTORY = 'SET_MY_ORDERS_SEARCH_KEYWORDS_HISTORY';
export const SET_SEARCH_BINDED_ORDERS_HISTORY = 'SET_SEARCH_BINDED_ORDERS_HISTORY';
export const SET_SEARCH_UNBINDED_ORDERS_HISTORY = 'SET_SEARCH_UNBINDED_ORDERS_HISTORY';

export const GET_MY_ORDER_DETAIL_HISTORY = 'GET_MY_ORDER_DETAIL_HISTORY';
export const SET_MY_SELECTED_PRODUCT_ID_HISTORY = 'SET_MY_SELECTED_PRODUCT_ID_HISTORY';
export const CLEAR_MY_SELECTED_PRODUCT_HISTORY= 'CLEAR_MY_SELECTED_PRODUCT_HISTORY';

export const CLEAR_MY_SELECTED_ORDERS_HISTORY = 'CLEAR_MY_SELECTED_ORDERS_HISTORY';
export const SET_UPDATE_PRODUCT_RESPONSE_VIEW_HISTORY = 'SET_UPDATE_PRODUCT_RESPONSE_VIEW_HISTORY';
export const SET_SELECT_MY_ORDERS_HISTORY = 'SET_SELECT_MY_ORDERS_HISTORY';
export const UPDATE_ORDERS_LIST_HISTORY = 'UPDATE_ORDERS_LIST_HISTORY';
export const SET_FREE_SHIPPING_MY_PRODUCT_HISTORY = 'SET_FREE_SHIPPING_MY_PRODUCT_HISTORY';
export const SET_EXTRA_SHIPPING_MY_PRODUCT_HISTORY = 'SET_EXTRA_SHIPPING_MY_PRODUCT_HISTORY';
export const SET_GONE_FROM_SUPPLIER_HISTORY = 'SET_GONE_FROM_SUPPLIER_HISTORY';
export const SET_BELOW_DSZ_PRICE_HISTORY = 'SET_BELOW_DSZ_PRICE_HISTORY';
export const REMOVE_ORDERS_LIST_HISTORY = 'REMOVE_ORDERS_LIST_HISTORY';
export const CLEAR_REMOVE_ORDERS_RESPONSE_HISTORY = 'CLEAR_REMOVE_ORDERS_RESPONSE_HISTORY';
export const RETAIN_MY_ORDERS_DETAILS_STATE_HISTORY = 'RETAIN_MY_ORDERS_DETAILS_STATE_HISTORY';
export const CLEAR_MY_ORDER_DETAILS_HISTORY = 'CLEAR_MY_ORDER_DETAILS_HISTORY';
export const RETAIN_ORDERS_BUNDLE_STATE_HISTORY = 'RETAIN_ORDERS_BUNDLE_STATE_HISTORY';
export const GET_ORDERS_BUNDLE_DETAILS_HISTORY = 'GET_ORDERS_BUNDLE_DETAILS_HISTORY';
export const CLEAR_ORDERS_BUNDLE_DETAILS_HISTORY = 'CLEAR_ORDERS_BUNDLE_DETAILS_HISTORY';
export const SET_BUNDLE_PAYMENT_TYPE_HISTORY = 'SET_BUNDLE_PAYMENT_TYPE_HISTORY';
export const SET_BUNDLE_PAYMENT_ID_HISTORY = 'SET_BUNDLE_PAYMENT_ID_HISTORY';
export const SET_ORDER_STATUS_HISTORY = 'SET_ORDER_STATUS_HISTORY';
export const SET_ORDER_HISTORY_STATE = 'SET_ORDER_HISTORY_STATE';