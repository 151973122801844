import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import styles from './TaskLoadingButton.module.css';
import qs from 'qs';

import {
  setProductImportProgressView,
  setProductImportLoadingView,
  fetchProductImportProgress,
  dismissProductImportProgress
} from '../productActions';
import {
  setPriceUpdateProgressView,
  setPriceUpdateLoadingView,
  fetchPriceUpdateProgress,
  dismissPriceUpdateProgress
} from '../ProductPriceUpdate/productPriceActions';
import {
  setSpecialPriceUpdateProgressView,
  setSpecialPriceUpdateLoadingView,
  fetchSpecialPriceUpdateProgress,
  dismissSpecialPriceUpdateProgress
} from '../SpecialPriceUpdate/specialPriceActions';

import { getUserProfile } from '../../myAccount/profileActions';

import { setFeatureFlag } from '../../../reducers/ThemeOptions';

export default function TaskLoadingButton({ limitRoutes = [], children }) {
  const dispatch = useDispatch();
  const { authenticated } = useSelector((state) => state.auth);
  const {
    isAutoSync
  } = useSelector((state) => state.ThemeOptions);
  const isShopifyUser = localStorage.getItem('isShopifyUser')=='true' ? true : false;
  let show = false;
  let isLogoutPage = false
  if(window.location.pathname.toLowerCase() =='/logout'){
    isLogoutPage = true;
  }
  const location = useLocation();
  if (!location || !location.pathname) {
    show = false;
  } else {
    if (limitRoutes && limitRoutes.length) {
      for (let route of limitRoutes) {
        if (!route.startsWith('/')) {
          route = '/' + route;
        }
        if (route.toUpperCase() === location.pathname.toUpperCase()) {
          show = true;
          break;
        }
      }
    } else {
      show = true;
    }
  }

  const { isProductImportRunning, isProductImportLoadingShow } = useSelector(
    (state) => state.productCatalogue
  );
  const { isPriceUpdateRunning, isPriceUpdateLoadingShow } = useSelector(
    (state) => state.productPrice
  );
  const {
    isPriceUpdateRunning: isSpecialPriceUpdateRunning,
    isPriceUpdateLoadingShow: isSpecialPriceUpdateLoadingShow
  } = useSelector((state) => state.specialPrice);

  const handleClick = () => {
    dispatch(setProductImportProgressView(true));
    dispatch(setProductImportLoadingView(false));
    dispatch(fetchProductImportProgress(true));

    dispatch(setPriceUpdateProgressView(true));
    dispatch(setPriceUpdateLoadingView(false));
    dispatch(fetchPriceUpdateProgress(true));

    dispatch(setSpecialPriceUpdateProgressView(true));
    dispatch(setSpecialPriceUpdateLoadingView(false));
    dispatch(fetchSpecialPriceUpdateProgress(true));
  };


  useEffect(() => {
   // console.log('useEffect 1 => ', showFeatureItems3);
    if (authenticated && isAutoSync === false && !isLogoutPage && isShopifyUser) {
      setTimeout(() => {
        if (authenticated) {
         // console.log('useEffect 1  timer => ', showFeatureItems3);
          dispatch(fetchProductImportProgress(true));
          dispatch(fetchPriceUpdateProgress(true));
          dispatch(fetchSpecialPriceUpdateProgress(true));
        }
      }, 2000);
    }
  }, [dispatch, authenticated, location.pathname, isAutoSync]);

  const productImportLoading =
    isProductImportRunning && isProductImportLoadingShow;
  const priceUpdateLoading = isPriceUpdateRunning && isPriceUpdateLoadingShow;
  const specialPriceUpdateLoading =
    isSpecialPriceUpdateRunning && isSpecialPriceUpdateLoadingShow;

  useEffect(() => {
    //console.log('useEffect 2 => ', checkFeatureFlag());
    if (authenticated && isAutoSync === false && !isLogoutPage && isShopifyUser) {
      if (
        productImportLoading ||
        priceUpdateLoading ||
        specialPriceUpdateLoading
      ) {
        dismissPriceUpdateProgress();
        dismissProductImportProgress();
        dismissSpecialPriceUpdateProgress();
      }
    }
  }, [
    dispatch,
    productImportLoading,
    priceUpdateLoading,
    specialPriceUpdateLoading,
    isAutoSync
  ]);

  if (
    !isAutoSync &&
    show &&
    (productImportLoading || priceUpdateLoading || specialPriceUpdateLoading) && isShopifyUser
  ) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <Button
            color="info"
            className={`btn-spinner ${styles.btn}`}
            onClick={handleClick}>
            <span
              className="btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"></span>
          </Button>
          {children}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
