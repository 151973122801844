import React from 'react';
import { useSelector } from 'react-redux';
import { Progress } from 'reactstrap';
import styles from './ProductPriceUpdate.module.css';

export default function PriceUpdateProgress({}) {
  const { priceUpdateProgress = {} } = useSelector(
    (state) => state.productPrice
  );

  const { all = 0, success = 0 } = priceUpdateProgress;
  let progress;
  if (all <= 0 || success <= 0) {
    progress = 1;
  } else {
    progress = Math.floor((success / all) * 100);
  }

  return (
    <>
      <div className={styles.importTaskHeader}>
        Price Update Progress&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <div className="d-block mt-1 mb-1 ml-1">
        <Progress
          value={progress}
          className="progress-bar-rounded progress-animated-alt"
          color="info">{`${progress}%`}</Progress>
      </div>
      <div
        className={
          styles.importTaskInfo
        }>{`All: ${all}, Success: ${success}`}</div>
    </>
  );
}
