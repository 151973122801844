import {
  FETCH_SPECIAL_PRICE_UPDATES,
  SPECIAL_PRICE_SET_SORT_PARAMS
} from './specialPriceConstants';
import {
  SPECIAL_PRICE_RETAIN_STATE,
  SPECIAL_PRICE_SET_PAGE_NO,
  SPECIAL_PRICE_SET_PAGE_SIZE,
  SET_SPECIAL_PRICE_UPDATE_LOADING_VIEW,
  SET_SPECIAL_PRICE_UPDATE_PROGRESS_VIEW,
  FETCH_SPECIAL_PRICE_UPDATE_PROGRESS,
  CLEAR_SPECIAL_PRICE_UPDATES
} from './specialPriceConstants';

const initialState = {
  specialPriceUpdates: [],
  retainStateSpecialPrice: false,
  totalCount: 0,
  totalPages: 0,
  currentPage: 1,
  pageSize: 10,
  sortParams: [],
  priceUpdateProgress: {},
  isPriceUpdateRunning: false,
  isPriceUpdateProgressShow: false,
  isPriceUpdateLoadingShow: false
};

export default function productPriceReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_SPECIAL_PRICE_UPDATE_LOADING_VIEW:
      return {
        ...state,
        isPriceUpdateLoadingShow: !!payload.value
      };
    case SET_SPECIAL_PRICE_UPDATE_PROGRESS_VIEW:
      return {
        ...state,
        isPriceUpdateProgressShow: !!payload.value
      };
    case FETCH_SPECIAL_PRICE_UPDATE_PROGRESS:
      return {
        ...state,
        isPriceUpdateRunning: payload.isPriceUpdateRunning || false,
        priceUpdateProgress: payload.priceUpdateProgress || {}
      };
    case FETCH_SPECIAL_PRICE_UPDATES:
      return {
        ...state,
        specialPriceUpdates: payload.productsInfoUpdate,
        totalCount: payload.totalCount,
        totalPages: payload.totalPages,
        currentPage: payload.currentPage
      };
      case CLEAR_SPECIAL_PRICE_UPDATES:
        return {
          ...state,
          specialPriceUpdates: [],
          currentPage: 1
        };
    case SPECIAL_PRICE_SET_PAGE_SIZE:
      return {
        ...state,
        retainStateSpecialPrice: false,
        pageSize: payload
      };

    case SPECIAL_PRICE_SET_PAGE_NO:
      return {
        ...state,
        retainStateSpecialPrice: false,
        currentPage: payload
      };

    case SPECIAL_PRICE_SET_SORT_PARAMS:
      return {
        ...state,
        sortParams: payload
      };

    case SPECIAL_PRICE_RETAIN_STATE:
      return {
        ...state,
        retainStateSpecialPrice: true
      };

    default:
      return state;
  }
}
