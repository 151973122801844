import {
  SET_PAGE_LOADING,
  GET_ORDER_DETAIL,
  CLEAR_ORDER_DETAIL
} from './orderDetailConstants';

const initialState = {
  pageLoading: false,

  orderDetail: null
};

export default function orderDetailReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_PAGE_LOADING:
      return {
        ...state,
        pageLoading: payload.isLoading
      };
    case GET_ORDER_DETAIL:
      return {
        ...state,
        orderDetail: payload
      };
    case CLEAR_ORDER_DETAIL:
      return {
        ...state,
        orderDetail: null
      };
    default:
      return state;
  }
}
