import {
  FETCH_PRODUCT_CATEGORIES,
  SET_CATEGORY_ID,
  FETCH_PRODUCTS,
  CLEAR_PRODUCTS,
  SET_PAGE_SIZE,
  SET_PAGE_NO,
  SET_SEARCH_KEYWORDS,
  SET_FREE_SHIPPING,
  SET_EXTRA_SHIPPING,
  SORT_BY_FREESHIPPING,
  GET_PRODUCT_DETAIL,
  SET_SELECTED_PRODUCT_ID,
  CLEAR_SELECTED_PRODUCT,
  SET_IMPORT_PRODUCT,
  IMPORT_PRODUCTS_LIST,
  CLEAR_SELECTED_PRODUCTS,
  SET_IMPORT_PRODUCT_RESPONSE_VIEW,
  CLEAR_PRODUCTS_IMPORT_RESPONSE,
  SET_SEARCH_KEYWORDS_CLEAR,
  FETCH_PRODUCT_IMPORT_TASK,
  SET_PRODUCT_IMPORT_PROGRESS_VIEW,
  SET_PRODUCT_IMPORT_LOADING_VIEW,
  SET_PRODUCT_FILTER_BY,
  SET_SORT_BY_PRODUCTS
} from './productConstants';

import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from '../../reducers/asyncReducer';

import service from '../../services/request';

import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProductImportProgress from './ProductCatalogue/productImportProgress';
import serviceToken from '../../services/requestToken';
const productImportToastId = 'product-import-task';
const productImportFinishId = 'product-import-finish';
const othersCategoryName = 'others';

export function fetchProductCategories() {
  let url = 'api/products/category';
  let categories = [];
  let pageParams = {};

  return async function (dispatch) {
    dispatch(asyncActionStart());

    service
      .get(url, { params: pageParams })
      .then(function (response) {
        // console.log('response -> category ->', response);
        if (response.data.status === true) {
          let getchildren = function (data) {
            for (let i = 0; i < data.length; i++) {
              const category = {
                category_id: data[i].category_id,
                name: data[i].name,
                parent_id: data[i].parent_id,
                show:
                  data[i].is_active === '1' && data[i].include_in_menu === true,
                menu:
                  data[i].include_in_menu &&
                  data[i].is_anchor === '1' &&
                  data[i].parent_id === 2,
                children: data[i].children
              };
              categories.push(category);

              if (data[i].children && data[i].children.length) {
                // Make others category last
                const children = (data[i].children || []).sort((x, y) => {
                  const xName = (x.name || '').trim().toLowerCase();
                  const yName = (y.name || '').trim().toLowerCase();
                  return xName === othersCategoryName
                    ? 1
                    : yName === othersCategoryName
                      ? -1
                      : 0;
                });
                getchildren(children);
              } else {
                // When category children empty, add a menu for parent category
                if (category.show && category.menu) {
                  categories.push({
                    category_id: category.category_id,
                    name: category.name,
                    parent_id: category.category_id,
                    show: true,
                    menu: false
                  });
                }
              }
            }
          };

          let data = response.data.data;
          getchildren(data);
          // console.log('responseEnd -> category ->', categories);
          dispatch({ type: FETCH_PRODUCT_CATEGORIES, payload: { categories } });
          dispatch(asyncActionFinish());
        }
      })
      .catch(function (error) {
        //  console.error(error.data);
        dispatch(asyncActionError(error));
      });
  };
}

export function fetchV2ProductCategories() {
  const TAXONOMY_ROOT_CATEGORY_ID = process.env.REACT_APP_TAXONOMY_ROOT_CATEGORY_ID
  const API_URL = process.env.REACT_APP_API_URL_DSZ
  let url = `${API_URL}v2/categories`;
  let categories = []

  return async function (dispatch) {
    dispatch(asyncActionStart());

    serviceToken.get(url).then(function (res) {
      if (res.status === 200 && res.data) {
        const arr = []
        let categoryList = JSON.parse(res.data);
        categoryList = categoryList.filter((item) => { return item.category_id.toString() !== TAXONOMY_ROOT_CATEGORY_ID && item.title !== 'Promotion' && item.title !== "New Arrivals" })
        for (let i = 0; i < categoryList.length; i++) {
          let item = categoryList[i];
          arr[i] = {
            category_id: item["category_id"],
            name: item["title"],
            parent_id: item["parent_id"],
            path: item["path"],
            show: item.is_active == 1 && item.include_in_menu == true,
            menu:
              item.include_in_menu &&
              item.is_anchor == 1 &&
              item.parent_id == TAXONOMY_ROOT_CATEGORY_ID,
          }
        }
        //按分类名称字母顺序排序
        arr.sort(sortBy("name"));
        categories = tree(arr);
      }
      dispatch({ type: FETCH_PRODUCT_CATEGORIES, payload: { categories } });
      dispatch(asyncActionFinish());
    })
      .catch(function (error) {
        dispatch(asyncActionError(error));
      })
  }
}

function tree(data) {
  let map = {};
  data.forEach(function (item) {
    map[item.category_id] = item;
  });
  let val = [];
  data.forEach(function (item) {
    let parent = map[item.parent_id];
    if (parent) {
      (parent.children || (parent.children = [])).push(item);
    } else {
      val.push(item);
    }
  });
  return val;
}

function sortBy(name) {
  return function (o, p) {
    let a, b;
    if (typeof o === "object" && typeof p === "object" && o && p) {
      a = o[name];
      b = p[name];
      if (a === b) {
        return 0;
      }
      if (typeof a === typeof b) {
        return a < b ? -1 : 1;
      }
      return typeof a < typeof b ? -1 : 1;
    }
  };
}

export function fetchProducts(
  pageNo,
  pageSize,
  selectedCategoryID,
  searchKeywords,
  isFreeShipping,
  isExtraShipping,
  filterBy,
  sortBy,
  categoryLevel,
  taxonomyFeatureFlag
) {
  let url = 'api/products/search';
  let pageParams = {
    page_no: pageNo,
    limit: pageSize,
    category_id: selectedCategoryID,
    keywords: searchKeywords,
    sort_by: sortBy
  };
  if (taxonomyFeatureFlag == true && filterBy !== 'not_on_my_sku') {
    url = 'api/v2/products/search';
    pageParams.category_level = categoryLevel;
  }
  if (taxonomyFeatureFlag == true && filterBy == 'not_on_my_sku') {
    pageParams.category_level = categoryLevel;
    pageParams.tff = true;
  }
  if (taxonomyFeatureFlag == false && selectedCategoryID == 'NEW_ARRIVAL') {
    pageParams.category_id = 191
  }
  //console.log('fetchProducts');
  //Set free shipping paramenter
  if (isFreeShipping && !isExtraShipping) {
    let setFreeShippingValue = isFreeShipping ? 1 : 0;
    // console.log('Free Shipping 1', isFreeShipping, setFreeShippingValue);
    pageParams.freeshipping = setFreeShippingValue;
  } else if (!isFreeShipping && isExtraShipping) {
    let setFreeShippingValue = isExtraShipping ? 0 : 1;
    // console.log('Extra Shipping 2', isFreeShipping, setFreeShippingValue);
    pageParams.freeshipping = setFreeShippingValue;
  }

  // Set filter_by
  if (filterBy) {
    pageParams.filter_by = filterBy;
  }

  //console.log('Product Action ->', searchKeywords, pageNo);
  // console.log('pageParams ->', pageParams);
  return async function (dispatch) {
    dispatch(asyncActionStart());
    dispatch(clearProducts());
    service
      .get(url, { params: pageParams })
      .then(function (response) {
        //  console.log('response ->', response);
        if (response.data.status === true) {
          const totalCount = response.data.data.total_count;
          const totalPages = response.data.data.total_pages;
          const currentPage = response.data.data.cur_page;

          //Get existing selected products
          const existingSelectedProducts = loadFromLocalStorage();
          //console.log("existingSelectedProducts",existingSelectedProducts);
          const products = response.data.data.data;
          for (let i = 0; i < products.length; i++) {
            if (products[i].is_import === 0 && products[i].is_product === 0) {
              products[i].add = true;
            } else {
              products[i].add = false;
            }
            //Free shipping
            if (products[i].freeshipping === '0') {
              products[i].freeshipping = false;
            } else {
              products[i].freeshipping = true;
            }
            //Is new arrival
            if (selectedCategoryID === 191 || selectedCategoryID == 'NEW_ARRIVAL') {
              products[i].isNew = true;
            } else {
              products[i].isNew = false;
            }
            //Add extended property isSelected
            const isSelected = existingSelectedProducts.findIndex(
              (p) => p.product_id == products[i].product_id
            );

            if (isSelected === -1) products[i].isSelected = false;
            else products[i].isSelected = true;

            if (products[i].qty > 0) products[i].isInStock = true;
            else products[i].isInStock = false;
          }

          //Enable button
          let showProductImportbButton = true;
          let index = existingSelectedProducts.findIndex(
            (p) => p.isSelected === true
          );

          if (index !== -1) showProductImportbButton = false;

          //  console.log('responseEnd -> products ->', products);
          dispatch({
            type: FETCH_PRODUCTS,
            payload: {
              products,
              totalCount,
              totalPages,
              currentPage,
              showProductImportbButton
            }
          });
          dispatch(asyncActionFinish());
        }
      })
      .catch(function (error) {
        //  console.error(error.data);
        dispatch(asyncActionError(error));
      });
  };
}

export function importProductsList() {
  let url = 'api/products/import/importtostorebyproductids';

  //Get existing selected products
  const existingSelectedProducts = loadFromLocalStorage();

  var productIDsString = existingSelectedProducts
    .map(function (p) {
      return p.product_id;
    })
    .join(',');

  // let pageParams = {
  //   product_ids: productIDsString
  // };

  return async function (dispatch) {
    dispatch(asyncActionStart());
    dispatch(clearProductImportToResponse());
    //await delay(1000);
    service
      .post(url, { product_ids: productIDsString })
      .then(function (response) {
        /*    console.log(
              'response -> importProductsList ->',
              response.data.data.status
            ); */

        if (response.data.data.status === true) {
          // const { task_id } = response.data.data;

          dispatch({
            type: IMPORT_PRODUCTS_LIST
            // payload: { productImportTaskId: task_id }
          });
          dispatch(asyncActionFinish());
          //Clear local storage
          localStorage.setItem('selectedProduct', '');
          dispatch(clearSelectedProducts());
          dispatch(clearProducts());
          //Unselect products
          dispatch(setImportProduct(0, true, false, false));
        } else if (response.data.data.status === false) {
          dispatch(asyncActionFinish());
          if (response.data.data.result) {
            toast.error(response.data.data.result, {
              toastId: 'productImportFinishError',
              autoClose: 8000
            });
          }
        }
      })
      .catch(function (error) {
        console.error(error.data);
        dispatch(asyncActionError(error));
      });
  };
}

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('selectedProduct');
    if (serializedState === null) return [];
    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
    return [];
  }
}

export function getProductDetail(id) {
  let url = 'api/products/view';
  let pageParams = {
    product_id: id
  };
  return async function (dispatch) {
    dispatch(asyncActionStart());

    service
      .get(url, { params: pageParams })
      .then(function (response) {
        if (response.data.status === true) {
          const productDetail = response.data.data;

          /* console.log(
            'response -> getProductDetail ->',
            id,
            productDetail.freeshipping
          ); */

          //Shipping fields
          if (productDetail.freeshipping === '0') {
            productDetail.freeshipping = false;
            productDetail.freeshippingtext = 'Extra Shipping Cost';
          } else {
            productDetail.freeshipping = true;
            productDetail.freeshippingtext = 'Free AU Shipping';
          }

          if (productDetail.qty > 0) productDetail.isInStock = true;
          else productDetail.isInStock = false;

          //Add extended property isSelected
          //Get existing selected products
          const existingSelectedProducts = loadFromLocalStorage();

          const isSelected = existingSelectedProducts.findIndex(
            (p) => p.product_id == id
          );

          if (parseInt(isSelected) === -1) productDetail.isSelected = false;
          else productDetail.isSelected = true;

          dispatch({
            type: GET_PRODUCT_DETAIL,
            payload: { productDetail }
          });
          dispatch(asyncActionFinish());
        }
      })
      .catch(function (error) {
        console.error(error.data);
        dispatch(asyncActionError(error));
      });
  };
}

// The /products/view interface needs to be called even if the productList data is obtained during display, because the number of views for each product is increased by one
export function getProductDetailNew(id) {
  let url = 'api/products/view';
  let pageParams = {
    product_id: id
  };
  return async function (dispatch) {
    service
      .get(url, { params: pageParams })
      .then(function (response) {
        if (response.data.status === true) {
          const productDetail = response.data.data;
          dispatch({
            type: GET_PRODUCT_DETAIL,
            payload: { productDetail }
          });
        }
      })
  }
}

export function setCategoryID(id, name, level) {
  return function (dispatch) {
    dispatch(clearProducts());
    dispatch({ type: SET_CATEGORY_ID, payload: { id, name, level, currentPage: 1 } });
  };
}

export function setSelectedProductID(value) {
  return function (dispatch) {
    //dispatch(clearSelectedProduct());
    //dispatch(clearProducts());
    dispatch({ type: SET_SELECTED_PRODUCT_ID, payload: value });
  };
}

export function setImportProduct(id, isSelectAllMode, isSelectAll, isSelected) {
  return function (dispatch) {
    dispatch({
      type: SET_IMPORT_PRODUCT,
      payload: { id, isSelectAllMode, isSelectAll, isSelected }
    });
  };
}

export function setCurrentPage(value) {
  return function (dispatch) {
    dispatch(clearProducts());
    dispatch({ type: SET_PAGE_NO, payload: value });
  };
}

export function setProductImportResponseView(value) {
  return function (dispatch) {
    dispatch({ type: SET_IMPORT_PRODUCT_RESPONSE_VIEW, payload: value });
  };
}

export function setPageSize(value) {
  return function (dispatch) {
    dispatch(clearProducts());
    dispatch({ type: SET_PAGE_SIZE, payload: value });
  };
}

export function setSearchKeywards(value) {
  //console.log("setSearchKeywards",value);
  return function (dispatch) {
    if (value === '') {
      //  console.log("SET_SEARCH_KEYWORDS_CLEAR",value);
      dispatch({ type: SET_SEARCH_KEYWORDS_CLEAR, payload: value });
    } else {
      //  dispatch(clearProducts());
      dispatch({ type: SET_SEARCH_KEYWORDS, payload: value });
    }
  };
}

export function setFreeShipping(value) {
  return function (dispatch) {
    dispatch(clearProducts());
    dispatch({ type: SET_FREE_SHIPPING, payload: value });
  };
}

export function clearFromLocalStorage() {
  localStorage.setItem('selectedProduct', '');
  return function (dispatch) {
    dispatch(clearSelectedProducts());
  };
}

export function setExtraShipping(value) {
  return function (dispatch) {
    dispatch(clearProducts());
    dispatch({ type: SET_EXTRA_SHIPPING, payload: value });
  };
}

export function sortProducts(key, direction) {
  return {
    type: SORT_BY_FREESHIPPING,
    payload: { key, direction }
  };
}

export function setSortByProducts(sortByVal) {
  return {
    type: SET_SORT_BY_PRODUCTS,
    payload: sortByVal
  };
}

export function clearProducts() {
  return {
    type: CLEAR_PRODUCTS
  };
}

export function clearSelectedProduct() {
  return {
    type: CLEAR_SELECTED_PRODUCT
  };
}

export function clearSelectedProducts() {
  return {
    type: CLEAR_SELECTED_PRODUCTS
  };
}
export function clearProductImportToResponse() {
  return {
    type: CLEAR_PRODUCTS_IMPORT_RESPONSE
  };
}

export function setProductImportLoadingView(value) {
  return {
    type: SET_PRODUCT_IMPORT_LOADING_VIEW,
    payload: value
  };
}

export function setProductImportProgressView(value) {
  return function (dispatch) {
    dispatch({ type: SET_PRODUCT_IMPORT_PROGRESS_VIEW, payload: { value } });

    if (value) {
      toast(<ProductImportProgress />, {
        autoClose: false,
        closeOnClick: false,
        toastId: productImportToastId,
        onClose: () => {
          dispatch({
            type: SET_PRODUCT_IMPORT_PROGRESS_VIEW,
            payload: { value: false }
          });
          dispatch({
            type: SET_PRODUCT_IMPORT_LOADING_VIEW,
            payload: { value: true }
          });
        }
      });
    } else {
      dismissProductImportProgress();
    }
  };
}

export function dismissProductImportProgress() {
  toast.dismiss(productImportToastId);
}

export function fetchProductImportProgress(isUpdate = false) {
  const url = 'api/products/import/importtasksummary';
  const params = {};
  return async function (dispatch, getState) {
    const {
      isProductImportLoadingShow,
      isProductImportProgressShow,
      isProductImportRunning
    } = getState().productCatalogue;
    if (!isUpdate) {
      if (isProductImportLoadingShow || isProductImportProgressShow) {
        return;
      }
    }
    if (!isUpdate)
      dispatch(asyncActionStart());

    service
      .get(url, { params })
      .then(function (response) {
        if (response.data.status === true) {
          const data = response.data.data || {};
          const isFinished =
            (data && data.all > 0 && data.all <= data.success + data.fail) ||
            (data && data.all === 0);
          const inProgress = data && data.all > 0 && !isFinished;

          dispatch({
            type: FETCH_PRODUCT_IMPORT_TASK,
            payload: {
              productImportProgress: data,
              isProductImportRunning: inProgress
            }
          });

          if (inProgress && !isUpdate) {
            dispatch(setProductImportProgressView(true));
            dispatch(setProductImportLoadingView(false));
          }
          if (!inProgress || isFinished) {
            dispatch(setProductImportProgressView(false));
            dispatch(setProductImportLoadingView(false));
            if (isProductImportRunning && isFinished) {
              toast.success('Products import finished!', {
                toastId: productImportFinishId,
                autoClose: 8000
              });
            }
          }
          if (!isUpdate)
            dispatch(asyncActionFinish());
        }
      })
      .catch(function (error) {
        console.error(error.data);
        dispatch(asyncActionError(error));
      });
  };
}

export function filterProducts(filter) {
  return function (dispatch) {
    if (filter && filter.length) {
      dispatch(clearProducts());
    }
    dispatch({ type: SET_PRODUCT_FILTER_BY, payload: filter });
  };
}
