import {
  FETCH_SPECIAL_PRICE_LOG_UPDATES,
  SPECIAL_PRICE_LOG_SET_PAGE_SIZE,
  SPECIAL_PRICE_LOG_SET_PAGE_NO,
  CLEAR_SPECIAL_PRICE_LOG
} from './specialPriceUpdateLogConstants';

const initialState = {
  specialPriceUpdateLog: [],
  totalCount: 0,
  currentPage: 1,
  pageSize: 10
};

export default function specialPriceUpdateLogReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FETCH_SPECIAL_PRICE_LOG_UPDATES:
      return {
        ...state,
        specialPriceUpdateLog: payload.specialPriceUpdateLog,
        totalCount: payload.totalCount,
        currentPage: payload.currentPage
      };
    case CLEAR_SPECIAL_PRICE_LOG:
      return {
        ...state,
        specialPriceUpdateLog: [],
        currentPage: 1
      };
    case SPECIAL_PRICE_LOG_SET_PAGE_SIZE:
      return {
        ...state,
        retainState: false,
        pageSize: payload
      };

    case SPECIAL_PRICE_LOG_SET_PAGE_NO:
      return {
        ...state,
        retainState: false,
        currentPage: payload
      };

    default:
      return state;
  }
}
