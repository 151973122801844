import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './index.scss'
import { clearDSZPriceNotification, getBelowDSZPriceAlert } from '../../myAccount/profileActions.js';

export default function PriceAlert() {
    const dispatch = useDispatch();
    const { isShopifyUser, DszNotificationFlag } = useSelector((state) => state.profile);
    const { authenticated } = useSelector((state) => state.auth);

    const clearDszNotification = () => {
        dispatch(clearDSZPriceNotification());
    };
    const priceAlert = localStorage.getItem('below_price_alert')
    useEffect(() => {
        const getPriceAlert = async () => {
            await dispatch(getBelowDSZPriceAlert());
        };
        if (localStorage.getItem('below_price_alert') === null && authenticated) {
            getPriceAlert()
        }
    }, []);
    return (
        <>

            {(DszNotificationFlag == 1 || priceAlert === 'true') && isShopifyUser == true && (
                <div className='price-alert'>
                    <div className='alert-div'>
                        <div className='alert-content'>
                            <div className='alert-text'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                    <path d="M9.22222 6.05517V16.4447C9.22222 17.2007 8.60941 17.8135 7.85347 17.8135C7.27502 17.8135 6.75902 17.4498 6.56446 16.9051L4.89503 12.1224M14.6667 11.5913C15.9553 11.5913 17 10.5466 17 9.25792C17 7.96926 15.9553 6.92459 14.6667 6.92459M4.89503 12.1224C3.78112 11.6489 3 10.5446 3 9.25792C3 7.5397 4.39289 6.14681 6.11111 6.14681H7.53607C10.7255 6.14681 13.4665 5.18696 14.6667 3.81348L14.6667 14.7024C13.4665 13.3289 10.7255 12.369 7.53607 12.369L6.11109 12.369C5.67958 12.369 5.2686 12.2812 4.89503 12.1224Z" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg><span style={{ paddingLeft: '8px' }}>We've noticed some of your products are listed below the price listed on Dropshipzone.</span>

                            </div>
                        </div>
                    </div>
                    <div className='alert-action'>
                        <a className='action-text'
                            href="/my-products?DSZ-Below-Price"
                            onClick={clearDszNotification}>
                            Compare pricing
                        </a>
                    </div>
                    <div onClick={clearDszNotification}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                            <path d="M5 15.8135L15 5.81348M5 5.81348L15 15.8135" stroke="white" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </div>)}

        </>
    )
}