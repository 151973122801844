import React from 'react';
import { useSelector } from 'react-redux';
import { Progress } from 'reactstrap';
import styles from './productCatelogue.module.css';

export default function ProductImportProgress({}) {
  const { productImportProgress = {} } = useSelector(
    (state) => state.productCatalogue
  );

  const { all = 0, success = 0 } = productImportProgress;
  let progress;
  if (all <= 0 || success <= 0) {
    progress = 1;
  } else {
    progress = Math.floor((success / all) * 100);
  }

  return (
    <>
      <div className={styles.importTaskHeader}>Products Import Progress</div>
      <div className="d-block mt-1 mb-1 ml-1">
        <Progress
          value={progress}
          className="progress-bar-rounded progress-animated-alt"
          color="info">{`${progress}%`}</Progress>
      </div>
      <div
        className={
          styles.importTaskInfo
        }>{`All: ${all}, Success: ${success}`}</div>
    </>
  );
}
