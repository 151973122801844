import service from '../../../services/request';
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart
} from '../../../reducers/asyncReducer';
import {
  FETCH_PRODUCT_PRICE_UPDATES,
  PRODUCT_PRICE_SET_PAGE_NO,
  PRODUCT_PRICE_SET_PAGE_SIZE,
  SET_PRICE_UPDATE_PROGRESS_VIEW,
  SET_PRICE_UPDATE_LOADING_VIEW,
  FETCH_PRICE_UPDATE_PROGRESS,
  CLEAR_PRODUCT_PRICES
} from './productPriceConstants';
import React from 'react';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import PriceUpdateProgress from './priceUpdateProgress';
import { saveOnlyNew } from '../../../utils/util';

const priceUpdateProgressToastId = 'price-update-progress';
const priceUpdateProgressFinishId = 'price-update-progress-finish';

export function fetchProductPriceUpdates(
  pageNo,
  pageSize,
  searchKeywords,
  fromTime,
  toTime,
  updateType
) {
  let pageParams = {
    page_no: pageNo,
    limit: pageSize,
    keywords: searchKeywords,
    from_time: fromTime ? fromTime.format('YYYY-MM-DD') : null,
    to_time: toTime ? toTime.format('YYYY-MM-DD') : null,
    update_type: updateType
  };
  return async (dispatch) => {
    dispatch(asyncActionStart());
    dispatch(clearProductsPrice());
    service
      .get('api/products/productupdate/getpriceupdate', { params: pageParams })
      .then((response) => {
        dispatch({
          type: FETCH_PRODUCT_PRICE_UPDATES,
          payload: {
            productsInfoUpdate:
              response.data.data.data !== undefined
                ? response.data.data.data
                : [],
            totalCount: response.data.data.total_count,
            totalPages: response.data.data.total_pages,
            currentPage: response.data.data.cur_page
          }
        });
        dispatch(asyncActionFinish());
      })
      .catch((error) => dispatch(asyncActionError(error)));
  };
}
export function clearProductsPrice() {
  return {
    type: CLEAR_PRODUCT_PRICES
  };
}

export function updateProductPrices(
  skus,
  pageNo,
  pageSize,
  searchKeywords,
  fromTime,
  toTime,
  updateType
) {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    service
      .post(`api/products/productupdate/updateprice`, { skus })
      .then(() => {
        dispatch(
          fetchProductPriceUpdates(
            pageNo,
            pageSize,
            searchKeywords,
            fromTime,
            toTime,
            updateType
          )
        ).then(() => {
          dispatch(asyncActionFinish());
          dispatch(fetchPriceUpdateProgress());
          Swal.fire({
            icon: 'success',
            title: 'Submission Received',
            text:
              'Track your progress through the progress bar in the upper right',
            onDestroy: () => dispatch(fetchPriceUpdateProgress(true))
          });
        });
      })
      .catch((error) => dispatch(asyncActionError(error)));
  };
}

export function setCurrentPage(value) {
  return function (dispatch) {
    dispatch({ type: PRODUCT_PRICE_SET_PAGE_NO, payload: value });
  };
}

export function setPageSize(value) {
  return function (dispatch) {
    dispatch({ type: PRODUCT_PRICE_SET_PAGE_SIZE, payload: value });
  };
}

export function set() {}

export function setPriceUpdateLoadingView(value) {
  return {
    type: SET_PRICE_UPDATE_LOADING_VIEW,
    payload: value
  };
}

export function setPriceUpdateProgressView(value) {
  return function (dispatch) {
    dispatch({ type: SET_PRICE_UPDATE_PROGRESS_VIEW, payload: { value } });

    if (value) {
      toast(<PriceUpdateProgress />, {
        autoClose: false,
        closeOnClick: false,
        toastId: priceUpdateProgressToastId,
        onClose: () => {
          dispatch({
            type: SET_PRICE_UPDATE_PROGRESS_VIEW,
            payload: { value: false }
          });
          dispatch({
            type: SET_PRICE_UPDATE_LOADING_VIEW,
            payload: { value: true }
          });
        }
      });
    } else {
      dismissPriceUpdateProgress();
    }
  };
}

export function fetchPriceUpdateProgress(isUpdate = false) {
  const url = 'api/products/productupdate/getpriceupdateprogress';
  const params = {};
  return async function (dispatch, getState) {
    const {
      isPriceUpdateLoadingShow,
      isPriceUpdateProgressShow,
      isPriceUpdateRunning
    } = getState().productPrice;
    if (!isUpdate) {
      if (isPriceUpdateLoadingShow || isPriceUpdateProgressShow) {
        return;
      }
    }

    if (!isUpdate) dispatch(asyncActionStart());

    service
      .get(url, { params })
      .then(function (response) {
        if (response.data.status === true) {
          const data = response.data.data || {};
          const isFinished =
            (data && data.all > 0 && data.all <= data.success + data.fail) ||
            (data && data.all === 0);
          const inProgress = data && data.all > 0 && !isFinished;

          dispatch({
            type: FETCH_PRICE_UPDATE_PROGRESS,
            payload: {
              priceUpdateProgress: data,
              isPriceUpdateRunning: inProgress
            }
          });

          if (inProgress && !isUpdate) {
            dispatch(setPriceUpdateProgressView(true));
            dispatch(setPriceUpdateLoadingView(false));
          }
          if (!inProgress || isFinished) {
            dispatch(setPriceUpdateProgressView(false));
            dispatch(setPriceUpdateLoadingView(false));
            if (data.all > 0 && isFinished) {
              const finishStr =
                new Date().toISOString().split('T')[0] + '-' + data.all;
              const isNew = saveOnlyNew(priceUpdateProgressFinishId, finishStr);
              if (isNew) {
                toast.success('Price update finished!', {
                  toastId: priceUpdateProgressFinishId,
                  autoClose: 8000
                });
              }
            }
          }
          if (!isUpdate) dispatch(asyncActionFinish());
        }
      })
      .catch(function (error) {
        console.error(error.data);
        dispatch(asyncActionError(error));
      });
  };
}

export function dismissPriceUpdateProgress() {
  toast.dismiss(priceUpdateProgressToastId);
}
