// @ts-check

import React, { useEffect } from 'react';
import GetCommonStyle from '../../../controls/common/getCommonStyle';
import { Row, Col, Container } from 'reactstrap';

import illustration1 from '../../../assets/images/logo-login.png';
import illustrationPurple from '../../../assets/images/logo_new.png';
import './index.scss'


export default function Maintenance() {
    const DSZ_URL = process.env.REACT_APP_DSZ_WEBSITE_URL
    const isSofortigDomain = window.location.host.indexOf('sofortig.com.au') >= 0

    return (
        <>
            {!isSofortigDomain && <GetCommonStyle />}
            <div className="app-wrapper bg-white min-vh-100">
                <div className="app-main min-vh-100">
                    <div className="app-content p-0">
                        <div className="app-content--inner d-flex align-items-center">
                            <div className="flex-grow-1 w-100 d-flex align-items-center">
                                <div className="bg-composed-wrapper--content py-5">
                                    <Container>
                                        <Row>
                                            <Col
                                                xs={{ order: 2 }}
                                                md={{ order: 1 }}
                                                lg="5">
                                                <div className='maintenance-div'>
                                                    <div className={isSofortigDomain ? 'sofortig-maintenance-title' : 'rp-maintenance-title'}>
                                                        Under Maintenance
                                                    </div>
                                                    <div className={isSofortigDomain ? 'sofortig-maintenance-content' : 'rp-maintenance-content'}>
                                                        <div style={{ marginBottom: '40px' }}>{isSofortigDomain ? 'Sofortig' : 'Dropshipzone'} is currently undergoing a scheduled maintenance.
                                                            This may take up to one hour. During this time you will be unable to log into your account.</div>
                                                        {/* <div style={{ marginBottom: '40px' }}>Sofortig is currently undergoing a scheduled maintenance as part of our new Retailer Portal.
                                                            This may take up to one hour. During this time you will be unable to log into your account.</div> */}
                                                        {/* <div style={{ marginBottom: '40px' }}>Need more information? 
                                                            <a style={{textDecoration:'underline',color:'#000'}} href={DSZ_URL + '/new-retailer-portal'}> Click here to find out more.</a>
                                                        </div> */}
                                                        <div style={{ marginBottom: '40px' }}>For urgent matters, please contact your Account Manager or email us at {''}
                                                            <a style={{ textDecoration: 'underline', color: '#000' }} href="mailto:info@dropshipzone.com.au">info@dropshipzone.com.au</a>.
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col
                                                lg="7"
                                                xs={{ order: 1 }}
                                                md={{ order: 1 }}
                                                className="d-flex d-lg-flex align-items-center">
                                                <div className="w-100 pb-4">
                                                    <img
                                                        alt="..."
                                                        className="w-90 mx-auto d-block img-fluid"
                                                        src={isSofortigDomain ? illustration1 : illustrationPurple}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
