import {
  LISTEN_TO_CURRENT_USER_PROFILE,
  BINDING_ACCOUNT,
  LISTEN_FOR_DSZ_BELOW_PRICE,
  CLEAR_DSZ_PRICE_NOTIFICATION
} from './profileConstants';

import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from '../../reducers/asyncReducer';

import service from '../../services/request';

import {
  setFeatureFlag,
  SET_FEATURE_FLAG_AUTO_SYNC
} from '../../reducers/ThemeOptions';

export const getUserProfile = () => {
  let url = 'api/users/getuserinfo';
  let pageParams = {};

  if (localStorage.getItem('email')) {
    pageParams['email'] = localStorage.getItem('email');
  }
  //console.log("Call getUserProfile ===", localStorage.getItem('email'));
  return async function (dispatch) {
    await dispatch(asyncActionStart());

    return service
      .get(url, { params: pageParams })
      .then(async function (response) {
        if (response.data.status === true) {
          const userProfile = response.data.data;
          const userBinding = response.data.data.binding;
          const isShopifyUser = response.data.data.myshopify_domain ? true : false;
          localStorage.setItem('isShopifyUser', isShopifyUser)
          var isAutoSync =
            response.data.data.price_auto_sync === 0 ? false : true;
          localStorage.setItem('isAutoSync', isAutoSync)
          //isAutoSync = false;
          /*   if (!isAutoSync && process.env.REACT_APP_FEATURE_AUTO_SYNC === 'true')
              isAutoSync = true; */
          //Check Auto Sync
          if (localStorage.getItem('f_mode3') === 'true') {
            //setFeatureFlagMyAccount(dispatch);
            await dispatch({
              type: SET_FEATURE_FLAG_AUTO_SYNC,
              payload: isAutoSync
            });
          }
          // console.log('getUserProfile', isAutoSync);
          /* console.log('responseEnd -> getUserProfile ->', userProfile);
          console.log('responseEnd -> userBinding ->', userBinding); */

          await dispatch({
            type: LISTEN_TO_CURRENT_USER_PROFILE,
            payload: { userProfile, userBinding, isAutoSync, isShopifyUser }
          });
          await dispatch(asyncActionFinish());
        }
      })
      .catch(async function (error) {
        console.error(error.data);
        await dispatch(asyncActionError(error));
      });
  };
};

export const setBindingAccount = (isBinding, email, website) => {
  // console.log("isBinding:",isBinding);
  let url = 'api/users/bindaccount';
  if (!isBinding) url = 'api/users/unbind';

  //console.log("setBindingAccount",url);

  return async function (dispatch) {
    dispatch(asyncActionStart());

    service
      .post(url, { email: email, website: website })
      .then(function (response) {
        if (response.data.status === true) {
          const bindStatus = 'success';
          const bindResponseData =
            'Dropshipzone Account binding has been updated successfully';
          dispatch({
            type: BINDING_ACCOUNT,
            payload: { isBinding, bindStatus, bindResponseData }
          });
          dispatch(asyncActionFinish());
        } else {
          dispatch(asyncActionFinish());
          const bindStatus = 'error';
          const bindResponseData = response.data.data;
          dispatch({
            type: BINDING_ACCOUNT,
            payload: { isBinding, bindStatus, bindResponseData }
          });
          console.error('-------------', response.data.data);
          dispatch(asyncActionError(response.data.data));
        }
      })
      .catch(function (error) {
        console.error(error.data);
        dispatch(asyncActionError(error));
      });
  };
};

export const getBelowDSZPriceAlert = () => {
  let url = 'api/products/myproduct2/search';
  let pageParams = {
    below_equal_DSZ_price: 1,
    bind_status: '',
    keywords: '',
    limit: 50,
    page_no: 1
  };

  //console.log(pageParams, "pageParams DSZ");
  return async function (dispatch) {
    dispatch(asyncActionStart());

    service
      .get(url, { params: pageParams })
      .then(function (response) {
        //console.log(response, "RESPONSE DSZ" );
        if (response.data.data.total_count > 0) {
          const DszNotificationFlag = 1;

          //console.log(response.data, "RESPONSE DSZ inside" , response.data.data.total_count , DszNotificationFlag);

          dispatch({
            type: LISTEN_FOR_DSZ_BELOW_PRICE,
            payload: { DszNotificationFlag }
          });
          if (localStorage.getItem('below_price_alert') === null) {
            localStorage.setItem('below_price_alert', true);
          }
        }

        dispatch(asyncActionFinish());
      })

      .catch(function (error) {
        console.error(error.data);
        dispatch(asyncActionError(error));
      });
  };
};

export function clearDSZPriceNotification() {
  localStorage.setItem('below_price_alert', false);
  return {
    type: CLEAR_DSZ_PRICE_NOTIFICATION
  };
}
