export const SET_FORM_1_DATA = 'SET_FORM_1_DATA';
export const SET_SELECT_SKU_LIST = 'SET_SELECT_SKU_LIST';
export const SET_USERINFO_DATA = 'SET_USERINFO_DATA';

export const SET_PAGE_LOADING = 'SET_PAGE_LOADING'

export const SET_ORDER_DETAILS = 'SET_ORDER_DETAILS'
export const CLEAR_ORDERS = 'CLEAR_ORDERS'

// /Order/Confirmed
export const SET_CONFIRMED_ORDERS = 'SET_CONFIRMED_ORDERS'