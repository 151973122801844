import {
  FETCH_PRODUCTS_INFO_UPDATE,
  FETCH_STORE_PRODUCT_COUNT_UPDATE
} from './shippingCostInquiryConstants';

const initialState = {
  storedProductCount: 0,
  shippingCostInquiry: []
};

export default function shippingCostInquiryReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FETCH_STORE_PRODUCT_COUNT_UPDATE:
      return {
        ...state,
        storedProductCount: payload
      };

    case FETCH_PRODUCTS_INFO_UPDATE:
      return {
        ...state,
        shippingCostInquiry: [{ ...payload.shippingCostInquiry }]
      };
    default:
      return state;
  }
}
